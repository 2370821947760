import {makeAutoObservable} from 'mobx';

export const DEFAULT_PAGINATION_LIMIT = 100;

export class Pagination {
  initialLimit: number = DEFAULT_PAGINATION_LIMIT;
  limit: number = DEFAULT_PAGINATION_LIMIT;
  total: number = 0;
  offset: string = '';
  lastPage: boolean = false;

  constructor(limit?: number) {
    if (limit) this.initialLimit = limit;
    this.setLimitToInitial();
    makeAutoObservable(this);
  }

  setOffset(offset: string) {
    this.offset = offset;
  }

  setTotal(total: number) {
    this.total = total;
  }

  setLimit(limit: number) {
    this.limit = limit;
  }

  setLimitToInitial() {
    this.setLimit(this.initialLimit);
  }

  setLimitToSyncData() {
    this.setLimit(Math.max(this.total, this.initialLimit));
  }

  setLastPage() {
    this.setOffset('');
    this.lastPage = true;
  }

  processOffset(offset?: string) {
    if (!offset || this.offset === offset) {
      this.setLastPage();
      return;
    }
    this.setOffset(offset || '');
    if (offset) this.lastPage = false;
  }

  clear() {
    this.total = 0;
    this.offset = '';
    this.setLimitToInitial();
    this.lastPage = false;
  }
}
