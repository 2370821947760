import {makeHandler} from './makeHandler';
import {IResponse, IResponseItem, RequestApiType} from './request';
import objectToQueryParamString from '../utils/objectToQueryParamString';

export const getDailyRecords = makeHandler(
  'getDailyRecords',
  (req: {dateFilter: string; userName: string}) => ({
    method: 'GET',
    path: `/Daily?${getRequestFields(req.userName, req.dateFilter)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: DailyResponse) => res.data.records,
);

export const addDailyRecord = makeHandler(
  'addDailyRecord',
  (data: DailyRecord4Export) => ({
    method: 'POST',
    data: {records: [{fields: data}], typecast: true},
    path: `/Daily/`,
    apiType: RequestApiType.AirTable,
  }),
  (res: DailyResponse) => res.data.records,
);

export const updateDailyRecord = makeHandler(
  'updateDailyRecord',
  (req: {id: string; data: DailyRecord4Export}) => ({
    method: 'PATCH',
    data: {records: [{id: req.id, fields: req.data}], typecast: true},
    path: '/Daily/',
    apiType: RequestApiType.AirTable,
  }),
  (res: DailyResponse) => res.data.records,
);

export type DailyResponse = IResponse<IDaily>;
export type DailyResponseItem = IResponseItem<IDaily>;

export interface IDaily {
  ident: string;
  daily_date: string;
  blockers: string;
  record_ids: string[];
  confirm_date: string;
}

export type DailyRecord4Export = {
  blockers: string;
  daily_date: string;
  record_ids: string[];
  daily_text: string;
  confirm_date: string;
  time_grid_user: string[];
};

export type IDailyWithId = IDaily & {id: string};

function getRequestFields(userName: string, dateFilter: string) {
  const fields = ['ident', 'daily_date', 'record_ids', 'blockers', 'confirm_date', 'time_grid_user'];
  return objectToQueryParamString({
    'fields[]': fields,
    filterByFormula: `AND({team_mate} = "${userName}", ${dateFilter} )`,
  });
}
