import React from 'react';
import {Text, View, StyleSheet, ActivityIndicator} from 'react-native';
import {observer} from 'mobx-react-lite';

import {DailyRecord} from '../models/dailyRecord';
import {strings} from '../locales/i18n';
import {HoursTrackedBlock} from './HoursTrackedBlock';
import {TimeRecordsItem} from './TimeRecordsItem';
import {AddTimeBlock} from './AddTimeBlock';
import {appProvider} from '../appProvider';
import theme from '../theme';
import {IMenuItem} from '../types';

type Props = {
  record: DailyRecord;
};

export const DailyRecordsList: React.FC<Props> = observer((props) => {
  const app = appProvider.application;
  const user = app.model.user;

  const onDelete = (timeRecordId: string) => {
    app.deleteTimeRecordFromDaily(props.record, timeRecordId);
    app.modal.hideLastEntry();
  };

  const renderList = () => {
    if (app.model.dailyCtrl.loading) {
      return <ActivityIndicator size="small" style={s.loading} />;
    }
    return props.record.timeRecords.map((el) => {
      const deleteAction: IMenuItem = {
        title: strings('action.daily.delete'),
        warning: true,
        onPress: () => onDelete(el.id),
      };
      return (
        <React.Fragment key={el.id}>
          <View style={s.separator} />
          <TimeRecordsItem record={el} key={el.id} deleteAction={deleteAction} editable />
        </React.Fragment>
      );
    });
  };

  const onAddTimePressed = () => {
    // TODO check if daily time recs empty, then - navigate to add time record screen
    app.getTimeRecords(props.record.weekFilter);
    app.showDailyTaskSelector(props.record);
  };

  return (
    <>
      <Text style={s.title}>{props.record.title}</Text>
      <HoursTrackedBlock
        hoursSpent={props.record.totalHoursSpent}
        hoursAvailable={user.hoursPerDay}
        headerVariant
        backgroundColor={theme.bgColorDark300}
      />
      {renderList()}
      <View style={s.separator} />
      <AddTimeBlock date={props.record.dailyDate} onPress={onAddTimePressed} />
    </>
  );
});

const s = StyleSheet.create({
  title: {
    paddingTop: theme.aligned(40),
    paddingBottom: theme.space16,
    fontSize: theme.fontSize24,
    color: theme.textColorSecondary,
    fontFamily: theme.fontFamilyMain,
    fontWeight: '500',
  },
  separator: {
    height: theme.pt,
    backgroundColor: theme.bgColorDark400,
  },
  loading: {
    backgroundColor: theme.bgColorDark200,
    paddingBottom: theme.space24,
    minHeight: theme.aligned(80),
  },
});
