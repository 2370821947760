import {PropsWithChildren} from 'react';
import {ScrollView, ViewStyle} from 'react-native';
import theme from '../../theme';

export const PinCodeEnterFormWrapper: React.FC<PropsWithChildren<{}>> = (props) => {
  return <ScrollView contentContainerStyle={container}>{props.children}</ScrollView>;
};

const container: ViewStyle = {
  paddingHorizontal: theme.space16,
  width: theme.webScreensWidth,
  margin: 'auto',
  marginTop: '15%',
};
