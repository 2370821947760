import {makeAutoObservable} from 'mobx';

import * as api from '../api';

export class Agreement {
  id: string = '';
  idComplex: string = '';
  agreement: string = '';
  projectType: string = '';
  emails: string[] = [];
  asanaId: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  get title() {
    return `${this.idComplex} ${this.agreement}`;
  }

  get projectNumber() {
    const nameNodes = this.idComplex.split('-');
    return nameNodes.length > 1 ? nameNodes[1] : '';
  }

  hasEmail = (email: string) => {
    return Boolean(this.emails.find((el) => el === email));
  };

  setFromApi(p: api.IAgreementWithId) {
    this.id = p.id;
    this.idComplex = p.id_complex;
    this.agreement = p.agreement;
    this.projectType = p.project_type;
    this.asanaId = p.asana_gid_agreement;
    this.setEmails(p.emails);
  }

  setEmails(emails: string) {
    this.emails = [];
    const emailArr = new Set(emails.split(','));
    for (const email of [...emailArr]) {
      if (email) this.emails.push(email);
    }
  }

  serialize(): any {
    return {
      id: this.id,
      id_complex: this.idComplex,
      agreement: this.agreement,
      project_type: this.projectType,
      asana_gid_agreement: this.asanaId,
    };
  }
}
