import {makeAutoObservable} from 'mobx';
import {formatISO, isSunday, isToday} from 'date-fns';

import * as api from '../api';
import {Root} from './root';
import {TimeRecord} from './timeRecord';
import {decodeDate} from '../utils/decodeDate';
import {ProxyField} from './formField';
import {appProvider} from '../appProvider';
import {strings} from '../locales/i18n';
import {middleOfDay} from '../utils/dateUtils';
import {WeekFilter} from './weekFilter';

export class DailyRecord {
  model: Root;
  id: string = '';
  timeRecordIds: string[] = [];
  confirmDate: number = 0;
  dailyDate: number = 0;
  blockers: string = '';
  blockersField: ProxyField;

  constructor(root: Root) {
    this.model = root;
    this.blockersField = new ProxyField({
      getter: () => this.blockers,
      setter: (val) => this.setBlockers(val as string),
    });
    makeAutoObservable(this);
  }

  get title() {
    if (this.isToday) {
      return strings('common.today');
    }
    return this.model.dailyCtrl.lastDateText;
  }

  get isToday(): boolean {
    return isToday(this.dailyDate);
  }

  get totalHoursSpent() {
    return this.timeRecords.reduce((acc, curr) => acc + curr.hoursSpent, 0) / 3600;
  }

  get empty() {
    return this.timeRecords.length === 0;
  }

  get timeRecords(): TimeRecord[] {
    const list: TimeRecord[] = [];
    for (const id of this.timeRecordIds) {
      const rec = this.model.timeRecordsList.byId.get(id);
      if (rec) {
        list.push(rec);
      }
    }
    return list;
  }

  get weekFilter(): WeekFilter {
    if (!this.isToday && isSunday(this.model.dailyCtrl.yesterdayDate)) {
      return new WeekFilter(this.model, this.model.dailyCtrl.yesterdayDate.getTime());
    } else {
      return new WeekFilter(this.model);
    }
  }

  getDailyText(recordIds: string[]): string {
    let dailyTextRecs: Record<string, string> = {};
    for (const id of recordIds) {
      const rec = this.model.timeRecordsList.byId.get(id);
      if (rec) {
        const agreement = this.model.agreementsList.byId.get(rec.agreementId);
        const agTitle = `${agreement?.idComplex || ''} ${rec.title}`;
        const text: string = dailyTextRecs[agTitle] || '';
        dailyTextRecs = {...dailyTextRecs, [agTitle]: `${text}\n - ${rec.fullTaskName}`};
      }
    }
    let dailyText = '';
    for (const agTitle of Object.keys(dailyTextRecs)) {
      dailyText = `${dailyText}${agTitle}: ${dailyTextRecs[agTitle]}\n\n`;
    }
    return dailyText;
  }

  get dailyDate4Export() {
    if (this.isToday) return this.dailyDate;
    return this.model.dailyCtrl.lastDateDay;
  }

  get confirmDate4export() {
    if (this.isToday) return '';
    return this.confirmDate ? formatISO(this.confirmDate) : '';
  }

  get record4confirm(): api.DailyRecord4Export {
    return {
      ...this.record4export,
      confirm_date: formatISO(Date.now()),
    };
  }

  get record4export(): api.DailyRecord4Export {
    return {
      daily_date: formatISO(this.dailyDate4Export),
      blockers: this.isToday ? this.blockers || '' : '',
      record_ids: this.timeRecordIds,
      confirm_date: this.confirmDate4export,
      time_grid_user: [this.model.session.userId],
      daily_text: this.getDailyText(this.timeRecordIds),
    };
  }

  setBlockers(val: string) {
    this.blockers = val;
  }

  setFromApi(p: api.IDailyWithId) {
    if (!p) return;
    this.id = p.id;
    this.confirmDate = decodeDate(p.confirm_date);
    this.dailyDate = decodeDate(p.daily_date);
    this.blockers = p.blockers || '';
    if (p.record_ids) this.timeRecordIds = [...p.record_ids];
  }

  saveSelectedTimeRecords = () => {
    const list = this.model.timeRecordsList;
    const record_ids = this.isToday ? [...list.selectedTodayIds] : [...list.selectedYesterdayIds];
    const data: api.DailyRecord4Export = {
      ...this.record4export,
      record_ids,
      daily_text: this.getDailyText(record_ids),
    };
    const app = appProvider.application;
    if (this.id) {
      app.updateDailyRecord(this.id, data);
    } else {
      app.addDailyRecord(data);
    }
    this.model.timeRecordsList.clearSelected();
    this.model.tabCtrl.reset();
  };

  setDailyDate(val: number | Date) {
    this.dailyDate = middleOfDay(val);
  }

  deleteTimeRecord(id: string) {
    this.timeRecordIds = this.timeRecordIds.filter((el) => el !== id);
  }
}
