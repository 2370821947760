import React from 'react';
import {observer} from 'mobx-react-lite';
import {PixelRatio, StyleSheet, Text, View} from 'react-native';
import {expr} from 'mobx-utils';

import theme from '../theme';
import {strings} from '../locales/i18n';
import {formatDuration} from '../utils/formatters';
import {isNative} from '../constants/general';
import {DEFAULT_WEEK_HOURS} from '../models/user';

type Props = {
  hoursSpent: number;
  hoursSpentFormatted?: string;
  hoursAvailable: number;
  loading?: boolean;
  headerVariant?: boolean;
  backgroundColor?: string;
  fullWidth?: number;
  // TODO simplify interface
};

const FULLWIDTH = isNative ? theme.windowWidth - theme.space16 * 4 : theme.webScreensWidth - theme.space16 * 2;

export const HoursTrackedBlock: React.FC<Props> = observer((props) => {
  const fullWidth = props.fullWidth ? props.fullWidth : FULLWIDTH;
  const percentage = expr(() => {
    if (props.hoursAvailable) return Math.round((props.hoursSpent * 100) / props.hoursAvailable);
    else return 100 + Math.round((props.hoursSpent * 100) / DEFAULT_WEEK_HOURS);
  });
  const progressWidth = PixelRatio.roundToNearestPixel((fullWidth * (percentage > 100 ? 100 : percentage)) / 100);
  const progressStyle = StyleSheet.flatten([
    s.progressLine,
    percentage > 100 ? s.alertColor : null,
    {width: progressWidth},
  ]);

  const style = StyleSheet.flatten([
    s.container,
    !props.headerVariant ? s.borderBottomRadius : null,
    props.backgroundColor ? {backgroundColor: props.backgroundColor} : null,
  ]);

  const hoursSpentFormatted = props.hoursSpentFormatted
    ? props.hoursSpentFormatted
    : formatDuration(props.hoursSpent * 3600);

  return (
    <View style={style}>
      <View style={s.row}>
        <Text style={[s.text, s.durationText]} numberOfLines={1} adjustsFontSizeToFit>
          {strings('info.hours.tracked')}: {hoursSpentFormatted} {strings('common.of')}{' '}
          {formatDuration(props.hoursAvailable * 3600)}
        </Text>
        <View>
          <Text style={s.text}>{percentage}%</Text>
        </View>
      </View>
      <View style={[s.progressBlock, {width: fullWidth}]}>
        <View style={progressStyle} />
      </View>
    </View>
  );
});

const s = StyleSheet.create({
  durationText: {
    flexShrink: 1,
    paddingRight: theme.space12,
  },
  container: {
    paddingVertical: theme.space24,
    backgroundColor: theme.bgColorDark200,
    borderTopRightRadius: theme.cardBorderRadius,
    borderTopLeftRadius: theme.cardBorderRadius,
  },
  row: {
    flex: 1,
    flexWrap: 'nowrap',
    paddingBottom: theme.aligned(12),
    paddingHorizontal: theme.space16,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
  },
  progressLine: {
    position: 'absolute',
    height: theme.aligned(12),
    backgroundColor: theme.bgColorGreen100,
    borderRadius: theme.cardBorderRadius,
  },
  progressBlock: {
    backgroundColor: theme.bgColorDark400,
    borderRadius: theme.cardBorderRadius,
    height: theme.aligned(12),
    marginHorizontal: theme.space16,
  },
  alertColor: {
    backgroundColor: theme.bgColorRed100,
  },
  separator: {
    height: theme.pt,
    backgroundColor: theme.bgColorDark400,
  },
  borderBottomRadius: {
    borderBottomRightRadius: theme.cardBorderRadius,
    borderBottomLeftRadius: theme.cardBorderRadius,
  },
});
