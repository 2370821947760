import {action, computed, makeObservable, observable} from 'mobx';
import {ITimerController} from '../types';

const INITIAL_SECONDS = 60;

export class DecrementTimerController implements ITimerController {
  initialSeconds: number = INITIAL_SECONDS;
  currentSeconds: number = 0;
  intervalId: ReturnType<typeof setInterval> | null = null;
  constructor(initialSeconds?: number) {
    makeObservable(this, {
      initialSeconds: observable,
      currentSeconds: observable,
      intervalId: observable,
      start: action,
      handleChangeClock: action,
      clear: action,
      stop: action,
      setCurrentSeconds: action,
      isTimerActive: computed,
    });
    if (initialSeconds) this.setInitialSeconds(initialSeconds);
  }

  get isTimerActive() {
    return this.currentSeconds > 0;
  }

  start() {
    this.clear();
    this.setCurrentSeconds(this.initialSeconds);
    this.intervalId = setInterval(() => this.handleChangeClock(), 1000);
  }

  handleChangeClock() {
    const s = this.currentSeconds - 1;
    if (s < 1) {
      this.clear();
    } else {
      this.setCurrentSeconds(s);
    }
  }

  clear() {
    this.stop();
    this.setCurrentSeconds(0);
  }

  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  setInitialSeconds(val?: number) {
    if (val || val === 0) {
      this.initialSeconds = val;
    }
  }

  setCurrentSeconds(value: number) {
    this.currentSeconds = value;
  }
}

export class IncrementTimerController extends DecrementTimerController {
  handleChangeClock() {
    const val = this.currentSeconds + 1;
    this.setCurrentSeconds(val);
  }

  setInitialSeconds(val?: number) {
    if (val || val === 0) {
      this.initialSeconds = val;
      this.currentSeconds = val;
    }
  }
}
