import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {TimeRecordType} from '../types';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const getTimeRecords = makeHandler(
  'getTimeRecords',
  (req: {email: string; dateFilter: string}) => ({
    method: 'GET',
    path: `/Reports?${getRequestFields(req.email, req.dateFilter)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: TimeRecordsResponse) => res.data.records,
);

export const getTimeRecord = makeHandler(
  'getTimeRecord',
  (id: string) => ({
    method: 'GET',
    path: `/Reports/${id}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: {data: TimeRecordsResponseItem}) => res.data,
);

export const deleteTimeRecord = makeHandler(
  'deleteTimeRecord',
  (id: string) => ({
    method: 'DELETE',
    path: `/Reports/${id}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: TimeRecordDeleteResponse) => res,
);

export const addTimeRecord = makeHandler(
  'addTimeRecord',
  (data: TimeRecord4Export) => ({
    method: 'POST',
    data: {records: [{fields: data}]},
    path: `/Reports/`,
    apiType: RequestApiType.AirTable,
  }),
  (res: TimeRecordsResponse) => res.data.records,
);

export const updateTimeRecord = makeHandler(
  'updateTimeRecord',
  (req: {id: string; data: TimeRecord4Export | TimeRecord4ExportShort}) => ({
    method: 'PATCH',
    data: {records: [{id: req.id, fields: req.data}]},
    path: `/Reports/`,
    apiType: RequestApiType.AirTable,
  }),
  (res: TimeRecordsResponse) => res.data.records,
);

export type TimeRecord4Export = {
  record_date: string;
  hours_spent: number; // duration in seconds
  agreement_grid_item?: string[];
  thread_grid_item?: string[];
  team_grid_user: string[];
  comment: string;
  asana_task_gid: string;
  asana_task_name: string;
  asana_subtask_gid: string;
  asana_subtask_name: string;
};

export type TimeRecord4ExportShort = {
  hours_spent: number;
};

export interface ITimeRecord {
  id: string;
  hours_spent: number;
  record_date: string;
  created_time: string;
  last_modified: string;
  user_email: string;
  title: string;
  sub_title: string;
  comment: string;
  agreement_grid_item: string[];
  thread_grid_item: string[];
  record_type: TimeRecordType;
  fixed_cost: number;
  asana_task_gid?: string;
  asana_task_name?: string;
  asana_subtask_gid?: string;
  asana_subtask_name?: string;
}

export interface TimeRecordDeleteResponse {
  data: {
    id: string;
    deleted: boolean;
  };
}

export type TimeRecordsResponse = IResponse<ITimeRecord>;
export type TimeRecordsResponseItem = IResponseItem<ITimeRecord>;

function getRequestFields(email: string, dateFilter: string) {
  const fields = [
    'id',
    'hours_spent',
    'record_date',
    'created_time',
    'last_modified',
    'user_email',
    'title',
    'sub_title',
    'record_type',
    'agreement_grid_item',
    'thread_grid_item',
    'comment',
    'fixed_cost',
    'asana_task_gid',
    'asana_task_name',
    'asana_subtask_gid',
    'asana_subtask_name',
  ];

  return objectToQueryParamString({
    'fields[]': fields,
    filterByFormula: `AND({user_email} = "${email}", ${dateFilter} )`,
  });
}
