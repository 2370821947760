import {makeAutoObservable} from 'mobx';
import {formatISO} from 'date-fns';

import {Root} from './root';
import * as api from '../api';
import {decodeDate} from '../utils/decodeDate';

export class AsanaProject {
  model: Root;

  gid: string = '';
  name: string = '';
  permalinkUrl: string = '';
  notes: string = '';
  defaultView: api.AsanaProjectView | null = null;
  modifiedAt: number = 0;
  public: boolean = false;
  resourceType: string = '';

  constructor(root: Root, p?: api.AsanaProject) {
    this.model = root;
    if (p) this.setFromApi(p);
    makeAutoObservable(this);
  }

  get projectNumber() {
    const nameNodes = this.name.split('-');
    const idComplex = nameNodes[1] || '';
    const idNodes = idComplex.split(' ');
    if (isNaN(Number(idNodes[0]))) {
      return '';
    }
    return idNodes[0] || '';
  }

  setFromApi(p: api.AsanaProject) {
    this.gid = p.gid;
    this.name = p.name;
    this.permalinkUrl = p.permalink_url;
    this.notes = p.notes;
    this.defaultView = p.default_view;
    this.modifiedAt = decodeDate(p.modified_at);
    this.public = !!this.public;
    this.resourceType = p.resource_type;
  }

  serialize(): any {
    return {
      gid: this.gid,
      name: this.name,
      permalink_url: this.permalinkUrl,
      notes: this.notes,
      default_view: this.defaultView,
      modified_at: formatISO(this.modifiedAt),
      public: this.public,
      resource_type: this.resourceType,
    };
  }
}
