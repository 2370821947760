import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const getAbsentDays = makeHandler(
  'getAbsentDays',
  (email: string) => ({
    method: 'GET',
    path: `/AbsentGridHarvest/?${getAbsentDaysParams(email)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: AbsentDaysResponse) => res.data.records,
);

export const getHolidays = makeHandler(
  'getHolidays',
  () => ({
    method: 'GET',
    path: `/HolidaysGrid/?${getHolidaysParams()}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: HolidaysResponse) => res.data.records,
);

function getAbsentDaysParams(email: string) {
  const fields = ['name', 'type', 'date', 'email'];
  return objectToQueryParamString({
    'fields[]': fields,
    filterByFormula: `{email} = "${email}"`,
  });
}

function getHolidaysParams() {
  const fields = ['date', 'type'];
  return objectToQueryParamString({
    'fields[]': fields,
  });
}

export type HolidaysResponse = IResponse<IHoliday>;
export type HolidaysResponseItem = IResponseItem<IHoliday>;

export type AbsentDaysResponse = IResponse<IAbsentDay>;
export type AbsentDaysResponseItem = IResponseItem<IAbsentDay>;

export interface IAbsentDay {
  email: string;
  name: string;
  type: string;
  date: string; // in short format YYYY-MM-DD  (example - 2022-05-05)
}

export enum HolidayType {
  Russian = 'Russian',
  AntiHoliday = 'Anti-holiday',
}

export interface IHoliday {
  date: string; // in short format YYYY-MM-DD  (example - 2022-05-05)
  type: HolidayType;
}
