/**
 * When compiling for web, it will search for index.js on root/src.
 */
import 'react-native-gesture-handler';
import {createRoot} from 'react-dom/client';

import App from './App';
import {main} from './main';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
main();
root.render(<App />);
