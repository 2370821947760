import React from 'react';
import {StyleSheet, Text, TextProps, TextStyle} from 'react-native';

import glyphmap from '../ownicons.json';

type GlyphMap = typeof glyphmap;
export type IconName = keyof GlyphMap;

const fontName = 'ownicons';

interface Props extends TextProps {
  name: IconName;
  size?: number;
  color?: string;
}

const Icon: React.FC<Props> = (props: Props) => {
  const newProps: React.PropsWithChildren<TextProps> = {
    allowFontScaling: false,
  };
  const keys = Object.keys(props) as Array<keyof Props>;
  let style: TextStyle = props.size ? {fontSize: props.size} : {};
  const colorStyle = props.color ? {color: props.color} : {};
  let code: number | undefined;
  for (const key of keys) {
    if (key === 'name') {
      code = glyphmap[props[key]];
    } else if (key === 'style') {
      style = StyleSheet.flatten([style, props[key]]);
    } else {
      // @ts-ignore
      newProps[key] = props[key] as any;
    }
  }
  newProps.style = StyleSheet.flatten<TextStyle>([style, {fontFamily: fontName, fontWeight: 'normal'}, colorStyle]);
  const symbol = code ? String.fromCodePoint(code) : '?';
  newProps.children = symbol;

  return React.createElement(Text, newProps);
};

export default Icon;
