import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Observer} from 'mobx-react-lite';

import {
  ConfirmationModalButton,
  IMenuItem,
  IModalConfirmConfig,
  IModalController,
  IModalEntry,
  ModalRenderer,
} from './types';
import {ConfirmationModal} from './elements/ConfirmModal';
import {strings} from './locales/i18n';
import {MenuModalAndroid} from './elements/MenuModalAndroid';
import {BottomSheetModal} from './elements/BottomSheetModal';
import theme from './theme';
import {BottomSheetMenuIOS} from './elements/BottomSheerMenuIOS';
import {AgreementOrThreadSelector} from './components/AgreementOrThreadSelector';
import {TimeRecordForm} from './models/timeRecordForm';
import {NoConnectionBlock} from './components/NoConnectionBlock';
import {SessionExpiredBlock} from './components/SessionExpiredBlock';
import {AsanaTaskSelector} from './components/AsanaTaskSelector';
import {hasEditRecordsUITroubles} from './utils/hasEditRecordsUITroubles';
import {appProvider} from './appProvider';
import {isNative} from './constants/general';
import {WebCalendar} from './components/WebCalendar';
import {DailyTaskSelector} from './components/DailyTaskSelector';
import {DailyRecord} from './models/dailyRecord';
import {TimeRecord} from './models/timeRecord';
import {TimeEditModal} from './components/TimeEditModal';

export function confirmRenderer(ctrl: IModalController, config: IModalConfirmConfig): ModalRenderer {
  return (entry) => {
    const onClose = () => {
      ctrl.hide(entry.id);
      config?.onClose?.();
    };
    const onConfirmPress = () => {
      ctrl.hide(entry.id);
      config.onConfirm();
    };
    let buttons: ConfirmationModalButton[] = [];
    if (config.buttons) {
      buttons.push(...config.buttons);
    } else {
      buttons = [{action: onClose, text: strings('common.cancel')}];
    }
    if (config.confirmText) {
      buttons.push({isConfirm: config.isConfirm, action: onConfirmPress, text: config.confirmText});
    }
    if (config.confirmOnly) {
      buttons.shift();
    }

    return (
      <ConfirmationModal
        onClose={onClose}
        title={config.title}
        contentRenderer={config.contentRenderer}
        text={config.text}
        buttons={buttons}
        buttonsInVerticalRows={config.buttonsInVerticalRows}
      />
    );
  };
}

export function menuModalRenderer(ctrl: IModalController, menuItems: IMenuItem[]): ModalRenderer {
  return () => {
    return <MenuModalAndroid menuItems={menuItems} />;
  };
}

export function bottomSheetMenuRenderer(ctrl: IModalController, menuItems: IMenuItem[]): ModalRenderer {
  return () => {
    if (!isNative)
      return (
        <View style={styles.webMenu}>
          <BottomSheetMenuIOS menuItems={menuItems} />
        </View>
      );
    return (
      <BottomSheetModal
        modalController={ctrl}
        closeOnOverlayTap
        adjustToContentHeight
        renderer={() => <BottomSheetMenuIOS menuItems={menuItems} />}
        overlayStyle={styles.transparent}
        style={styles.bottomSheetMenuStyle}
        panGestureEnabled={false}
        withHandle={false}
      />
    );
  };
}

export function agreementOrThreadSelectorRenderer(ctrl: IModalController, form: TimeRecordForm): ModalRenderer {
  return () => {
    const onClose = () => ctrl.hide();

    if (!isNative) return <AgreementOrThreadSelector onClose={onClose} form={form} />;

    return (
      <BottomSheetModal
        modalController={ctrl}
        closeOnOverlayTap
        renderer={() => <AgreementOrThreadSelector onClose={onClose} form={form} />}
        overlayStyle={styles.transparent}
        style={styles.modalSelectors}
        withHandle
        snapPoint={theme.windowHeight - theme.space16}
      />
    );
  };
}

export function asanaTaskSelectorRenderer(ctrl: IModalController, form: TimeRecordForm): ModalRenderer {
  return () => {
    const onClose = () => ctrl.hide();

    if (!isNative) return <AsanaTaskSelector onClose={onClose} form={form} />;

    if (hasEditRecordsUITroubles(appProvider.application.model.session.email)) {
      return (
        <ScrollView contentContainerStyle={styles.scroll}>
          <AsanaTaskSelector onClose={onClose} form={form} />
        </ScrollView>
      );
    }

    return (
      <BottomSheetModal
        modalController={ctrl}
        closeOnOverlayTap
        renderer={() => <Observer>{() => <AsanaTaskSelector onClose={onClose} form={form} />}</Observer>}
        overlayStyle={styles.transparent}
        style={styles.modalSelectors}
        withHandle
        snapPoint={theme.windowHeight - theme.space16}
      />
    );
  };
}

export function webCalendarRenderer(ctrl: IModalController, form: TimeRecordForm): ModalRenderer {
  return (m: IModalEntry) => {
    const onClose = () => ctrl.hide(m.id);

    const onChange = (val: Date) => {
      form.setDate(val.getTime());
      onClose();
    };
    return <WebCalendar onChange={onChange} onClose={onClose} value={new Date(form.date)} />;
  };
}

export function dailyTaskSelectorRenderer(ctrl: IModalController, record: DailyRecord): ModalRenderer {
  return () => {
    return (
      <BottomSheetModal
        modalController={ctrl}
        closeOnOverlayTap
        renderer={() => <Observer>{() => <DailyTaskSelector record={record} />}</Observer>}
        overlayStyle={styles.transparent}
        style={styles.dailySelector}
        withHandle
        snapPoint={theme.windowHeight - theme.aligned(80)}
      />
    );
  };
}

export function recordTimeEditRenderer(ctrl: IModalController, record: TimeRecord): ModalRenderer {
  return (m: IModalEntry) => {
    const onClose = () => {
      ctrl.hide(m.id);
      record.setTimeEditMode(false);
    };
    return <TimeEditModal record={record} onClose={onClose} />;
  };
}

export function noConnectionRenderer(): ModalRenderer {
  return () => <NoConnectionBlock />;
}

export function sessionExpiredRenderer(): ModalRenderer {
  return () => <SessionExpiredBlock />;
}

const styles = StyleSheet.create({
  bottomSheetMenuStyle: {
    backgroundColor: 'transparent',
    paddingHorizontal: theme.space16,
  },
  modalSelectors: {
    backgroundColor: theme.bgColorDark300,
    flex: 1,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  scroll: {
    paddingTop: theme.space32,
    minHeight: theme.windowHeight,
  },
  webMenu: {
    justifyContent: 'center',
    flex: 1,
    alignSelf: 'center',
    width: theme.aligned(400),
  },
  dailySelector: {
    backgroundColor: theme.bgColorDark300,
  },
});
