import {makeHandler} from './makeHandler';
import {RequestApiType} from './request';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {ASANA_WORKSPACE_ID} from '../constants/general';

export const getAsanaUsers = makeHandler(
  'getAsanaUsers',
  () => ({
    method: 'GET',
    path: `/workspaces/${ASANA_WORKSPACE_ID}/users/?${objectToQueryParamString({opt_fields: 'email'})}`,
    apiType: RequestApiType.Asana,
  }),
  (res: AsanaUser[]) => res,
);

export type AsanaUser = {
  gid: string;
  email: string;
};
