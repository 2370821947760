import {makeAutoObservable} from 'mobx';
import {createRef, ReactText} from 'react';
import {FlatList, NativeScrollEvent, NativeSyntheticEvent, PixelRatio} from 'react-native';

import {ITabItem, TabType} from '../types';
import {strings} from '../locales/i18n';
import theme from '../theme';

export class TabsController {
  items: ITabItem[] = [
    {id: TabType.Daily, text: strings('common.daily')},
    {id: TabType.History, text: strings('common.history')},
    {id: TabType.Time, text: strings('common.time')},
  ];
  selectedTab: TabType = TabType.Daily;
  scrollRef = createRef<FlatList<ITabItem>>();

  constructor() {
    makeAutoObservable(this);
  }

  onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const scrollX = PixelRatio.roundToNearestPixel(event.nativeEvent.contentOffset.x);
    const screenWidth = theme.windowWidth;
    switch (true) {
      case scrollX < screenWidth:
        this.setTab(TabType.Daily);
        return;
      case scrollX >= screenWidth && scrollX < screenWidth * 2:
        this.setTab(TabType.History);
        return;
      case scrollX >= screenWidth * 2:
        this.setTab(TabType.Time);
        return;
    }
  };

  setTab(tab: ReactText) {
    this.selectedTab = tab as TabType;
  }

  onTabSelect = (item: ITabItem) => {
    this.scrollRef.current?.scrollToItem({item, animated: true});
  };

  scrollToBegin() {
    this.scrollRef.current?.scrollToOffset({offset: 0, animated: true});
  }

  scrollToEnd() {
    this.scrollRef.current?.scrollToOffset({offset: theme.windowWidth * 2, animated: true});
  }

  setHistoryTab() {
    this.setTab(TabType.History);
    setTimeout(() => this.scrollRef.current?.scrollToOffset({offset: theme.windowWidth, animated: true}));
  }

  reset() {
    this.setTab(TabType.Daily);
    this.scrollToBegin();
  }
}
