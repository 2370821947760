import React from 'react';
import {observer} from 'mobx-react-lite';
import {Keyboard, StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import {Button} from '../../elements/Button';
import {LoginController} from '../../models/loginController';
import theme from '../../theme';
import {FormInputBlock} from '../FormInputBlock';
import {strings} from '../../locales/i18n';
import {isNative} from '../../constants/general';
import {PinCodeEnterFormWrapper} from './PinCodeEnterFormWrapper';

type Props = {
  ctrl: LoginController;
};

export const PinCodeEnterForm: React.FC<Props> = observer((props) => {
  const hideKeyboard = () => Keyboard.dismiss();
  const onBackPress = () => props.ctrl.setEmailStep();

  const renderReloadButtonBlock = () => {
    if (props.ctrl.isReloadInProgress) {
      return (
        <Button
          loading={props.ctrl.loading}
          title={strings('info.code.was.resend')}
          variant="tertiary"
          iconName="check"
          iconColor={theme.bgColorGreen100}
          borderColor={theme.bgColorDark600}
        />
      );
    }
    return (
      <Button
        loading={props.ctrl.loading}
        disabled={!props.ctrl.canRequestNewPin}
        title={strings('action.resend.code')}
        variant="tertiary"
        onPress={props.ctrl.onRequestNewPin}
        iconName="reload"
      />
    );
  };

  const renderNotification = () => {
    return (
      <View style={styles[theme.stylesType].noticeBlock} onTouchStart={hideKeyboard}>
        <Text style={s.noticeText}>{strings('info.verification.code.notice')}</Text>
      </View>
    );
  };

  return (
    <PinCodeEnterFormWrapper>
      <View style={styles[theme.stylesType].titleRow} onTouchStart={hideKeyboard}>
        {isNative && (
          <Text style={s.back} onPress={onBackPress}>
            {strings('common.back')}
          </Text>
        )}
        <Text style={s.title}>{strings('common.verification.code')}</Text>
        <View style={s.spacer} />
      </View>
      {renderNotification()}
      <View style={s.content}>
        <FormInputBlock
          underlineStyle={styles[theme.stylesType].underline}
          field={props.ctrl.pinCodeField}
          autofocus
          type="pincode"
        />
        <View onTouchStart={hideKeyboard}>
          <View style={s.vSpacer} />
          <Button
            title={strings('common.continue')}
            disabled={props.ctrl.loading}
            onPress={props.ctrl.onConfirm}
            loading={props.ctrl.loading}
          />
          <View style={s.buttonSpacer} />
          {renderReloadButtonBlock()}
          <View style={s.buttonSpacer} />
          {!isNative && <Button title={strings('common.cancel')} onPress={onBackPress} variant="secondary" />}
        </View>
      </View>
    </PinCodeEnterFormWrapper>
  );
});

interface IEmailFormStyles {
  titleRow: ViewStyle;
  title: TextStyle;
  noticeBlock: ViewStyle;
  underline: ViewStyle;
}

interface IStyles {
  [x: string]: IEmailFormStyles;
}

const styles: IStyles = {
  web: {
    titleRow: {
      alignItems: 'center',
      justifyContent: 'center',
      height: theme.aligned(40),
    },
    title: {
      alignSelf: 'center',
      color: theme.textColor,
      fontSize: theme.fontSize32,
      fontFamily: theme.fontFamilyMedium,
      letterSpacing: theme.letterSpacing,
      textAlign: 'center',
      fontWeight: '600',
    },
    noticeBlock: {
      padding: theme.space16,
      marginTop: theme.space12,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.cardBorderRadius,
      marginBottom: theme.space16,
    },
    underline: {
      width: theme.aligned(263),
    },
  },
  native: {
    titleRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: theme.aligned(40),
    },
    title: {
      alignSelf: 'center',
      color: theme.textColor,
      fontSize: theme.fontSize18,
      fontFamily: theme.fontFamilyMain,
      letterSpacing: theme.letterSpacing,
      fontWeight: '500',
      textAlign: 'center',
    },
    noticeBlock: {
      padding: theme.space16,
      marginTop: theme.aligned(40),
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.cardBorderRadius,
      marginBottom: theme.space16,
      backgroundColor: theme.bgColorDark200,
    },
    underline: {},
  },
};

const s = StyleSheet.create({
  flex: {
    justifyContent: 'center',
  },
  back: {
    width: theme.aligned(50),
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  title: {
    alignSelf: 'center',
    color: theme.textColor,
    fontSize: theme.fontSize32,
    fontFamily: theme.fontFamilyMedium,
    letterSpacing: theme.letterSpacing,
    textAlign: 'center',
    fontWeight: '600',
  },
  spacer: {
    width: theme.aligned(50),
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
  vSpacer: {
    height: theme.space32,
  },
  buttonSpacer: {
    height: theme.space16,
  },
  noticeText: {
    textAlign: 'center',
    color: theme.textColorSecondary,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    lineHeight: theme.aligned(24),
    fontWeight: '500',
  },
});
