import {Platform} from 'react-native';

//export const AIRTABLE_API_TOKEN = 'keySTPMg7e0k1h2g5';
export const AIRTABLE_API_TOKEN = 'patFojVUYDHeKspI8.5c4b8e5b746520ed552eb58c9e1a0a91367e443095085eeb52d70ddcbf7a93ff';
export const AIRTABLE_BASE_ID = 'apprdpUK3k9gINF6Y';
export const REVIWER_EMAIL = 'violettrackertest@gmail.com';
export const REVIWER_PIN = '983421';
export const AIRTABLE_API_URL = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}`;
export const ASANA_API_TOKEN = '1/16022376534266:79139c724c8cf72507f0e8bbe1fe9042';
export const ASANA_WORKSPACE_ID = '16022375400120';
export const ASANA_API_URL = 'https://app.asana.com/api/1.0';
export const ASANA_TASKS_URL = 'https://app.asana.com/0/0/';
export const SENTRY_DSN = 'https://b37d5eade66f4e29b59e235ae1b86a55@o1301512.ingest.sentry.io/4504127515131904'; // account linked with email d.nartov@proscom.ru
export const isNative = Platform.OS !== 'web';

export const AIRTABLE_API_TOKEN_DEV =
  'path7vyiAu60d6Bux.d74bf95df467432c58745c8e1983b6ef6ff90e2d540f52a2868390b69cbabc4d';
export const AIRTABLE_BASE_ID_DEV = 'app1v8rjyZ0jMfUfJ';
export const AIRTABLE_API_URL_DEV = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID_DEV}`;
