import {makeAutoObservable} from 'mobx';
import {formatISO, subWeeks} from 'date-fns';
import {Linking} from 'react-native';

import * as api from '../api';
import {TimeRecordType} from '../types';
import {decodeDate} from '../utils/decodeDate';
import {formatDuration} from '../utils/formatters';
import {appProvider} from '../appProvider';
import {AsanaTask} from './asanaTask';
import {ASANA_TASKS_URL} from '../constants/general';
import {ProxyField, ProxyFieldValidator} from './formField';
import {adaptTimeValue} from './timeRecordForm';
import {strings} from '../locales/i18n';

export class TimeRecord {
  id: string = '';
  recordDate: number = 0;
  createdTime: number = 0;
  lastModified: number = 0;
  userEmail: string = '';
  title: string = '';
  subTitle: string = '';
  type: TimeRecordType | null = null;
  hoursSpent: number = 0;
  agreementId: string = '';
  threadId: string = '';
  comment: string = '';
  fixedCost: number = 0;
  asanaTaskId: string = '';
  asanaTaskName: string = '';
  asanaSubTaskId: string = '';
  asanaSubTaskName: string = '';

  loading: boolean = false;
  isSelected: boolean = false;
  timeField: ProxyField | null = null;
  time: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  get lastChangeDate() {
    return this.lastModified || this.createdTime || 0;
  }

  get canDelete() {
    return this.userEmail === appProvider.application.model.session.email;
  }

  get canEdit() {
    return this.createdTime > subWeeks(Date.now(), 2).getTime();
  }

  get isProjectType() {
    return this.type === TimeRecordType.Project;
  }

  get defined() {
    return Boolean(this.id) && Boolean(this.userEmail) && Boolean(this.type);
  }

  get hoursSpentFormatted() {
    return formatDuration(this.hoursSpent);
  }

  get subTitleCalculated() {
    if (this.asanaSubTaskName) return this.asanaSubTaskName;
    if (this.asanaTaskName) return this.asanaTaskName;
    if (this.comment) return this.comment;
    return this.subTitle || '';
  }

  get hasAsanaTask() {
    return Boolean(this.asanaTaskName || this.asanaSubTaskName);
  }

  get asanaTaskLink() {
    if (this.asanaSubTaskId) return `${ASANA_TASKS_URL}${this.asanaSubTaskId}`;
    if (this.asanaTaskId) return `${ASANA_TASKS_URL}${this.asanaTaskId}`;
    return '';
  }

  get export4ContinueTracking(): api.Timer4Export {
    return {
      reports_id: [this.id],
      is_active: true,
      start_date: formatISO(Date.now()),
    };
  }

  get fullTaskName() {
    let res: string = this.asanaTaskName ? this.asanaTaskName : '';
    if (this.asanaSubTaskName && res) {
      res = ` (${res})`;
    }
    return this.asanaSubTaskName ? `${this.asanaSubTaskName}${res}` : res;
  }

  openAsanaTaskLink = () => {
    Linking.canOpenURL(this.asanaTaskLink).then((result) => {
      if (result) Linking.openURL(this.asanaTaskLink);
    });
  };

  setAsanaTask(task: AsanaTask) {
    this.asanaTaskId = task.id;
    this.asanaTaskName = task.name;
  }

  setSelected(val: boolean) {
    this.isSelected = val;
  }

  toggleSelected = () => {
    this.setSelected(!this.isSelected);
  };

  setLoading(val: boolean) {
    this.loading = val;
  }

  setFromApi(p: api.ITimeRecord) {
    this.id = p.id;
    this.userEmail = p.user_email;
    this.type = p.record_type;
    this.title = p.title;
    this.subTitle = p.sub_title;
    this.hoursSpent = p.hours_spent || 0;
    this.comment = p.comment || '';
    this.fixedCost = p.fixed_cost;
    this.recordDate = decodeDate(p.record_date);
    this.createdTime = decodeDate(p.created_time);
    this.lastModified = decodeDate(p.last_modified);
    this.agreementId = Array.isArray(p.agreement_grid_item) ? p.agreement_grid_item[0] : '';
    this.threadId = Array.isArray(p.thread_grid_item) ? p.thread_grid_item[0] : '';
    this.asanaTaskId = p.asana_task_gid || '';
    this.asanaTaskName = p.asana_task_name || '';
    this.asanaSubTaskId = p.asana_subtask_gid || '';
    this.asanaSubTaskName = p.asana_subtask_name || '';
  }

  setTimeEditMode(enable: boolean) {
    if (!enable) {
      this.timeField = null;
    } else {
      this.time = formatDuration(this.hoursSpent);
      this.timeField = new ProxyField({
        getter: () => this.time,
        setter: (val) => this.setTime(val as string),
        validator: this.validateTime,
      });
    }
  }

  setTime(val: string) {
    this.time = adaptTimeValue(val);
  }

  serialize(): any {
    return {
      id: this.id,
      record_date: this.recordDate,
      created_time: this.createdTime,
      last_modified: this.lastModified,
      user_email: this.userEmail,
      title: this.title,
      sub_title: this.subTitle,
      record_type: this.type,
      hours_spent: this.hoursSpent,
      comment: this.comment,
      fixed_cost: this.fixedCost,
      asana_task_gid: this.asanaTaskId,
      asana_task_name: this.asanaTaskName,
      asana_subtask_gid: this.asanaSubTaskId,
      asana_subtask_name: this.asanaSubTaskName,
      agreement_grid_item: [this.agreementId],
      thread_grid_item: [this.threadId],
    };
  }

  clone() {
    const record = new TimeRecord();
    record.userEmail = this.userEmail;
    record.type = this.type;
    record.title = this.title;
    record.subTitle = this.subTitle;
    record.hoursSpent = this.hoursSpent;
    record.recordDate = this.recordDate;
    record.createdTime = this.createdTime;
    record.lastModified = this.lastModified;
    record.agreementId = this.agreementId;
    record.threadId = this.threadId;
    record.comment = this.comment;
    record.fixedCost = this.fixedCost;
    record.asanaTaskName = this.asanaTaskName;
    record.asanaTaskId = this.asanaTaskId;
    record.asanaSubTaskName = this.asanaSubTaskName;
    record.asanaSubTaskId = this.asanaSubTaskId;
    return record;
  }

  validateTime: ProxyFieldValidator = (val) => {
    const stringVal = val as string;
    if (!stringVal || stringVal.length < 4) return strings('error.invalid.value');
    return undefined;
  };
}
