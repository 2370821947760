import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet, KeyboardAvoidingView, ViewStyle} from 'react-native';

import {TimeRecordForm} from '../models/timeRecordForm';
import {FormInputBlock} from './FormInputBlock';
import theme from '../theme';
import Icon from '../elements/OwnIcons';
import {FormInputLabel} from './FormInputLabel';
import {strings} from '../locales/i18n';
import {appProvider} from '../appProvider';
import {hasEditRecordsUITroubles} from '../utils/hasEditRecordsUITroubles';
import {isNative} from '../constants/general';

type Props = {
  form: TimeRecordForm;
};

export const TimeRecordFormView: React.FC<Props> = observer((props) => {
  const app = appProvider.application;

  const renderTimeIcon = () => {
    return <Icon name="timer" color={theme.textColorLight900} style={s.icon} />;
  };

  const renderDateIcon = () => {
    return (
      <Icon
        name="calendar"
        color={props.form.dateLocked ? theme.textColorLight700 : theme.textColorLight900}
        style={s.icon}
      />
    );
  };

  const renderArrowIcon = () => {
    return <Icon name="arrow_right" color={theme.textColorLight900} style={s.icon} />;
  };

  const onSelectAgreementOrThread = () => {
    app.showAgreementOrThreadSelector(props.form);
  };

  const onSelectDate = () => {
    props.form.dateField.setFocused(true);
    if (isNative) {
      props.form.setShowDatePicker(true);
    } else {
      app.showWebCalendar(props.form);
    }
  };

  const onPressAsanaTaskField = () => {
    //if (!props.form.asanaProjectId) return;
    app.model.asanaTasksList.setProjectId(props.form.asanaProjectId);
    if (app.model.agreementsList.empty) app.getAgreements();
    if (app.model.threadsList.empty) app.getThreads();
    app.showAsanaTaskSelector(props.form);
  };

  const renderCommentBlock = () => {
    return (
      <View style={styles[theme.stylesType].commentBlock}>
        <FormInputLabel labelText={strings('common.notes')} field={props.form.commentField} />
        <FormInputBlock
          field={props.form.commentField}
          type="textarea"
          autoCapitalize="sentences"
          placeholder={strings('placeholder.comment')}
          textarea={{multiline: true, numberOfLines: 2}}
        />
      </View>
    );
  };

  return (
    <>
      <View style={styles[theme.stylesType].container}>
        <FormInputLabel labelText={strings('common.time.record')} field={props.form.timeField} warning />
        <FormInputBlock field={props.form.timeField} type="time" renderIconBlock={renderTimeIcon} />
        <View style={s.spacer} />
        <FormInputLabel
          labelText={strings('common.date')}
          field={props.form.dateField}
          warning={!props.form.dateLocked}
          disabled={props.form.dateLocked}
        />
        <FormInputBlock
          field={props.form.dateField}
          type="date"
          placeholder={props.form.datePlaceholder}
          renderIconBlock={renderDateIcon}
          onPress={onSelectDate}
          disabled={props.form.dateLocked}
        />
        <View style={s.spacer} />
        <FormInputLabel
          labelText={`${strings('common.project')} / ${strings('common.process')}`}
          field={props.form.agreementOrThreadField}
          warning
        />
        <FormInputBlock
          onPress={onSelectAgreementOrThread}
          field={props.form.agreementOrThreadField}
          type="dummy"
          renderIconBlock={renderArrowIcon}
        />
        <View style={s.spacer} />
        <FormInputLabel labelText={strings('common.task')} field={props.form.taskField} warning />
        <FormInputBlock
          onPress={onPressAsanaTaskField}
          field={props.form.taskField}
          type="dummy"
          placeholder={strings('placeholder.select.task')}
          renderIconBlock={renderArrowIcon}
        />
      </View>
      {hasEditRecordsUITroubles(app.model.session.email) ? (
        // TODO make more clean solution for iPhone layout issue without this hack
        <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={theme.aligned(80)}>
          {renderCommentBlock()}
        </KeyboardAvoidingView>
      ) : (
        renderCommentBlock()
      )}
    </>
  );
});

interface IRecordFormStyles {
  container: ViewStyle;
  commentBlock: ViewStyle;
}

interface IStyles {
  [x: string]: IRecordFormStyles;
}

const styles: IStyles = {
  web: {
    container: {
      paddingVertical: theme.space24,
      flex: 1,
    },
    commentBlock: {
      paddingVertical: theme.space24,
    },
  },
  native: {
    container: {
      marginTop: theme.aligned(40),
      backgroundColor: theme.bgColorDark200,
      borderRadius: theme.cardBorderRadius,
      paddingHorizontal: theme.space16,
      paddingVertical: theme.space24,
      flex: 1,
    },
    commentBlock: {
      marginTop: theme.aligned(32),
      backgroundColor: theme.bgColorDark200,
      borderRadius: theme.cardBorderRadius,
      paddingHorizontal: theme.space16,
      paddingVertical: theme.space24,
    },
  },
};

const s = StyleSheet.create({
  icon: {
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(46),
  },
  spacer: {
    height: theme.space16,
  },
});
