import React from 'react';
import {observer} from 'mobx-react';
import {View, StyleSheet, Text, Pressable, ActivityIndicator, ViewStyle} from 'react-native';
import {expr} from 'mobx-utils';
import {endOfDay, isSameDay, isSunday, isWithinInterval, startOfDay} from 'date-fns';

import {strings} from '../locales/i18n';
import {TimeRecord} from '../models/timeRecord';
import theme from '../theme';
import {appProvider} from '../appProvider';
import OwnIcons from '../elements/OwnIcons';
import {HoursTrackedBlock} from './HoursTrackedBlock';
import {Button} from '../elements/Button';
import {DailyRecord} from '../models/dailyRecord';
import {isNative} from '../constants/general';

type Props = {
  record: DailyRecord;
};

export const DailyTaskSelector: React.FC<Props> = observer((props) => {
  const app = appProvider.application;
  const list = expr(() => {
    const ctrl = app.model.dailyCtrl;
    return app.model.timeRecordsList.all.filter((el) => {
      if (props.record.isToday) {
        return isSameDay(el.recordDate, props.record.dailyDate);
      }
      return isWithinInterval(el.recordDate, {
        start: startOfDay(ctrl.lastDateDay),
        end: endOfDay(app.model.dailyCtrl.yesterdayDate),
      });
    });
  });

  const hoursSpent = expr(
    () => list.filter((el) => el.isSelected).reduce((acc, curr) => acc + curr.hoursSpent, 0) / 3600,
  );

  const hasSelected = expr(() => list.some((el) => el.isSelected));

  const onClose = () => {
    if (isNative) {
      app.modal.hideLastEntry();
    } else {
      app.navigateToMainScreen();
    }
  };
  const onSave = () => props.record.saveSelectedTimeRecords();

  const renderTitleButton = () => {
    if (!isNative) return null;
    return <Pressable onPress={onTitleButtonPress}>{renderTitleButtonIcon()}</Pressable>;
  };

  const renderTitleButtonIcon = () => {
    if (hasSelected) {
      return <OwnIcons name={'check_in_circle'} color={theme.bgColorPurple100} size={theme.fontSize24} />;
    }
    return <OwnIcons name={'close'} color={theme.textColorLight300} size={theme.fontSize24} />;
  };

  const onTitleButtonPress = () => {
    if (hasSelected) {
      onSave();
    }
    onClose();
  };

  const afterTimeRecordAdd = (timeRecId: string) => {
    if (timeRecId) {
      props.record.timeRecordIds.push(timeRecId);
    }
    app.showDailyTaskSelector(props.record);
  };

  const onAddPress = () => {
    onClose();
    let date = props.record.dailyDate;
    if (!props.record.isToday && isSunday(app.model.dailyCtrl.yesterdayDate)) {
      date = app.model.dailyCtrl.yesterdayDate.getTime();
    }
    app.navigateToAddTimeRecord({
      date,
      lockDate: true,
      afterAddAction: afterTimeRecordAdd,
    });
  };

  const renderList = () => {
    if (app.model.timeRecordsList.loading) {
      return <ActivityIndicator style={s.loader} />;
    }
    return (
      <>
        {list.map((el) => (
          <React.Fragment key={el.id}>
            <DailyTaskSelectorItem record={el} key={el.id} />
            <View style={s.spacer} />
          </React.Fragment>
        ))}
      </>
    );
  };

  const onButtonPress = () => {
    if (hasSelected) {
      onSave();
    }
    onClose();
  };

  const renderActionBlock = () => {
    return (
      <View style={s.buttonBlock}>
        <Button title={strings(hasSelected ? 'action.add' : 'common.cancel')} onPress={onButtonPress} />
      </View>
    );
  };

  // TODO add task selector for holidays
  return (
    <View style={styles[theme.stylesType].container}>
      <View style={s.titleRow}>
        <Text style={s.title}>{strings('info.daily.task.selector.title')}</Text>
        <Pressable onPress={onTitleButtonPress}>{renderTitleButton()}</Pressable>
      </View>
      <View style={styles[theme.stylesType].hoursBlock}>
        <HoursTrackedBlock
          hoursSpent={hoursSpent}
          hoursAvailable={app.model.user.hoursPerDay}
          backgroundColor={theme.bgColorDark300}
          fullWidth={(isNative ? theme.windowWidth : theme.webScreensWidth) - theme.space16 * 2}
        />
      </View>
      {renderList()}
      <Pressable style={s.addBlock} onPress={onAddPress}>
        <OwnIcons name={'plus'} color={theme.textColorSecondary} style={s.addIcon} />
        <Text style={s.taskTitle} numberOfLines={1} ellipsizeMode="tail">
          {strings('action.new.record')}
        </Text>
      </Pressable>
      {renderActionBlock()}
    </View>
  );
});

type ItemProps = {
  record: TimeRecord;
};

const DailyTaskSelectorItem: React.FC<ItemProps> = observer((props) => {
  const selected = props.record.isSelected;
  return (
    <Pressable style={[s.row, selected ? s.selected : null]} onPress={props.record.toggleSelected}>
      <View style={s.tasksBlock}>
        <Text style={s.agreement} numberOfLines={1} ellipsizeMode="tail">
          {props.record.title}
        </Text>
        <Text style={s.taskTitle} numberOfLines={2}>
          {props.record.subTitleCalculated}
        </Text>
      </View>
      <View style={s.rightBlock}>
        <Text style={s.text}>{props.record.hoursSpentFormatted}</Text>
        {selected ? (
          <View style={s.iconBlock}>
            <OwnIcons name={'check'} color={theme.bgColorPurple100} />
          </View>
        ) : null}
      </View>
    </Pressable>
  );
});

interface IScreenStyles {
  container: ViewStyle;
  hoursBlock: ViewStyle;
}

interface IStyles {
  [x: string]: IScreenStyles;
}

const styles: IStyles = {
  web: {
    container: {
      flex: 1,
      width: theme.webScreensWidth,
      alignContent: 'center',
      margin: 'auto',
      paddingVertical: theme.space16,
    },
    hoursBlock: {
      marginTop: theme.space16,
      marginBottom: theme.space8,
    },
  },
  native: {
    container: {
      padding: theme.space16,
      backgroundColor: theme.bgColorDark300,
      borderTopLeftRadius: theme.cardBorderRadius,
      borderTopRightRadius: theme.cardBorderRadius,
    },
    hoursBlock: {
      marginHorizontal: -theme.space16,
    },
  },
};

const s = StyleSheet.create({
  container: {
    padding: theme.space16,
    backgroundColor: theme.bgColorDark300,
    borderTopLeftRadius: theme.cardBorderRadius,
    borderTopRightRadius: theme.cardBorderRadius,
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spacer: {
    height: theme.space8,
  },
  buttonBlock: {
    paddingTop: theme.space8,
  },
  tasksBlock: {
    flex: 1,
  },
  title: {
    color: theme.textColor,
    fontSize: theme.fontSize24,
    fontFamily: theme.fontFamilyMedium,
    fontWeight: '600',
  },
  rightBlock: {
    justifyContent: 'space-between',
  },
  iconBlock: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.bgColorDark400,
    borderRadius: theme.cardBorderRadius,
    padding: theme.space16,
    minHeight: theme.aligned(80),
  },
  addIcon: {
    paddingRight: theme.space16,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.bgColorDark400,
    minHeight: theme.aligned(80),
    borderRadius: theme.cardBorderRadius,
    padding: theme.space16,
  },
  selected: {
    backgroundColor: theme.bgColorDark500,
  },
  text: {
    color: theme.textColor,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    lineHeight: theme.aligned(20),
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  agreement: {
    color: theme.textColor,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    lineHeight: theme.aligned(20),
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
    paddingBottom: theme.aligned(6),
    paddingRight: theme.space8,
  },
  taskTitle: {
    color: theme.textColorSecondary,
    fontSize: theme.fontSize14,
    lineHeight: theme.aligned(18),
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '400',
    flexShrink: 1,
  },
  loader: {
    paddingBottom: theme.space24,
  },
});
