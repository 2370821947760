import React, {ReactText} from 'react';
import {NavigationContainerRef} from '@react-navigation/core';
// @ts-ignore
import en from 'src/locales/en';

import * as api from './api';

import {IFs} from './storage';
import {IconName} from './elements/OwnIcons';

// @ts-ignore TODO typing
export type AppNavigationType = React.RefObject<NavigationContainerRef>;

export interface IConsole {
  error(message?: any, ...optionalParams: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  log(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
}

export interface ILogger extends IConsole {
  info(message?: any, ...args: any[]): void;
  error(...args: any[]): void;
}

export interface IApplicationConfig {
  console?: IConsole;
  fs: IFs;
  navigation: AppNavigationType;
}

export enum TimeRecordType {
  Project = 'Project',
  Process = 'Process',
}

export interface ITabItem {
  id: ReactText;
  text: string;
}

export enum TabType {
  Daily = 0,
  History = 1,
  Time = 2,
}

export interface ISessionSerialized extends api.ISession {
  id: string;
  authorized?: boolean;
}

export enum LoginStep {
  Email = 'EmailStep',
  PinCode = 'PinCode',
}

export type TranslationsReference = typeof en;
export type Translations = Record<string, string | object>;
export type TranslationsKey = keyof TranslationsReference;

export type ModalType = 'bottom' | 'dialog';

export type ModalRenderer = (entry: IModalEntry) => React.ReactNode;

export interface IModalEntry {
  readonly id: string;
}

export interface IModalConfig {
  backgroundColor?: string;
  onHide?: () => void;
  persistent?: boolean;
  type?: ModalType;
  multipleMode?: boolean;
}

export interface IModalController {
  readonly canHideLastEntry: boolean;
  readonly isVisible: boolean;

  show(render: ModalRenderer, config?: IModalConfig): IModalEntry;
  hide(entryId?: string): void;
  hideLastEntry(): void;
}

export type ConfirmationModalButton = {
  isConfirm?: boolean;
  isWarning?: boolean;
  action: () => void;
  text: string;
};

export interface IModalConfirmConfig {
  onConfirm: () => void;
  title?: string;
  contentRenderer?: () => React.ReactNode;
  text?: string;
  confirmText?: string;
  isConfirm?: boolean;
  confirmOnly?: boolean;
  onClose?: () => void;
  buttons?: ConfirmationModalButton[];
  buttonsInVerticalRows?: boolean;
}

export interface IMenuItem {
  title: string;
  iconName?: IconName;
  badge?: number;
  onPress?: () => void;
  iconSize?: number;
  warning?: boolean;
  iosBold?: boolean;
}

export interface ITimerController {
  initialSeconds: number;
  currentSeconds: number;
  intervalId: ReturnType<typeof setInterval> | null;
  start(): void;
  clear(): void;
  stop(): void;
  setInitialSeconds(val?: number): void;
  isTimerActive: boolean;
}

export type SearchAsanaTaskParams = {
  projectId: string;
  text: string;
  createdOn: string;
  isLoadMore?: boolean;
};

export type AddTimeRecordConfig = {
  date: number;
  lockDate?: boolean;
  afterAddAction?: CallbackFunction;
};

export type CallbackFunction = (args?: any) => void;
