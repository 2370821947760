// @ts-nocheck
import session_expired from './images/session_expired/session_expired.png';
import no_connection from './images/no_connection/no_connection.png';
import empty_timer from './images/empty_timer/empty_timer.png';
import active_timer from './images/active_timer/active_timer.png';
import paused_timer from './images/paused_timer/paused_timer.png';
import violet_logo from './images/violet_logo/violet_logo.png';
import violet_logo_horizontal from './images/violet_logo/violet_logo_horizontal.png';
import search_empty from './images/search_empty/search_empty.png';
import task_search from './images/task_search/task_search.png';

const images = {
  session_expired,
  no_connection,
  empty_timer,
  active_timer,
  paused_timer,
  violet_logo,
  violet_logo_horizontal,
  search_empty,
  task_search,
};

export default images;
