import React from 'react';
import {ActivityIndicator, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import theme from '../theme';
import Icon, {IconName} from './OwnIcons';
import {isNative} from '../constants/general';

interface Props {
  iconName?: IconName;
  title: string;
  onPress?: () => void;
  disabled?: boolean;
  disableTouchOnly?: boolean;
  loading?: boolean;
  iconColor?: string;
  borderColor?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined';
}

export const Button: React.FC<Props> = (props) => {
  const isSecondary = props.variant === 'secondary';
  const isTertiary = props.variant === 'tertiary';
  const buttonStyles = [
    isSecondary ? s.secondaryButton : s.button,
    s.buttonShape,
    isTertiary ? s.buttonTertiary : {},
    props.disabled ? s.disabledButton : {},
    props.borderColor ? {borderColor: props.borderColor} : {},
  ];
  const buttonTextStyles = [isSecondary ? s.buttonSecondaryText : s.buttonText, s.text];
  const disabled = props.disableTouchOnly || props.disabled || !props.onPress || props.loading;

  const renderIconBlock = () => {
    if (props.loading) {
      return <ActivityIndicator color={theme.textColor} size="small" />;
    }
    if (props.iconName) {
      return (
        <Icon name={props.iconName} style={s.buttonIcon} color={props.iconColor ? props.iconColor : theme.textColor} />
      );
    }
    return null;
  };
  return (
    <TouchableOpacity style={buttonStyles} onPress={props.onPress} disabled={disabled}>
      <View style={s.content}>
        {renderIconBlock()}
        <Text style={buttonTextStyles}>{props.title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const s = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isNative ? theme.bgColorPurple100 : theme.bgColorPrimary,
  },
  secondaryButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: isNative ? theme.bgColorDark500 : theme.bgColorDark300Web,
  },
  disabledButton: {
    opacity: 0.3,
  },
  buttonText: {
    fontFamily: theme.fontFamilyMain,
    textAlign: 'center',
    color: theme.textColor,
    fontSize: theme.fontSize16,
  },
  buttonSecondaryText: {
    fontFamily: theme.fontFamilyMain,
    textAlign: 'center',
    color: theme.textColor,
    fontSize: theme.fontSize16,
  },
  buttonShape: {
    minHeight: theme.aligned(48),
    borderRadius: theme.cardBorderRadius,
  },
  text: {
    fontFamily: theme.fontFamilyMain,
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(21),
    marginHorizontal: theme.aligned(10),
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonIcon: {
    fontSize: theme.fontSize16,
    textAlign: 'center',
    lineHeight: theme.aligned(22),
  },
  buttonTertiary: {
    backgroundColor: 'transparent',
    borderColor: theme.textColor,
    borderWidth: theme.pt,
  },
  activityIndicator: {
    alignSelf: 'center',
  },
});
