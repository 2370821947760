import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, KeyboardAvoidingView, TouchableOpacity, Text, View, Keyboard, ViewStyle} from 'react-native';

import {FormInputLabel} from './FormInputLabel';
import {FormInputBlock} from './FormInputBlock';
import {Button} from '../elements/Button';
import {LoginController} from '../models/loginController';
import theme from '../theme';
import Icon from '../elements/OwnIcons';
import {strings} from '../locales/i18n';

type Props = {
  ctrl: LoginController;
};

export const EmailEnterForm: React.FC<Props> = observer((props) => {
  const renderIconBlock = () => {
    if (props.ctrl.email.length === 0) return null;
    return (
      <TouchableOpacity onPress={() => props.ctrl.emailField.setValue('')} hitSlop={theme.smallElementsHitSlop}>
        <Icon name="close" color={theme.textColorLight900} style={s.icon} />
      </TouchableOpacity>
    );
  };

  const hideKeyboard = () => Keyboard.dismiss();

  return (
    <KeyboardAvoidingView style={styles[theme.stylesType].content}>
      <View style={styles[theme.stylesType].header} onTouchStart={hideKeyboard}>
        <Text style={s.title}>{strings('common.hello')}!</Text>
        <Text style={s.subTitle}>{strings('info.login.subtitle1')}</Text>
        <Text style={s.subTitle}>{strings('info.login.subtitle2')}</Text>
      </View>
      <FormInputLabel field={props.ctrl.emailField} labelText={strings('placeholder.email')} />
      <FormInputBlock field={props.ctrl.emailField} autofocus type="email" renderIconBlock={renderIconBlock} />
      <View style={s.spacer} onTouchStart={hideKeyboard} />
      <Button
        loading={props.ctrl.loading}
        title={`${strings('common.continue')} ${strings('common.with.email')}`}
        disabled={props.ctrl.loading || !props.ctrl.emailField.isValid}
        onPress={props.ctrl.onLogin}
        iconName="email"
      />
      <View style={s.flex} onTouchStart={hideKeyboard} />
    </KeyboardAvoidingView>
  );
});

interface IEmailFormStyles {
  content: ViewStyle;
  header: ViewStyle;
}

interface IStyles {
  [x: string]: IEmailFormStyles;
}

const styles: IStyles = {
  web: {
    content: {
      flex: 1,
      width: theme.webScreensWidth,
      justifyContent: 'center',
      alignContent: 'center',
      margin: 'auto',
    },
    header: {
      justifyContent: 'flex-end',
      flex: 1,
      paddingBottom: theme.space40,
    },
  },
  native: {
    content: {
      flex: 1,
      paddingHorizontal: theme.space16,
    },
    header: {
      justifyContent: 'center',
      flex: 1,
    },
  },
};

const s = StyleSheet.create({
  spacer: {
    height: theme.space16,
  },
  icon: {
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(46),
  },
  title: {
    textAlign: 'center',
    fontSize: theme.fontSize32,
    color: theme.textColor,
    fontWeight: '600',
    fontFamily: theme.fontFamilyMedium,
    paddingVertical: theme.space16,
  },
  subTitle: {
    textAlign: 'center',
    fontSize: theme.fontSize16,
    color: theme.textColorSecondary,
    fontWeight: '500',
    lineHeight: theme.aligned(24),
    fontFamily: theme.fontFamilyMain,
  },
  flex: {
    flex: 1,
  },
});
