import {makeAutoObservable} from 'mobx';
import * as api from '../api';
import {locale} from '../locales/i18n';

export class WelcomeTextsList {
  texts: string[] = [];
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getRandomText() {
    if (!this.texts.length) return '';
    const rndIdx = Math.round(Math.random() * (this.texts.length - 1 || 1));
    return this.texts[rndIdx] || this.texts[0];
  }

  setFromApi(p?: api.WelcomeTextsResponseItem[]) {
    let filtered: api.WelcomeTextsResponseItem[] = [];
    if (!Array.isArray(p)) return;
    if (locale === 'ru') {
      filtered = p.filter((el) => el.fields.language === 'ru');
    } else {
      filtered = p.filter((el) => el.fields.language === 'en');
    }
    this.texts = [...filtered.map((el) => el.fields.text)];
  }

  clear() {
    this.texts = [];
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}
