import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const getTimerByEmail = makeHandler(
  'getTimerByEmail',
  (email: string) => ({
    method: 'GET',
    path: `/Timers?${getTimerByEmailParams(email)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: GetTimerResponse) => res.data?.records[0],
);

export const addTimerRecord = makeHandler(
  'addTimerRecord',
  (data: Timer4Export) => ({
    method: 'POST',
    data: {records: [{fields: data}]},
    path: '/Timers/',
    apiType: RequestApiType.AirTable,
  }),
  (res: GetTimerResponse) => res.data?.records[0],
);

export const updateTimerRecord = makeHandler(
  'updateTimerRecord',
  (req: {id: string; data: Timer4Export}) => ({
    method: 'PATCH',
    data: {records: [{id: req.id, fields: req.data}]},
    path: '/Timers/',
    apiType: RequestApiType.AirTable,
  }),
  (res: GetTimerResponse) => res.data?.records[0],
);

type GetTimerResponse = IResponse<ITimer>;

export type GetTimerResponseItem = IResponseItem<ITimer>;

export interface ITimer {
  id?: string;
  email: string;
  start_date: string;
  time_spent: number; // duration in seconds
  is_active: boolean;
  reports_id: string[];
}

export type Timer4Export = {
  email?: string;
  time_spent?: number;
  start_date?: string | null;
  is_active?: boolean;
  reports_id?: string[];
};

function getTimerByEmailParams(email: string) {
  const fields = ['email', 'start_date', 'time_spent', 'is_active', 'reports_id'];
  return objectToQueryParamString({
    'fields[]': fields,
    filterByFormula: `{email} = "${email}"`,
  });
}
