import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View, ViewStyle} from 'react-native';

import theme from '../theme';
import {IMenuItem} from '../types';
import {isNative} from '../constants/general';

const ITEM_HEIGHT = theme.aligned(60);

interface IProps {
  item: IMenuItem;
  hideUnderline?: boolean;
  itemHeight?: number;
}

export const MenuItem: React.FC<IProps> = (props: IProps) => {
  const {item, hideUnderline, itemHeight} = props;
  const itemStyle = [
    styles.item,
    !hideUnderline ? styles.itemBorder : null,
    itemHeight ? itemHeightStyle(itemHeight) : null,
  ];
  const textStyle = [styles.text, item.warning ? styles.warning : null, item.iosBold ? styles.bold : null];
  return (
    <TouchableOpacity style={itemStyle} onPress={item.onPress}>
      <Text numberOfLines={1} style={textStyle}>
        {item.title}
      </Text>
      {item.badge ? (
        <View style={styles.badge}>
          <Text style={styles.badgeText}>{item.badge}</Text>
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    paddingHorizontal: theme.space16,
    height: ITEM_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemBorder: {
    borderBottomColor: isNative ? theme.lightGreyColor : theme.bgColorDark400,
    borderBottomWidth: theme.pt,
  },
  text: {
    fontSize: theme.fontSize16,
    fontWeight: '500',
    color: theme.textColor,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  badge: {
    backgroundColor: theme.bgColorRed100,
    fontWeight: '600',
    paddingHorizontal: theme.space8,
    borderRadius: theme.cardBorderRadius,
    marginLeft: theme.space16,
    marginVertical: theme.space20,
  },
  badgeText: {
    color: theme.bgColor,
  },
  warning: {
    color: theme.bgColorRed100,
  },
  bold: {
    fontWeight: '700',
  },
});

const itemHeightStyle = (height: number): ViewStyle => {
  return {height: theme.aligned(height)};
};
