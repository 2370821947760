import React from 'react';
import {observer} from 'mobx-react-lite';
import {ActivityIndicator, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View, ViewStyle} from 'react-native';
import {expr} from 'mobx-utils';

import theme from '../theme';
import {strings} from '../locales/i18n';
import {appProvider} from '../appProvider';
import {TimeRecordForm} from '../models/timeRecordForm';
import {FormInputBlock} from './FormInputBlock';
import {AsanaTask, FoundedAgrementOrThread} from '../models/asanaTask';
import images from '../images';
import OwnIcons from '../elements/OwnIcons';
import Icon from '../elements/OwnIcons';
import {IMenuItem, IModalConfirmConfig, TimeRecordType} from '../types';
import {SearchMode} from '../models/asanaTasksFilteredList';
import {isNative} from '../constants/general';

type Props = {
  onClose: () => void;
  form: TimeRecordForm;
};

export const AsanaTaskSelector: React.FC<Props> = observer((props) => {
  const app = appProvider.application;
  const tasksList = app.model.asanaTasksList;

  const onSelect = (task: AsanaTask) => {
    props.form.setTask(task);
    props.onClose();
  };

  const onClearSearchCache = () => {
    tasksList.setLoading(true);
    tasksList.setSearchMode(SearchMode.FromAsana);
    app.model.asanaTasks.clear(tasksList.projectId);
    tasksList.clearSearch();
    tasksList.setShowOnlyMyTasks(false);
    app.modal.hideLastEntry();
    setTimeout(() => tasksList.setLoading(false), 500);
  };

  const onPressReload = () => {
    const menuItems: IMenuItem[] = [];
    if (tasksList.searchStringField.isValid) {
      menuItems.push({
        title: strings('action.repeat.search'),
        onPress: () => {
          tasksList.reloadSearchResults();
          app.modal.hideLastEntry();
        },
      });
    }
    if (tasksList.tasksCached.length > 0) {
      menuItems.push({
        title: strings('action.drop.cache'),
        onPress: onClearSearchCache,
      });
    }
    app.showMenuModal(menuItems);
  };

  const onPressMyTasksBtn = () => {
    tasksList.toggleOnlyMyTasksMode();
  };

  const renderPlaceholderBlock = () => {
    return (
      <View style={s.centered}>
        <Image source={images.task_search} />
        <Text style={s.infoText}>{strings('info.start.typing.task')}</Text>
      </View>
    );
  };

  const renderEmptyBlock = () => {
    return (
      <View style={s.centered}>
        <Image source={images.search_empty} />
        <Text style={s.infoText}>{strings('info.no.results.found')}</Text>
        {!props.form.agreementOrThreadField.isValid ? renderProjectSelectMessage() : null}
      </View>
    );
  };

  const renderProjectSelectMessage = () => {
    return (
      <TouchableOpacity
        style={s.projectSelectBlock}
        onPress={() => {
          app.modal.hideLastEntry();
          app.showAgreementOrThreadSelector(props.form);
        }}>
        <Text style={s.linkText}>{strings('error.no.project.selected')}</Text>
      </TouchableOpacity>
    );
  };

  const renderSearchMoreBlock = () => {
    return (
      <TouchableOpacity onPress={tasksList.reloadSearchResults} style={s.link}>
        <Text style={s.linkText}>{strings('action.search.more')}</Text>
      </TouchableOpacity>
    );
  };

  const showPlaceholder = expr(() => {
    if (tasksList.selected || tasksList.showOnlyMyTasks) return false;
    if (tasksList.isInCachedTasksMode && tasksList.hasCachedAssigneeTasks) return false;
    return !tasksList.searchString || !tasksList.searchStringField.isValid;
  });

  const showSearchMoreBlock = expr(() => {
    if (!tasksList.isInCachedTasksMode) return false;
    if (!tasksList.searchStringField.isValid && !tasksList.hasCachedAssigneeTasks) return false;
    return !tasksList.hasCachedTasks && props.form.agreementOrThreadField.isValid;
  });

  const renderList = () => {
    if (showSearchMoreBlock) return renderSearchMoreBlock();
    if (showPlaceholder) return renderPlaceholderBlock();
    if (tasksList.empty) return renderEmptyBlock();
    const ListComponent = isNative ? View : ScrollView;
    const nodes = renderListContent();
    if (!nodes.length) return null;
    return <ListComponent style={s.list}>{nodes}</ListComponent>;
  };

  const renderListContent = (): React.ReactNode[] => {
    const nodes: React.ReactNode[] = [];
    if (tasksList.selected) {
      nodes.push(
        <TaskListItem
          task={tasksList.selected}
          onSelect={onSelect}
          selected
          form={props.form}
          key={tasksList.selected.id}
        />,
      );
    }
    if (props.form.agreementOrThreadField.isValid) {
      nodes.push([
        ...tasksList.tasks.map((el) => {
          if (el.id === tasksList.selected?.id) return null;
          return <TaskListItem task={el} onSelect={onSelect} key={el.id} form={props.form} />;
        }),
      ]);
    }
    return nodes;
  };

  const renderIconBlock = () => {
    if (tasksList.searchString.length === 0) return null;
    return (
      <TouchableOpacity onPress={() => tasksList.searchStringField.setValue('')} hitSlop={theme.smallElementsHitSlop}>
        <Icon name="close" color={theme.textColorLight900} style={s.icon} />
      </TouchableOpacity>
    );
  };

  const renderActionsBlock = () => {
    return (
      <View style={s.row}>
        {props.form.agreementOrThreadField.isValid ? (
          <>
            {renderReloadBlock()}
            {renderMyTasksButton()}
          </>
        ) : null}
      </View>
    );
  };

  const renderMyTasksButton = () => {
    const style = tasksList.showOnlyMyTasks ? s.myTasksBtnSelected : s.myTasksBtn;
    const textStyle = tasksList.showOnlyMyTasks ? s.myTasksTextSelected : s.myTasksText;
    return (
      <TouchableOpacity style={style} hitSlop={theme.smallElementsHitSlop} onPress={onPressMyTasksBtn}>
        <Text style={textStyle}>my</Text>
      </TouchableOpacity>
    );
  };

  const renderReloadBlock = () => {
    if (tasksList.isInCachedTasksMode || tasksList.searchStringField.isValid) {
      return (
        <>
          <TouchableOpacity style={s.reloadBlock} hitSlop={theme.smallElementsHitSlop} onPress={onPressReload}>
            <OwnIcons name="reload" color={theme.textColor} size={theme.fontSize16} />
          </TouchableOpacity>
          <View style={s.smallSpacer} />
        </>
      );
    }
    return null;
  };

  return (
    <View style={styles[theme.stylesType].container}>
      <View style={styles[theme.stylesType].titleRow}>
        <Text style={s.back} onPress={props.onClose} numberOfLines={1} adjustsFontSizeToFit>
          {strings('common.cancel')}
        </Text>
        <Text style={s.text}>{strings('common.task')}</Text>
        {renderActionsBlock()}
      </View>
      <View style={s.searchBlock}>
        <FormInputBlock
          field={app.model.asanaTasksList.searchStringField}
          type="search"
          placeholder={strings('placeholder.task.search')}
          autofocus={!tasksList.selected}
          renderIconBlock={renderIconBlock}
        />
      </View>
      {tasksList.loading ? (
        <ActivityIndicator color={theme.textColor} size={'large'} style={s.activityIndicator} />
      ) : (
        renderList()
      )}
    </View>
  );
});

type TaskListProps = {
  task: AsanaTask;
  form: TimeRecordForm;
  onSelect: (task: AsanaTask) => void;
  selected?: boolean;
};

const TaskListItem: React.FC<TaskListProps> = observer((props) => {
  const app = appProvider.application;

  const onSetProject = (project: FoundedAgrementOrThread) => {
    if (project.agreement) {
      props.form.setAgreementOrThread({id: project.agreement.id, type: TimeRecordType.Project});
      props.onSelect(props.task);
      return;
    }
    if (project.thread) {
      props.form.setAgreementOrThread({id: project.thread.id, type: TimeRecordType.Process});
      props.onSelect(props.task);
      return;
    }
  };

  const onSelect = () => {
    if (!props.task.hasProjectInfo) {
      const config: IModalConfirmConfig = {
        onConfirm: app.modal.hideLastEntry,
        title: strings('error.unable.task.select'),
        text: strings('info.task.not.linked.any.project'),
        confirmOnly: true,
        confirmText: strings('action.understand'),
      };
      app.showConfirmModal(config);
      return;
    }

    const agreementOrThread = props.task.getAgreementOrThreadByAsanaId();
    if (props.selected && !taskInProject) {
      const canSetTaskProject = !!agreementOrThread.agreement || !!agreementOrThread.thread;

      // TODO may be check all tasks
      const config: IModalConfirmConfig = canSetTaskProject
        ? {
            onConfirm: () => onSetProject(agreementOrThread),
            title: strings('error.warning'),
            text: `${strings('info.task.linked.another.project')}\n${props.task.projects[0] || ''}`,
            confirmText: strings('action.replace'),
          }
        : {
            onConfirm: app.modal.hideLastEntry,
            title: strings('error.unable.task.select'),
            text: `${strings('info.task.linked.another.project')}\n${props.task.projects.join('\n')}`,
            confirmOnly: true,
            confirmText: strings('action.resume'),
          };
      app.showConfirmModal(config);
      return;
    } else {
      if (!props.form.agreementOrThreadField.isValid) onSetProject(agreementOrThread);
      props.onSelect(props.task);
    }
  };

  const taskInProject = expr(() => {
    const list = app.model.asanaTasksList;
    return props.task.inProject(list.projectId);
  });

  if (props.selected) {
    const showCheckedMark = expr(() => {
      if (props.task.loading) return false;
      if (!props.task.hasProjectInfo) return true;
      return taskInProject;
    });

    return (
      <TouchableOpacity style={[s.item, s.selected]} onPress={onSelect} disabled={props.task.loading}>
        <View style={s.textsBlock}>
          {props.task.parent ? <Text style={s.subText}>{props.task.parent.name}</Text> : null}
          <Text style={s.text}>{props.task.name}</Text>
        </View>
        {props.task.loading ? <ActivityIndicator size="small" color={theme.textColor} /> : null}
        {showCheckedMark ? <OwnIcons name="check" color={theme.bgColorPurple100} /> : null}
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity style={s.item} onPress={onSelect}>
      <View style={s.textsBlock}>
        {props.task.parent ? <Text style={s.subText}>{props.task.parent.name}</Text> : null}
        <Text style={s.text}>{props.task.name}</Text>
      </View>
      {props.task.isAssigneeToMe ? <AssignToMeBlock /> : null}
    </TouchableOpacity>
  );
});

const AssignToMeBlock = () => {
  return (
    <View style={s.greetHint}>
      <Text style={s.hintText}>my</Text>
    </View>
  );
};

interface ISelectorStyles {
  container: ViewStyle;
  titleRow: ViewStyle;
}

interface IStyles {
  [x: string]: ISelectorStyles;
}

const styles: IStyles = {
  web: {
    container: {
      backgroundColor: theme.bgColorWeb,
      width: '100%',
      maxWidth: theme.aligned(500),
      flex: 1,
      alignSelf: 'center',
    },
    titleRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.space16,
    },
  },
  native: {
    container: {
      borderTopLeftRadius: theme.modalBorderRadius,
      borderTopRightRadius: theme.modalBorderRadius,
      backgroundColor: theme.bgColorDark300,
      padding: theme.space16,
      flex: 1,
    },
    titleRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.space12,
      marginBottom: theme.space32,
    },
  },
};

const s = StyleSheet.create({
  back: {
    width: theme.aligned(60),
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },

  subText: {
    paddingRight: theme.space8,
    color: theme.textColorSecondary,
    fontSize: theme.fontSize12,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    flexShrink: 1,
    paddingBottom: theme.space8,
  },
  textsBlock: {
    flexShrink: 1,
  },
  text: {
    paddingRight: theme.space8,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    flexShrink: 1,
  },
  infoText: {
    paddingHorizontal: theme.space16,
    color: theme.textColorSecondary,
    fontSize: theme.fontSize18,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
  },
  reloadBlock: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: theme.space12,
    width: theme.space16,
  },
  spacer: {
    width: theme.space16,
  },
  smallSpacer: {
    width: theme.space4,
  },
  item: {
    paddingHorizontal: theme.space12,
    paddingVertical: theme.space10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  list: {
    backgroundColor: theme.bgColorDark400,
    borderRadius: theme.cardBorderRadius,
    paddingVertical: theme.space14,
    paddingHorizontal: theme.space12,
  },
  activityIndicator: {
    padding: theme.space24,
    alignSelf: 'center',
  },
  searchBlock: {
    marginBottom: theme.space24,
  },
  centered: {
    alignItems: 'center',
  },
  selected: {
    backgroundColor: theme.bgColorDark700,
    borderRadius: theme.cardBorderRadius,
  },
  icon: {
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(46),
  },
  greetHint: {
    paddingVertical: theme.aligned(2),
    paddingHorizontal: theme.aligned(4),
    backgroundColor: theme.bgColorGreen100,
    borderRadius: theme.cardBorderRadius,
    position: 'absolute',
    right: 0,
    top: theme.aligned(10),
  },
  hintText: {
    color: theme.textColor,
    fontSize: theme.aligned(10),
    fontWeight: 'bold',
  },
  link: {
    paddingHorizontal: theme.aligned(12),
    flexShrink: 1,
  },
  linkText: {
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    lineHeight: theme.aligned(20),
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    width: theme.aligned(60),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  myTasksBtn: {
    paddingHorizontal: theme.space4,
    justifyContent: 'center',
    borderWidth: theme.pt,
    borderColor: theme.textColorLight900,
    borderRadius: theme.cardBorderRadius,
    height: theme.space20,
  },
  myTasksBtnSelected: {
    borderWidth: theme.pt,
    borderColor: theme.bgColorGreen100,
    paddingHorizontal: theme.space4,
    justifyContent: 'center',
    backgroundColor: theme.bgColorGreen100,
    borderRadius: theme.cardBorderRadius,
    height: theme.space20,
  },
  myTasksText: {
    color: theme.textColorLight700,
    fontSize: theme.fontSize12,
    fontWeight: '700',
  },
  myTasksTextSelected: {
    color: theme.textColor,
    fontSize: theme.fontSize12,
    fontWeight: '700',
  },
  projectSelectBlock: {
    paddingTop: theme.space8,
  },
});
