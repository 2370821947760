import {makeAutoObservable} from 'mobx';

import * as api from '../api';
import {decodeDate} from '../utils/decodeDate';
import {Root} from './root';
import {Agreement} from './agreement';
import {Thread} from './thread';

export type FoundedAgrementOrThread = {
  agreement: Agreement | undefined;
  thread: Thread | undefined;
};

export class AsanaTask {
  model: Root;
  id: string = '';
  name: string = '';
  createdAt: number = 0;
  parent: AsanaTask | null = null;
  assigneeUserId: string = '';
  projectsIds: string[] = [];
  loading: boolean = false;

  constructor(root: Root, p?: api.AsanaTask) {
    this.model = root;
    if (p) this.setFromApi(p);
    makeAutoObservable(this);
  }

  get compareName() {
    if (!this.name) return '';
    return this.name.toLowerCase();
  }

  get isAssigneeToMe() {
    const asanaUserId = this.model.user.asanaUserId;
    if (this.assigneeUserId === asanaUserId) return true;
    return this.subtasks.some((el) => el.assigneeUserId === asanaUserId);
  }

  get subtasks(): AsanaTask[] {
    if (this.parent) return [];
    return this.model.asanaTasksList.getSubtasks(this.id);
  }

  get hasProjectInfo() {
    return this.projectsIds.length || this.parent?.projectsIds.length;
  }

  get projects() {
    const projectIds = this.projectsIds.length ? this.projectsIds : this.parent?.projectsIds || [];
    const list: string[] = [];
    for (const id of projectIds) {
      const agreement = this.model.agreementsList.getFromAsanaId(id);
      if (agreement) list.push(agreement.title);
      else {
        const thread = this.model.threadsList.getFromAsanaId(id);
        if (thread) list.push(thread.title);
      }
    }
    return list;
  }

  getAgreementOrThreadByAsanaId() {
    const projectIds = this.projectsIds.length ? [...this.projectsIds] : [...(this.parent?.projectsIds || [])];
    const founded: FoundedAgrementOrThread = {agreement: undefined, thread: undefined};
    for (const id of projectIds) {
      const agreement = this.model.agreementsList.getFromAsanaId(id);
      const thread = this.model.threadsList.getFromAsanaId(id);
      if (agreement) {
        founded.agreement = agreement;
        break;
      }
      if (thread) {
        founded.thread = thread;
        break;
      }
    }
    return founded;
  }

  inProject(projectId: string) {
    if (this.parent?.projectsIds.some((el) => el === projectId)) return true;
    return this.projectsIds.some((el) => el === projectId);
  }

  setFromApi(p: api.AsanaTask) {
    this.id = p.gid;
    this.name = p.name;
    this.createdAt = decodeDate(p.created_at);
    if (p.parent) this.parent = new AsanaTask(this.model, p.parent);
    if (p.assignee?.gid) this.assigneeUserId = p.assignee.gid;
    if (p.projects && Array.isArray(p.projects)) {
      this.projectsIds = p.projects.map((el) => el.gid);
    }
    if (!this.hasProjectInfo) {
      this.setProject(p.workspace?.gid);
    }
  }

  setProject(val?: string) {
    if (!val) return;
    this.projectsIds.push(val);
  }

  setParent(parent: AsanaTask) {
    this.parent = parent;
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

  serialize(): any {
    let p: any = {
      gid: this.id,
      name: this.name,
      assignee: {
        gid: this.assigneeUserId,
      },
    };
    if (this.parent) p = {...p, parent: this.parent.serialize()};
    return p;
  }
}
