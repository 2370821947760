import {IConsole, ILogger} from './types';

export interface ILoggerConfig {
  console?: IConsole;
}

export class Logger implements ILogger {
  private console: IConsole | undefined;

  constructor(cfg?: ILoggerConfig) {
    if (!cfg) cfg = {};
    this.console = cfg.console;
  }

  log(...args: any[]): void {
    if (this.console) this.console.log(...args);
  }

  warn(...args: any[]): void {
    if (this.console) this.console.warn(...args);
  }

  info(...args: any[]): void {
    if (this.console) this.console.info(...args);
  }
  error(...args: any[]): void {
    const msg = stringifyArgs(args);
    if (this.console) this.console.warn(msg);
  }
}

function stringifyArgs(args: unknown[]): string {
  const parts = [];
  for (const arg of args) {
    //@ts-ignore TODO
    parts.push(strerr(arg));
  }
  return parts.join(' ');
}

function strerr(e: unknown): string {
  if (e === null) return 'null';
  if (e === undefined) return 'undefined';
  if (e instanceof Error) return e.toString();
  return JSON.stringify(e);
}
