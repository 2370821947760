import {makeAutoObservable} from 'mobx';

import * as api from '../api';
import {Root} from './root';
import {Thread} from './thread';

export class ThreadList {
  ids: string[] = [];
  byId: Map<string, Thread> = new Map();
  loading: boolean = false;
  model: Root;

  constructor(root: Root) {
    this.model = root;
    makeAutoObservable(this);
  }

  get empty() {
    return this.ids.length === 0;
  }

  get list(): Thread[] {
    const list: Thread[] = [];
    this.ids.map((id) => {
      const thread = this.byId.get(id);
      if (thread?.isActive) list.push(thread);
    });
    return list;
  }

  getFromAsanaId(asanaId: string) {
    return this.list.find((el) => el.asanaId === asanaId);
  }

  setFromApi(data: api.IThreadWithId[]) {
    for (const r of data) {
      this.addFromApi(r);
    }
  }

  addFromApi(p: api.IThreadWithId): Thread {
    const id = p.id;
    let ag = this.byId.get(id);
    if (ag) {
      ag.setFromApi(p);
    } else {
      ag = new Thread();
      ag.setFromApi(p);
      this.byId.set(id, ag);
      this.ids.push(id);
    }
    return ag;
  }

  remove(id: string) {
    this.ids = this.ids.filter((el) => el !== id);
    this.byId.delete(id);
  }

  clear() {
    this.ids = [];
    this.byId = new Map();
    this.setLoading(false);
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}
