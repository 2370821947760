import React from 'react';
import {NavigationContainerRef} from '@react-navigation/core';
import {configure} from 'mobx';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

import {appProvider} from './appProvider';
import Application from './application';
import {WebStorage} from './storage/webStorage';
import {setI18nConfig} from './locales/i18n';
import {isNative, SENTRY_DSN} from './constants/general';

export function main() {
  configure({useProxies: 'never'});

  if (!isNative) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  setI18nConfig();
  const fs = new WebStorage();
  const app = new Application();
  appProvider.setApplication(app);
  appProvider.application.bootstrap({
    fs,
    // @ts-ignore
    navigation: React.createRef<NavigationContainerRef>(),
    console: __DEV__ ? console : undefined,
  });
}
