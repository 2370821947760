import React, {useEffect, useState} from 'react';
import {View, ViewStyle} from 'react-native';

import theme from '../theme';

export const BlinkingDot: React.FC = () => {
  const [showDot, setShowDot] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowDot((showText) => !showText);
    }, 750);
    return () => clearInterval(interval);
  }, []);

  return showDot ? <View style={style} /> : null;
};

const style: ViewStyle = {
  width: theme.space8,
  height: theme.space8,
  borderRadius: theme.aligned(4),
  backgroundColor: theme.bgColorGreen100,
};
