import {PropsWithChildren} from 'react';
import {ScrollView, ViewStyle} from 'react-native';
import theme from '../../theme';

export const DailyBlockScrollWrapper: React.FC<PropsWithChildren<{}>> = (props) => {
  return <ScrollView contentContainerStyle={container}>{props.children}</ScrollView>;
};

const container: ViewStyle = {
  width: theme.webScreensWidth,
};
