import {makeAutoObservable} from 'mobx';
import {eachDayOfInterval, isToday, isWithinInterval} from 'date-fns';

import * as api from '../api';
import {TimeRecord} from './timeRecord';
import {dateToString} from '../utils/formatters';
import {Root} from './root';
import {TimeRecordType} from '../types';

export type TimeRecordsListSection = {
  day: Date;
  data: TimeRecord[];
  idx: number;
};

export class TimeRecordsList {
  model: Root;
  ids: string[] = [];
  byId: Map<string, TimeRecord> = new Map();
  loading: boolean = false;

  constructor(root: Root) {
    this.model = root;
    makeAutoObservable(this);
  }

  get empty() {
    return this.ids.length === 0;
  }

  get lastCreatedDate() {
    return Math.max(...this.list.map((el) => el.lastChangeDate));
  }

  get selectedIds() {
    return this.all.filter((el) => el.isSelected).map((el) => el.id);
  }

  get selectedTodayIds() {
    return this.all.filter((el) => el.isSelected && isToday(el.recordDate)).map((el) => el.id);
  }

  get selectedYesterdayIds() {
    return this.all.filter((el) => el.isSelected && !isToday(el.recordDate)).map((el) => el.id);
  }

  get lastAddedAgreementOrThread() {
    const item = this.list.find((el) => el.lastChangeDate);
    switch (item?.type) {
      case TimeRecordType.Process:
        return {id: item.threadId, type: item.type};
      case TimeRecordType.Project:
        return {id: item.agreementId, type: item.type};
    }
    return undefined;
  }

  get lastAddedThreadId(): string | undefined {
    const item = this.list.find((el) => el.lastChangeDate);
    return item?.agreementId;
  }

  get all() {
    const list: TimeRecord[] = [];
    this.ids.map((id) => {
      const record = this.byId.get(id);
      if (record?.defined && !record.fixedCost) list.push(record);
    });
    return list;
  }

  get list(): TimeRecord[] {
    const {startOfWeek, endOfWeek} = this.model.weekFilter;
    const filtered = this.all.filter((el) => isWithinInterval(el.recordDate, {start: startOfWeek, end: endOfWeek}));
    return filtered.sort((a, b) => a.recordDate - b.recordDate + b.createdTime - b.createdTime);
  }

  get sectionsByDate() {
    const {startOfWeek, endOfWeek} = this.model.weekFilter;
    const weekdays = eachDayOfInterval({start: startOfWeek, end: endOfWeek});
    const sections: TimeRecordsListSection[] = [];
    let idx = 0;
    for (const day of weekdays.values()) {
      const data = this.list.filter((el) => dateToString(el.recordDate) === dateToString(day));
      if (day <= new Date()) sections.push({day, data, idx});
      idx++;
    }
    return sections;
  }

  get totalSecondsSpent() {
    return this.list.reduce((acc, curr) => acc + curr.hoursSpent, 0);
  }

  get totalHoursSpent() {
    return this.totalSecondsSpent / 3600;
  }

  setFromApi(records: api.ITimeRecord[]) {
    for (const r of records) {
      this.addFromApi(r);
    }
  }

  addFromApi(p: api.ITimeRecord): TimeRecord {
    const id = p.id;
    let record = this.byId.get(id);
    if (record) {
      record.setFromApi(p);
    } else {
      record = new TimeRecord();
      record.setFromApi(p);
      this.byId.set(id, record);
      this.ids.push(id);
    }
    return record;
  }

  remove(id: string) {
    this.ids = this.ids.filter((el) => el !== id);
    this.byId.delete(id);
  }

  clear() {
    this.ids = [];
    this.byId = new Map();
    this.setLoading(false);
  }

  clearSelected() {
    for (const item of this.list) {
      item.setSelected(false);
    }
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}
