import {makeAutoObservable} from 'mobx';

import {AppController} from './appController';
import {hasProp} from '../utils/typeUtils';
import {TimeRecordsList} from './timeRecordsList';
import {WeekFilter} from './weekFilter';
import {Session} from './session';
import {SnacksController} from './snacksController';
import {AgreementsList} from './agreementsList';
import {TrackTimer} from './trackTimer';
import {User} from './user';
import {HolidaysList} from './holidaysList';
import NetInfoController from './netInfoController';
import {ThreadList} from './threadsList';
import {WelcomeTextsList} from './welcomeTextsList';
import {TabsController} from './tabsContoller';
import {AsanaTasksFilteredList} from './asanaTasksFilteredList';
import {AsanaTasks} from './asanaTasks';
import {DailyController} from './dailyController';
import {AsanaProjects} from './asanaProjects';

export class Root {
  installTime: number;
  storeToFileIndicator: number = 0;
  navigationIsReady: boolean = false;
  appCtrl: AppController | null = null;
  session: Session = new Session();
  timeRecordsList: TimeRecordsList = new TimeRecordsList(this);
  agreementsList: AgreementsList = new AgreementsList(this);
  threadsList: ThreadList = new ThreadList(this);
  weekFilter: WeekFilter = new WeekFilter(this);
  snacksCtrl = new SnacksController();
  timer: TrackTimer = new TrackTimer(this);
  user: User = new User();
  holidays: HolidaysList = new HolidaysList();
  welcomeTextsList = new WelcomeTextsList();
  netInfoCtrl = new NetInfoController();
  tabCtrl = new TabsController();
  asanaTasksList = new AsanaTasksFilteredList(this);
  asanaTasks = new AsanaTasks(this);
  asanaProjects: AsanaProjects = new AsanaProjects(this);
  dailyCtrl = new DailyController(this);

  constructor() {
    this.installTime = 0;
    makeAutoObservable(this);
  }

  setInstallTime() {
    this.installTime = Date.now();
  }

  bumpStoreToFileIndicator() {
    this.storeToFileIndicator = Date.now();
  }

  setNavigationReady() {
    this.navigationIsReady = true;
  }

  setAppController() {
    this.appCtrl = new AppController(this);
  }

  /**
   * Prepare data to be saved to disk
   *
   * @returns Some JS structure suitable for JSON.stringify
   */
  serialize(): any {
    return {
      installTime: this.installTime,
      session: this.session.serialize(),
      asanaTasks: this.asanaTasks.serialize(),
      storeToFileIndicator: this.storeToFileIndicator,
    };
  }

  /**
   * Load previously stored data
   *
   * @param data JS structure returned by serialize
   */
  load(data: any) {
    if (!data) {
      // First run!
      this.setInstallTime();
      return;
    }
    if (hasProp(data, 'installTime')) this.installTime = Number(data.installTime);
    if (hasProp(data, 'session')) this.session.load(data.session);
    if (hasProp(data, 'asanaTasks')) this.asanaTasks.load(data.asanaTasks);
  }

  clear() {
    this.appCtrl?.destroy();
    this.snacksCtrl.destroy();
    this.user.clear();
    this.netInfoCtrl.destroy();
  }
}
