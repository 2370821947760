import React from 'react';
import {observer} from 'mobx-react-lite';
import Calendar, {CalendarTileProperties} from 'react-calendar';

import {View, StyleSheet} from 'react-native';
import {endOfToday, isSameDay, isToday, subWeeks} from 'date-fns';

import theme from '../theme';
import {locale} from '../locales/i18n';

type Props = {
  onChange: (value: Date) => void;
  onClose: () => void;
  value: Date;
};

export const WebCalendar: React.FC<Props> = observer((props: Props) => {
  const getTileClassName = (p: CalendarTileProperties) => {
    const classNames: string[] = [];
    if (isToday(p.date)) classNames.push('react-calendar_day_today');
    if (isSameDay(p.date, props.value)) classNames.push('react-calendar_day_selected');
    return classNames;
  };

  return (
    <View style={s.container}>
      <Calendar
        onChange={props.onChange}
        locale={locale}
        maxDate={endOfToday()}
        value={props.value}
        tileClassName={getTileClassName}
        minDate={subWeeks(Date.now(), 2)}
      />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    width: '80%',
    maxWidth: theme.aligned(400),
    minWidth: theme.webScreensWidth,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: theme.aligned(280),
  },
});
