const en = {
  'common.continue': 'Continue',
  'common.back': 'Back',
  'common.of': 'of',
  'common.verification.code': 'Verification code',
  'common.with.email': 'with email',
  'common.logout': 'Logout',
  'common.this.week': 'This week',
  'common.today': 'Today',
  'common.past.week': 'Last week',
  'common.total': 'Total',
  'common.history': 'History',
  'common.time': 'Time',
  'common.hello': 'Hello',
  'common.cancel': 'Cancel',
  'common.delete': 'Delete',
  'common.save': 'Save',
  'common.time.record': 'Time record',
  'common.date': 'Date',
  'common.project': 'Project',
  'common.process': 'Process',
  'common.confirm': 'Confirm',
  'common.notes': 'Notes',
  'common.timesheet': 'Timesheet',
  'common.hi': 'Hi',
  'common.task': 'Task',
  'common.daily': 'Daily',
  'common.yesterday': 'Yesterday',
  'common.last.two.days': 'last 2 days',
  'common.last.three.days': 'last 3 days',

  'placeholder.email': 'Email',
  'placeholder.comment': 'Write something',
  'placeholder.search': 'Search',
  'placeholder.select.task': 'Select task',
  'placeholder.task.search': 'For example Work planning',

  'action.resend.code': 'Resend code',
  'action.add.time': 'Add time',
  'action.entry.edit': 'Edit Entry',
  'action.entry.delete': 'Delete Entry',
  'action.daily.delete': 'Delete Entry from Daily',
  'action.play': 'Play',
  'action.pause': 'Pause',
  'action.end.record': 'End record',
  'action.try.again': 'Retry',
  'action.login': 'Login',
  'action.resume': 'Resume',
  'action.cancel.positive': 'Yes, Сancel',
  'action.continue.negative': 'No, Сontinue',
  'action.search.more': 'Search more',
  'action.repeat.search': 'Repeat search',
  'action.drop.cache': 'Drop search cache',
  'action.open.in.asana': 'Open Asana task',
  'action.continue.tracking': 'Continue tracking',
  'action.yes.stop': 'Yes, stop',
  'action.no.cancel': 'No, cancel',
  'action.understand': 'I understand',
  'action.replace': 'Replace',
  'action.add': 'Add',
  'action.new.record': 'Add new record',
  'action.send': 'Send',

  'error.incorrect.email': 'Email is incorrect',
  'error.invalid.code': 'Code is not valid',
  'error.email.not.found': 'Email address you entered could not be found',
  'error.authorization.problem': 'Authorization problem',
  'error.session.problem': 'Session request problem',
  'error.invalid.value': 'Invalid value',
  'error.task.search.string.length': 'Enter word contains 3 symbols or more',
  'error.no.project.selected': 'You need select Project/Process first',
  'error.unable.task.select': 'Unable to select task',
  'error.warning': 'Warning!',

  'info.code.was.resend': 'Code was resend',
  'info.hours.tracked': 'Hours Tracked',
  'info.try.again': 'try again later',
  'info.login.subtitle1': 'Welcome to the Violet Time Tracker',
  'info.login.subtitle2': 'Keep track of your projects',
  'info.verification.code.notice':
    'We have sent the verification code to your email address. Code will expire in 1 hour',
  'info.timerecord.deleted': 'Record deleted',
  'info.new.time.entry': 'New Time Entry',
  'info.edit.time.entry': 'Edit Time Entry',
  'info.working.hours': 'Working hours',
  'info.oops': 'Sorry',
  'info.couldnt.connect': 'We could not connect to the Internet.',
  'info.check.connection': 'Please, check your connection and try again',
  'info.session.expired.title': 'Session expired',
  'info.session.expired': 'Please, log in again',
  'info.cancel.addition': 'Cancel Editing?',
  'info.cancel.editing': 'Отменить редактирование?',
  'info.data.not.saved': 'If canceled, the data will not be saved',
  'info.no.data.saved': 'No data saved',
  'info.record.edited': 'Record Edited',
  'info.record.added': 'Record Added',
  'info.no.results.found': 'No results found',
  'info.start.typing.task': 'Start typing the task name',
  'info.task.linked.another.project': 'The task is linked to another project',
  'info.continue.tracking.confirm': 'To continue this tracking, stop the current tracking',
  'info.continue.tracking.title': 'Continue tracking',
  'info.task.not.linked.any.project':
    "You can't choose task because it not linked to any project. Connect with your PM to fix it",
  'info.daily.report': 'New daily report',
  'info.daily.text1': 'Fill in what you did ',
  'info.daily.text2': ' and what you plan to do today',
  'info.daily.task.selector.title': 'Choose tasks for daily',
  'info.daily.report.sent': 'Daily reports successfully sent',
  'info.has.blockers': 'Has blockers?',
};

export default en;
