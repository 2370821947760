import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react';

import {Screen} from '../elements/Screen';
import theme from '../theme';
import {TimeRecordsList} from '../components/TimeRecordsList';
import {HeaderBlock} from '../elements/HeaderBlock';
import {TabType} from '../types';
import {DailyBlock} from '../components/DailyBlock/DailyBlock';
import {TrackTimerBlock} from '../components/TrackTimer';
import {appProvider} from '../appProvider';
import {Tabs} from '../components/Tabs';
import {LayoutRectangle} from 'react-native/Libraries/Types/CoreEventTypes';
import {expr} from 'mobx-utils';

export const MainScreen: React.FC = observer(() => {
  const app = appProvider.application;
  const ctrl = app.model.tabCtrl;

  const style: StyleProp<ViewStyle> = expr(() => {
    if (app.model.appCtrl && !app.model.appCtrl?.isWideWebScreen) {
      return [s.content, {marginTop: 0}];
    }
    return s.content;
  });

  const renderTabs = () => {
    switch (ctrl.selectedTab) {
      case TabType.Daily:
        return <DailyBlock />;
      case TabType.Time:
        return <TrackTimerBlock />;
      case TabType.History:
        return <TimeRecordsList />;
    }
  };

  const onLayout = (layout: LayoutRectangle) => {
    app.model.appCtrl?.setScreenWidth(layout.width);
  };

  return (
    <Screen safearea style={s.screen} onLayout={onLayout}>
      <HeaderBlock />
      <View style={style}>
        <Tabs data={ctrl.items} onSelect={(item) => ctrl.setTab(item.id)} value={ctrl?.selectedTab} />
        <View style={s.spacer} />
        {renderTabs()}
      </View>
    </Screen>
  );
});

const s = StyleSheet.create({
  screen: {
    backgroundColor: theme.bgColorWeb,
  },
  content: {
    flex: 1,
    width: theme.webScreensWidth,
    alignContent: 'center',
    margin: 'auto',
    marginTop: -theme.aligned(54),
  },
  spacer: {
    height: theme.space16,
  },
});
