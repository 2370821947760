import React from 'react';
import {Image, ImageStyle, StyleSheet, Text, TouchableOpacity, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import {formatISO} from 'date-fns';

import {appProvider} from '../appProvider';
import images from '../images';
import {Button} from '../elements/Button';
import theme from '../theme';
import {strings} from '../locales/i18n';
import OwnIcons from '../elements/OwnIcons';
import {WelcomeTextBlock} from './WelcomeTextBlock';
import {isNative} from '../constants/general';

type Props = {
  onClose?: () => void;
};

export const TrackTimerBlock: React.FC<Props> = observer((props: Props) => {
  const app = appProvider.application;
  const timer = app.model.timer;
  const onStartTimer = () => {
    app.onTimerUpdate({
      start_date: formatISO(Date.now()),
      time_spent: timer.timeSpent,
      is_active: true,
    });
  };
  const onStopTimer = () =>
    app
      .onTimerUpdate({
        start_date: formatISO(Date.now()),
        time_spent: timer.timerCtrl.currentSeconds,
        is_active: false,
      })
      .then(() => timer.stop());

  const onSaveRecord = () => {
    if (props.onClose) props.onClose();
    app.saveTimerRecord();
  };

  const timerImage = expr(() => {
    if (timer.empty) return images.empty_timer;
    return timer.isActive ? images.active_timer : images.paused_timer;
  });

  const renderPlayButton = () => {
    return (
      <Button
        variant="secondary"
        title={timer.empty ? strings('action.play') : strings('action.resume')}
        iconColor={theme.bgColorGreen100}
        iconName="play"
        disableTouchOnly={timer.loading}
        onPress={onStartTimer}
      />
    );
  };

  const renderPauseButton = () => {
    return (
      <Button
        variant="secondary"
        title={strings('action.pause')}
        iconColor={theme.textColorLight900}
        iconName="pause"
        disableTouchOnly={timer.loading}
        onPress={onStopTimer}
      />
    );
  };

  const renderButtonsBlock = () => {
    if (app.model.timer.empty) {
      return <View style={styles[theme.stylesType].singleButton}>{renderPlayButton()}</View>;
    }
    return (
      <View style={styles[theme.stylesType].row}>
        <View style={s.dualButtonBlock}>{!timer.isActive ? renderPlayButton() : renderPauseButton()}</View>
        <View style={s.spacer} />
        <View style={s.dualButtonBlock}>
          <Button
            variant="tertiary"
            iconName="stop"
            iconColor={theme.bgColorRed100}
            title={strings('action.end.record')}
            onPress={onSaveRecord}
            disabled={timer.empty}
            disableTouchOnly={timer.loading}
          />
        </View>
      </View>
    );
  };

  const renderAddTimeButton = () => {
    if (!isNative) return null;
    if (app.model.timer.isActive) return null;
    const bottomInset = app.model.appCtrl?.safeAreaInsets.bottom;
    const style = StyleSheet.flatten([s.addTimeBlock, {bottom: bottomInset ? bottomInset : theme.space32}]);
    return (
      <TouchableOpacity style={style} onPress={() => app.navigateToAddTimeRecord({date: Date.now()})}>
        <Text style={s.addTimeText}>{strings('action.add.time')}</Text>
        <View style={s.addTimeIconBlock}>
          <OwnIcons name="plus" color={theme.textColor} size={theme.fontSize18} />
        </View>
      </TouchableOpacity>
    );
  };

  const textStyle = expr(() => StyleSheet.flatten([s.timerText, !timer.empty ? s.textActive : null]));
  return (
    <View style={styles[theme.stylesType].container}>
      <WelcomeTextBlock />
      <View style={s.containerInside}>
        <View style={s.content}>
          <View style={s.timerBlock}>
            <View style={s.textBlock}>
              <Text style={s.title}>{strings('info.working.hours')}</Text>
              <Text style={textStyle}>{timer.timeSpentFormatted}</Text>
              <View style={s.heightSpacer} />
            </View>
            <Image source={timerImage} style={styles[theme.stylesType].timerImage} />
          </View>
        </View>
      </View>
      <View style={s.buttonBlock}>{renderButtonsBlock()}</View>
      {renderAddTimeButton()}
    </View>
  );
});

interface IScreenStyles {
  container: ViewStyle;
  row: ViewStyle;
  singleButton: ViewStyle;
  timerImage: ImageStyle;
}

interface IStyles {
  [x: string]: IScreenStyles;
}

const styles: IStyles = {
  web: {
    container: {
      flex: 1,
      backgroundColor: theme.bgColorWeb,
      width: theme.webScreensWidth + theme.space16 * 2,
      alignContent: 'center',
      margin: 'auto',
    },
    row: {
      flexDirection: 'row',
      flex: 1,
      width: theme.webScreensWidth,
    },
    singleButton: {
      width: theme.webScreensWidth,
    },
    timerImage: {
      width: theme.aligned(260),
      height: theme.aligned(260),
    },
  },
  native: {
    container: {
      flex: 1,
      justifyContent: 'space-between',
    },
    row: {
      flexDirection: 'row',
      flex: 1,
    },
    singleButton: {},
    timerImage: {},
  },
};

const s = StyleSheet.create({
  containerInside: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexShrink: 1,
    alignItems: 'center',
  },
  buttonBlock: {
    flex: 0.4,
    width: theme.windowWidth - theme.space16 * 2,
    marginHorizontal: theme.space16,
  },
  timerBlock: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  timerText: {
    color: theme.bgColorDark400,
    fontFamily: theme.fontFamilyMain,
    fontSize: theme.aligned(56),
    letterSpacing: theme.aligned(3),
  },
  textActive: {
    color: theme.textColor,
  },
  textBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  title: {
    fontSize: theme.fontSize20,
    color: theme.textColorSecondary,
    fontFamily: theme.fontFamilyMain,
    fontWeight: '500',
    height: theme.aligned(34),
  },
  dualButtonBlock: {
    flex: 0.5,
  },
  spacer: {
    width: theme.space8,
  },
  heightSpacer: {
    height: theme.aligned(30),
  },
  addTimeBlock: {
    position: 'absolute',
    right: theme.space16,
    bottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  addTimeText: {
    fontSize: theme.space16,
    color: theme.textColor,
    fontFamily: theme.fontFamilyMain,
    fontWeight: '500',
    paddingRight: theme.space8,
  },
  addTimeIconBlock: {
    borderRadius: theme.space24,
    width: theme.aligned(48),
    height: theme.aligned(48),
    backgroundColor: theme.bgColorPurple100,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
