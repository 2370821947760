import {IReactionDisposer, makeAutoObservable, observable, ObservableMap, reaction} from 'mobx';
import {BackHandler, Keyboard, NativeEventSubscription} from 'react-native';

import {ModalEntry} from './modalEntry';
import {IModalConfig, IModalController, IModalEntry, ModalRenderer} from '../types';

export class ModalController implements IModalController {
  private _lastId: number = 1;
  private _disposers: IReactionDisposer[] = [];
  private backPresslistener: NativeEventSubscription | null = null;

  private _entries: ObservableMap<string, ModalEntry> = observable(new Map());
  private isKeyboardVisible: boolean = false;

  get entries(): ModalEntry[] {
    return Array.from(this._entries.values());
  }

  get isVisible(): boolean {
    return this._entries.size > 0;
  }

  get lastEntry(): ModalEntry | undefined {
    if (this._entries.size === 0) return undefined;
    const entries = this.entries;
    return entries[entries.length - 1];
  }

  get canHideLastEntry(): boolean {
    if (!this.lastEntry) return false;
    return this.lastEntry.canHide;
  }

  constructor() {
    makeAutoObservable(this);
    Keyboard.addListener('keyboardDidShow', () => {
      this.setKeyboardVisibility(true);
    });
    Keyboard.addListener('keyboardDidHide', () => {
      this.setKeyboardVisibility(false);
    });
    this.createBackListenerReaction();
  }

  createBackListenerReaction() {
    this._disposers.push(
      reaction(
        () => this.isVisible,
        (isVisible) => {
          if (isVisible) {
            this.backPresslistener = BackHandler.addEventListener('hardwareBackPress', () => {
              this.hide();
              return true;
            });
          } else {
            this.backPresslistener?.remove();
          }
        },
        {fireImmediately: true},
      ),
    );
  }

  dispose() {
    Keyboard.removeAllListeners('keyboardDidShow');
    Keyboard.removeAllListeners('keyboardDidHide');
    for (const d of this._disposers) {
      d();
    }
  }

  show(render: ModalRenderer, config?: IModalConfig): IModalEntry {
    if (this.isKeyboardVisible) Keyboard.dismiss();
    for (const ent of this.entries) {
      if (!ent.multipleMode) this.hide(ent.id);
    }
    const entry = new ModalEntry(`entry${this._lastId++}`, render, config);
    this._entries.set(entry.id, entry);
    return entry;
  }

  hide(entryId?: string) {
    if (this._entries.size === 0) return;
    if (entryId) {
      const entry = this._entries.get(entryId);
      if (entry) entry.hide(this.onEntryHide);
    } else {
      for (const entry of this.entries) {
        if (entry) entry.hide(this.onEntryHide);
      }
    }
  }

  hideLastEntry() {
    if (!this.lastEntry) return;
    this.hide(this.lastEntry?.id);
  }

  private setKeyboardVisibility(visible: boolean) {
    this.isKeyboardVisible = visible;
  }

  private onEntryHide = (id: string) => {
    this._entries.delete(id);
  };
}
