// always define route names as constants
// use it late like navigate(screens.Space)

export enum AppScreens {
  MainScreen = 'MainScreen',
  LoginScreen = 'LoginScreen',
  LoadingScreen = 'LoadingScreen',
  AddOrEditTimeRecord = 'AddOrEditTimeRecord',
  DailyEditScreen = 'DailyEditScreen',
}
