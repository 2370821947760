import React, {PropsWithChildren} from 'react';
import {
  ViewStyle,
  StyleSheet,
  StyleProp,
  KeyboardAvoidingView,
  Platform,
  NativeEventSubscription,
  BackHandler,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {LayoutChangeEvent, LayoutRectangle} from 'react-native/Libraries/Types/CoreEventTypes';

type Props = {
  style?: StyleProp<ViewStyle>;
  safearea?: boolean;
  onHardwareBackPressed?: () => void; // Android only
  onLayout?: (layout: LayoutRectangle) => void;
};

export class Screen extends React.Component<PropsWithChildren<Props>> {
  backPressListener: NativeEventSubscription | null = null;

  constructor(props: Props) {
    super(props);
    if (props.onHardwareBackPressed && Platform.OS === 'android') {
      this.backPressListener = BackHandler.addEventListener('hardwareBackPress', () => {
        // @ts-ignore
        props.onHardwareBackPressed();
        return true;
      });
    }
  }

  componentWillUnmount() {
    if (this.backPressListener) this.backPressListener.remove();
  }

  private onLayout = (event: LayoutChangeEvent) => {
    if (this.props.onLayout) {
      this.props.onLayout(event.nativeEvent.layout);
    }
  };

  render() {
    const style = [styles.main, this.props.style];
    if (this.props.safearea)
      return (
        <SafeAreaView edges={['top']} style={style} onLayout={this.onLayout}>
          {this.props.children}
        </SafeAreaView>
      );
    return (
      <KeyboardAvoidingView style={style} behavior={'position'} onLayout={this.onLayout}>
        {this.props.children}
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
});
