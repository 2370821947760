import {makeHandler} from './makeHandler';
import {RequestApiType} from './request';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {ASANA_WORKSPACE_ID} from '../constants/general';

export const searchForTasks = makeHandler(
  'searchForTasks',
  (req: {projectNumbers: string[]; text: string; createdOn?: string}) => ({
    method: 'GET',
    path: `/workspaces/${ASANA_WORKSPACE_ID}/tasks/search/?${getSearchRequestParams(
      req.projectNumbers,
      req.text,
      req.createdOn,
    )}`,
    apiType: RequestApiType.Asana,
  }),
  (res: AsanaTask[]) => res,
);

export const searchForMyTasks = makeHandler(
  'searchForMyTasks',
  (req: {projectNumbers: string[]; asanaUserId: string}) => ({
    method: 'GET',
    path: `/workspaces/${ASANA_WORKSPACE_ID}/tasks/search/?${getSearchMyTasksParams(
      req.projectNumbers,
      req.asanaUserId,
    )}`,
    apiType: RequestApiType.Asana,
  }),
  (res: AsanaTask[]) => res,
);

export const getAsanaTaskById = makeHandler(
  'getAsanaTaskById',
  (id: string) => ({
    method: 'GET',
    path: `/tasks/${id}/?${getTaskRequestParams()}`,
    apiType: RequestApiType.Asana,
  }),
  (res: AsanaTask) => res,
);

const SINGLE_TASK_OPT_FIELDS =
  'created_at, name, assignee, assignee.gid, parent, parent.name, parent.created_at, parent.assignee, projects';

const SEARCH_OPT_FIELDS = `${SINGLE_TASK_OPT_FIELDS}, workspace, workspace.gid, parent.workspace, parent.workspace.gid`;

function getSearchRequestParams(projectNumbers: string[], text: string, createdOn?: string) {
  const params: Record<string, any> = {
    'projects.any': projectNumbers.join(','),
    //'assignee.any': '1202450976049095',
    text: text.toLowerCase().trim(),
    sort_by: 'created_at',
    opt_fields: SEARCH_OPT_FIELDS,
    sort_ascending: true,
  };
  if (createdOn) {
    params['created_on.after'] = createdOn;
  }
  return objectToQueryParamString(params);
}

function getSearchMyTasksParams(projectNumbers: string[], asanaUserId: string) {
  const params: Record<string, any> = {
    'projects.any': projectNumbers.join(','),
    'assignee.any': asanaUserId,
    sort_by: 'created_at',
    opt_fields: SEARCH_OPT_FIELDS,
    sort_ascending: true,
  };
  return objectToQueryParamString(params);
}

function getTaskRequestParams() {
  return objectToQueryParamString({
    opt_fields: SINGLE_TASK_OPT_FIELDS,
  });
}

export type AsanaTask = {
  gid: string;
  name: string;
  created_at?: string;
  parent?: AsanaTask;
  assignee?: {
    gid: string;
  };
  projects?: [
    {
      gid: string;
    },
  ];
  workspace?: {
    gid: string;
  };
};
