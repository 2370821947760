import {IDisposer} from 'mobx-utils';
import {reaction, runInAction, when} from 'mobx';

import {
  AddTimeRecordConfig,
  IApplicationConfig,
  ILogger,
  IMenuItem,
  IModalConfirmConfig,
  SearchAsanaTaskParams,
  TabType,
  TimeRecordType,
} from './types';
import {Storage} from './storage';
import {Logger} from './logger';
import {Api} from './api/executor';
import {Root} from './models/root';
import {AppScreens} from './constants/screens';
import {ApiError} from './api/error';
import * as api from './api';
import {LoginController} from './models/loginController';
import {newGuid} from './utils/guid';
import {strings} from './locales/i18n';
import {ModalController} from './models/modalsController';
import {
  agreementOrThreadSelectorRenderer,
  asanaTaskSelectorRenderer,
  bottomSheetMenuRenderer,
  confirmRenderer,
  dailyTaskSelectorRenderer,
  noConnectionRenderer,
  recordTimeEditRenderer,
  sessionExpiredRenderer,
  webCalendarRenderer,
} from './modalRenderers';
import {TimeRecordForm} from './models/timeRecordForm';
import {TimeRecord} from './models/timeRecord';
import {formatDuration} from './utils/formatters';
import {SearchMode} from './models/asanaTasksFilteredList';
import {AsanaTask} from './models/asanaTask';
import {isNative} from './constants/general';
import {DailyRecord} from './models/dailyRecord';
import {WeekFilter} from './models/weekFilter';
import {isMonday, subDays} from 'date-fns';

export default class Application {
  private _bootstrapped: boolean = false;
  private _storage?: Storage;
  private _logger: Logger = new Logger();
  private _model: Root = new Root();
  private _api?: Api;
  private _modal: ModalController = new ModalController();
  private navigationReadyDisposer?: IDisposer;
  private persistentDataSaveDisposer?: IDisposer;

  get api(): Api {
    if (!this._api) throw new Error('Api not configured');
    return this._api;
  }

  get logger(): ILogger {
    return this._logger;
  }

  get modal(): ModalController {
    return this._modal;
  }

  get model(): Root {
    return this._model;
  }

  get storage(): Storage {
    if (!this._storage) throw new Error('PersistentStorage not configured');
    return this._storage;
  }

  bootstrap(config: IApplicationConfig) {
    if (this._bootstrapped) throw new Error('Application.bootstrap called on already bootstrapped instance');
    this._bootstrapped = true;
    this.model.setAppController();
    this.model.appCtrl?.setNavigationRef(config.navigation);
    this._storage = new Storage(config.fs);
    this._logger = new Logger({console: config.console});
    this._api = new Api({onError: this.onApiError});
    this.bootstrapAsync();
  }

  bootstrapAsync(): Promise<void> {
    return this.initPersistentData().then(() => {
      this.createNavigationReadyReaction();
    });
  }

  private createNavigationReadyReaction() {
    this.navigationReadyDisposer = when(
      () => this.model.navigationIsReady,
      () => this.processAppStart(),
    );
  }

  private initPersistentData(): Promise<void> {
    return this.storage.load().then((data) => {
      this.model.load(data);
      if (this.persistentDataSaveDisposer) {
        this.persistentDataSaveDisposer();
        this.persistentDataSaveDisposer = undefined;
      }
      this.persistentDataSaveDisposer = reaction(
        () => this.model.serialize(),
        (dataForSave) => {
          this.storage.store(dataForSave);
        },
        {delay: 500, fireImmediately: true},
      );
    });
  }

  afterAuthorizeActions() {
    this.getTimerData();
    this.getWelcomeTexts();
    this.getAsanaUsers();
    this.getThreads();
    this.getDailyRecords();
    this.getAsanaProjects();
  }

  processAuthorization() {
    if (this.model.session.isAuthorized) {
      this.afterAuthorizeActions();
      this.navigateToMainScreen();
      return;
    }
    const ctrl = new LoginController(this.model);
    ctrl.emailField.setValue(this.model.session.email || '');
    if (this.model.session.awaitPinCodeCheck) {
      ctrl.setPinCodeStep();
    }
    this.navigateToLogin(ctrl);
  }

  processAppStart() {
    this.model.netInfoCtrl.updateListener();
    if (this.model.session.id) {
      this.getSessionById().then(() => this.processAuthorization());
    } else {
      this.navigateToLogin(new LoginController(this.model));
    }
  }

  dispose() {
    if (this.persistentDataSaveDisposer) {
      this.persistentDataSaveDisposer();
      this.persistentDataSaveDisposer = undefined;
    }
    if (this.navigationReadyDisposer) {
      this.navigationReadyDisposer();
      this.navigationReadyDisposer = undefined;
    }
  }

  get navigation() {
    return this.model.appCtrl?.navigation;
  }

  navigate(screen: AppScreens, params?: Record<string, any>) {
    if (this.navigation?.getRootState()) {
      if (params?.key) {
        this.navigation.navigate({
          name: screen,
          key: params.key,
          params: params,
        });
      } else {
        this.navigation.navigate(screen, params);
      }
    } else {
      setTimeout(() => {
        this.navigate(screen, params);
      }, 100);
    }
  }

  navigateBack(count?: number) {
    for (let i = 0; i < (count || 1); i++) {
      if (this.navigation?.canGoBack()) this.navigation?.goBack();
    }
  }

  navigateToLogin(ctrl: LoginController) {
    this.navigate(AppScreens.LoginScreen, {ctrl});
  }

  navigateToMainScreen() {
    this.getTimeRecords(this.model.weekFilter);
    this.getLastWeekTimeRecords();
    this.navigation?.reset({
      index: 0,
      routes: [{name: AppScreens.MainScreen}],
    });
  }

  navigateToAddTimeRecord(cfg: AddTimeRecordConfig) {
    const form = new TimeRecordForm(this.model);
    form.configure(cfg);
    if (form.agreementId) {
      this.getAgreement(form.agreementId);
    }
    this.navigate(AppScreens.AddOrEditTimeRecord, {form});
  }

  navigateToEditTimeRecord(record: TimeRecord) {
    const form = new TimeRecordForm(this.model, record);
    if (record.type === TimeRecordType.Project) {
      this.getAgreement(record.agreementId).then(() => form.asanaTask?.setProject(form.agreement?.asanaId));
    } else {
      this.getThread(record.threadId).then(() => form.asanaTask?.setProject(form.thread?.asanaId));
    }
    this.navigate(AppScreens.AddOrEditTimeRecord, {form});
  }

  navigateToEditDailyScreen(record: DailyRecord) {
    this.navigate(AppScreens.DailyEditScreen, {record});
  }

  createSessionRequest(ctrl: LoginController) {
    ctrl.setLoading(true);
    return this.api
      .call('createSessionRequest', ctrl.email)
      .then((res: api.SessionResponseItem[]) => {
        const item = res[0];
        if (!item) ctrl.emailField.setError(strings('error.session.problem'));
        const session = this.model.session;
        session.clear();
        session.setSessionId(item.id);
        session.setFromApi(item.fields);
        if (session.awaitPinCodeCheck) {
          ctrl.setPinCodeStep();
        } else {
          ctrl.emailField.setError(`${strings('error.session.problem')}, ${strings('info.try.again')}`);
        }
      })
      .finally(() => ctrl.setLoading(false));
  }

  setSessionToken(ctrl: LoginController) {
    ctrl.setLoading(true);
    return this.api
      .call('setSessionToken', {id: this.model.session.id, token: newGuid()})
      .then((res: api.SessionResponseItem[]) => {
        const item = res[0];
        if (!item) ctrl.pinCodeField.setError(strings('error.authorization.problem'));
        this.model.session.setFromApi(item.fields);
        if (this.model.session.valid) {
          this.model.session.setAuthorized();
          this.afterAuthorizeActions();
          ctrl.destroy();
          this.navigateToMainScreen();
        }
      })
      .finally(() => ctrl.setLoading(false));
  }

  getSessionById(): Promise<void> {
    return this.api
      .call('getSessionById', this.model.session.id)
      .then((res) => {
        return this.checkUserByEmail(res.email).then((valid) => {
          if (valid) {
            this.model.session.setFromApi(res);
          } else {
            this.checkAuthStatusAndLogout();
          }
        });
      })
      .catch(() => this.model.session.clear());
  }

  getTimeRecord(id: string): Promise<TimeRecord> {
    return this.api.call('getTimeRecord', id).then((res: api.TimeRecordsResponseItem) => {
      return this.model.timeRecordsList.addFromApi(res.fields);
    });
  }

  getLastWeekTimeRecords() {
    // it's necessary for last week daily records
    const today = Date.now();
    if (!isMonday(today)) {
      return;
    }
    this.getTimeRecords(new WeekFilter(this.model, subDays(today, 3).getTime()));
  }

  getTimeRecords(weekFilter: WeekFilter): Promise<void> {
    const list = this.model.timeRecordsList;
    list.setLoading(true);
    return this.api
      .call('getTimeRecords', {email: this.model.session.email, dateFilter: weekFilter.airTableFilter})
      .then((res: api.TimeRecordsResponseItem[]) => {
        for (const r of res) {
          list.addFromApi(r.fields);
        }
        return this.getHolidays();
      })
      .finally(() => list.setLoading(false));
  }

  getAgreements(): Promise<void> {
    const list = this.model.agreementsList;
    if (!list.pagination.offset) list.clear();
    list.setLoading(true);
    return this.api
      .call('getAgreements', {offset: list.pagination.offset, limit: list.pagination.limit})
      .then((res) =>
        runInAction(() => {
          for (const r of res.records || []) {
            list.addFromApi({...r.fields, id: r.id});
          }
          list.pagination.processOffset(res.offset);
          list.pagination.setTotal(list.ids.length);
          if (!list.pagination.lastPage) {
            list.setLoadingMore(true);
            return this.getAgreements();
          }
          return Promise.resolve();
        }),
      )
      .finally(() => list.setLoading(false));
  }

  getThreads(): Promise<void> {
    const list = this.model.threadsList;
    list.clear();
    list.setLoading(true);
    return this.api
      .call('getThreads', undefined)
      .then((res: api.ThreadResponseItem[]) => {
        for (const r of res) {
          list.addFromApi({...r.fields, id: r.id});
        }
      })
      .finally(() => list.setLoading(false));
  }

  getThread(id: string): Promise<void> {
    const list = this.model.threadsList;
    list.setLoading(true);
    return this.api
      .call('getThread', id)
      .then((res: api.ThreadResponseItem) => {
        if (!res) return;
        list.addFromApi({...res.fields, id: res.id});
      })
      .finally(() => list.setLoading(false));
  }

  getAgreement(id: string): Promise<void> {
    const list = this.model.agreementsList;
    list.setLoading(true);
    return this.api
      .call('getAgreement', id)
      .then((res: api.AgreementsResponseItem) => {
        if (!res) return;
        list.addFromApi({...res.fields, id: res.id});
      })
      .finally(() => list.setLoading(false));
  }

  getDailyRecords(): Promise<void> {
    const daily = this.model.dailyCtrl;
    daily.setLoading(true);
    return this.api
      .call('getDailyRecords', {userName: this.model.user.fullName, dateFilter: daily.airTableFilter})
      .then((res: api.DailyResponseItem[]) => {
        if (!res) return;
        daily.setFromApi(res);
      })
      .finally(() => daily.setLoading(false));
  }

  deleteTimeRecord(id: string) {
    // TODO check for daily time records usage
    const list = this.model.timeRecordsList;
    return this.callWithUserEmailCheck(() => {
      list.setLoading(true);
      return this.api
        .call('deleteTimeRecord', id)
        .then((res: api.TimeRecordDeleteResponse) => {
          if (!res.data) return;
          if (res.data.id === id && res.data.deleted) {
            list.remove(id);
            this.showSnackMessage(strings('info.timerecord.deleted'));
          }
        })
        .finally(() => list.setLoading(false));
    });
  }

  addTimeRecord(record: api.TimeRecord4Export) {
    const list = this.model.timeRecordsList;
    return this.callWithUserEmailCheck(() => {
      list.setLoading(true);
      return this.api
        .call('addTimeRecord', record)
        .then((res: api.TimeRecordsResponseItem[]) => {
          if (!Array.isArray(res) || !res[0]) return;
          list.addFromApi(res[0].fields);
          return res[0].id;
        })
        .finally(() => list.setLoading(false));
    });
  }

  addDailyRecord(record: api.DailyRecord4Export) {
    const ctrl = this.model.dailyCtrl;
    return this.callWithUserEmailCheck(() => {
      ctrl.setLoading(true);
      return this.api
        .call('addDailyRecord', record)
        .then((res: api.DailyResponseItem[]) => {
          if (!res || !Array.isArray(res)) return;
          ctrl.setFromApi(res);
        })
        .finally(() => ctrl.setLoading(false));
    });
  }

  updateDailyRecord(id: string, data: api.DailyRecord4Export) {
    const ctrl = this.model.dailyCtrl;
    return this.callWithUserEmailCheck(() => {
      ctrl.setLoading(true);
      return this.api
        .call('updateDailyRecord', {id, data})
        .then((res: api.DailyResponseItem[]) => {
          if (!res || !Array.isArray(res)) return;
          ctrl.setFromApi(res);
        })
        .finally(() => ctrl.setLoading(false));
    });
  }

  deleteTimeRecordFromDaily(record: DailyRecord, timeRecordId: string) {
    const record_ids = record.record4export.record_ids.filter((el) => el !== timeRecordId);
    return this.updateDailyRecord(record.id, {
      ...record.record4export,
      record_ids,
      daily_text: record.getDailyText(record_ids),
    }).then(() => {
      record.deleteTimeRecord(timeRecordId);
    });
  }

  getTimerData() {
    if (!this.model.session.authorized) return;
    const timer = this.model.timer;
    timer.setLoading(true);
    return this.api
      .call('getTimerByEmail', this.model.session.email)
      .then((res: api.GetTimerResponseItem) => {
        if (!res?.id) return;
        if (res.fields.email !== this.model.session.email) return;
        timer.setFromApi(res.id, res.fields);
        this.processTimerCurrRecord();
      })
      .finally(() => this.model.timer.setLoading(false));
  }

  processTimerCurrRecord() {
    const timer = this.model.timer;
    if (!timer.needRequestTimeRecord) return;
    this.model.welcomeTextsList.setLoading(true);
    this.getTimeRecord(timer.recordId)
      .then(() => {
        if (timer?.timeRecord?.agreementId) {
          return this.getAgreement(timer?.timeRecord?.agreementId);
        }
        return Promise.resolve();
      })
      .finally(() => this.model.welcomeTextsList.setLoading(false));
  }

  updateTimeRecord(id: string, record: api.TimeRecord4Export | api.TimeRecord4ExportShort) {
    const list = this.model.timeRecordsList;
    return this.callWithUserEmailCheck(() => {
      list.setLoading(true);
      return this.api
        .call('updateTimeRecord', {id, data: record})
        .then((res: api.TimeRecordsResponseItem[]) => {
          if (!Array.isArray(res) || !res[0]) return;
          list.addFromApi(res[0].fields);
          return res[0].id;
        })
        .finally(() => list.setLoading(false));
    });
  }

  callWithUserEmailCheck(call: () => Promise<any>) {
    return this.checkUserByEmail(this.model.session.email)
      .catch(() => {})
      .then((verified) => (verified ? call() : Promise.resolve()));
  }

  checkUserByEmail(email: string): Promise<boolean> {
    return this.api.call('checkUserByEmail', email).then((res?: api.CheckUserResponseItem) => {
      if (!res) {
        this.checkAuthStatusAndLogout();
        return Promise.resolve(false);
      }
      this.model.session.setUserId(res?.id);
      this.model.user.setFromApi(res);
      return Boolean(res?.id) && res?.fields.email === email;
    });
  }

  saveTimerRecord() {
    const timer = this.model.timer;
    const form = new TimeRecordForm(this.model, timer.timeRecord?.clone());
    if (timer.timeRecord?.agreementId) {
      this.getAgreement(timer.timeRecord.agreementId);
    }
    form.setDate(Date.now());
    form.setTime(formatDuration(timer.timerCtrl.currentSeconds, true));
    this.onTimerUpdate({start_date: null, time_spent: 0, is_active: false, reports_id: []});
    this.navigate(AppScreens.AddOrEditTimeRecord, {form});
  }

  onTimerUpdate(data: api.Timer4Export) {
    const timer = this.model.timer;
    const call = timer.id
      ? () => this.api.call('updateTimerRecord', {id: timer.id, data})
      : () => this.api.call('addTimerRecord', {...data, email: this.model.session.email});
    return this.callWithUserEmailCheck(() => {
      timer.setLoading(true);
      return call()
        .then((res?: api.GetTimerResponseItem) => {
          if (!res?.id) return;
          this.model.timer.setFromApi(res.id, res.fields);
        })
        .finally(() => timer.setLoading(false));
    });
  }

  continueTracking(record: TimeRecord) {
    this.modal.hideLastEntry();
    this.onTimerUpdate(record.export4ContinueTracking).then(() => {
      if (!isNative) this.model.tabCtrl.setTab(TabType.Time);
    });
    this.model.tabCtrl.scrollToEnd();
  }

  getAbsentDays() {
    this.model.user.setLoading(true);
    return this.api
      .call('getAbsentDays', this.model.user.email)
      .then((res?: api.AbsentDaysResponseItem[]) => {
        this.model.user.setAbsentDaysFromApi(res);
      })
      .finally(() => this.model.user.setLoading(false));
  }

  getHolidays() {
    this.model.user.setLoading(true);
    return this.api
      .call('getHolidays', undefined)
      .then((res?: api.HolidaysResponseItem[]) => {
        this.model.holidays.setFromApi(res);
        return this.getAbsentDays();
      })
      .finally(() => this.model.user.setLoading(false));
  }

  getWelcomeTexts() {
    this.model.welcomeTextsList.setLoading(true);
    return this.api
      .call('getWelcomeTexts', undefined)
      .then((res?: api.WelcomeTextsResponseItem[]) => {
        this.model.welcomeTextsList.setFromApi(res);
      })
      .finally(() => this.model.welcomeTextsList.setLoading(false));
  }

  getAsanaUsers() {
    return this.api.call('getAsanaUsers', undefined).then((res?: api.AsanaUser[]) => {
      this.model.user.processAsanaUsers(res);
    });
  }

  searchMyAsanaTasks(projectId: string) {
    const tasksList = this.model.asanaTasksList;
    tasksList.setLoading(true);
    return this.api
      .call('searchForMyTasks', {
        projectNumbers: this.model.agreementsList.getProjectsNumbers4Search(projectId),
        asanaUserId: this.model.user.asanaUserId,
      })
      .then((res: api.AsanaTask[]) => {
        runInAction(() => {
          tasksList.setFromApi(res);
          this.model.asanaTasks.setFromApi(projectId, res);
          this.model.bumpStoreToFileIndicator();
          if (!tasksList.showOnlyMyTasks) {
            tasksList.setSearchMode(SearchMode.InCachedTasks);
          } else if (!tasksList.hasCachedAssigneeTasks) {
            tasksList.setSearchMode(SearchMode.FromAsana);
          }
        });
        return res;
      })
      .finally(() => this.model.asanaTasksList.setLoading(false));
  }

  searchByAsanaTask(params: SearchAsanaTaskParams) {
    this.model.asanaTasksList.setLoading(true);
    const {projectId, text, createdOn} = params;
    return this.api
      .call('searchForTasks', {
        projectNumbers: this.model.agreementsList.getProjectsNumbers4Search(projectId),
        text,
        createdOn,
      })
      .then((res: api.AsanaTask[]) => {
        runInAction(() => {
          this.model.asanaTasksList.setFromApi(res, params.isLoadMore);
          this.model.asanaTasks.setFromApi(projectId, res);
          this.model.bumpStoreToFileIndicator();
        });
        return res;
      })
      .finally(() => this.model.asanaTasksList.setLoading(false));
  }

  getAsanaTaskById(projectId: string, taskId: string) {
    const tasksModel = this.model.asanaTasksList;
    tasksModel.setLoading(true);
    return this.api
      .call('getAsanaTaskById', taskId)
      .then((res: api.AsanaTask) =>
        runInAction(() => {
          const task = new AsanaTask(this.model, res);
          task.setLoading(true);
          this.processAsanaTaskProjectInfo(projectId, task).finally(() => {
            task.setLoading(false);
          });
          return task;
        }),
      )
      .finally(() => this.model.asanaTasksList.setLoading(false));
  }

  getAsanaProjects(): Promise<void> {
    const list = this.model.asanaProjects;
    list.setLoading(true);
    return this.api
      .call('getAsanaProjects', undefined)
      .then((res) => list.setFromApi(res))
      .finally(() => list.setLoading(false));
  }

  getAgreementByAsanaGid(projectIds: string[]) {
    return this.api.call('getAgreementByAsanaGid', projectIds).then((res: api.AgreementsResponseItem[]) => {
      const list = this.model.agreementsList;
      for (const r of res || []) {
        list.addFromApi({...r.fields, id: r.id});
      }
    });
  }

  processAsanaTaskProjectInfo(projectId: string, task: AsanaTask) {
    if (task.hasProjectInfo) {
      return this.getAgreementByAsanaGid(task.projectsIds);
    }

    const tasksModel = this.model.asanaTasksList;
    if (task.parent && !tasksModel.list.byId.get(task.parent.id)) {
      return this.getAsanaTaskById(projectId, task.parent.id).then((parent) => {
        task.setParent(parent);
      });
    }
    return Promise.resolve();
  }

  showConfirmModal(config: IModalConfirmConfig) {
    this.modal.show(confirmRenderer(this.modal, config), {type: 'dialog'});
  }

  showTimeEditModal(record: TimeRecord) {
    this.modal.show(recordTimeEditRenderer(this.modal, record));
  }
  showNoConnectionModal() {
    const m = this.modal.show(noConnectionRenderer(), {type: 'dialog'});
    this.model.netInfoCtrl.setOnCloseModal(() => this.modal.hide(m.id));
  }

  checkAuthStatusAndLogout() {
    if (!this.model.session.isAuthorized) return;
    this.showSessionExpiredModal();
    this.logout();
  }

  showSessionExpiredModal() {
    this.modal.show(sessionExpiredRenderer(), {type: 'dialog'});
  }

  showBottomSheetMenu(menuItems: IMenuItem[]) {
    this.modal.show(bottomSheetMenuRenderer(this.modal, menuItems));
  }

  showAgreementOrThreadSelector = (form: TimeRecordForm) => {
    this.getAgreements();
    this.getThreads();
    this.modal.show(agreementOrThreadSelectorRenderer(this.modal, form));
  };

  showAsanaTaskSelector = (form: TimeRecordForm) => {
    this.modal.show(asanaTaskSelectorRenderer(this.modal, form), {multipleMode: true});
    const list = this.model.asanaTasksList;
    list.clearSearch();
    list.setSelected(form.asanaTask);
    list.setSearchMode(list.tasksCached.length ? SearchMode.InCachedTasks : SearchMode.FromAsana);
  };

  showDailyTaskSelector = (record: DailyRecord) => {
    for (const id of record.timeRecordIds) {
      const timeRec = this.model.timeRecordsList.byId.get(id);
      timeRec?.setSelected(true);
    }
    if (isNative) {
      this.modal.show(dailyTaskSelectorRenderer(this.modal, record));
    } else {
      this.navigateToEditDailyScreen(record);
    }
  };

  showMenuModal(menuItems: IMenuItem[]) {
    this.showBottomSheetMenu(menuItems);
  }

  showSnackMessage(message: string) {
    this.model.snacksCtrl.show(message);
  }

  showWebCalendar(form: TimeRecordForm) {
    if (isNative) return;
    this.modal.show(webCalendarRenderer(this.modal, form));
  }

  logout() {
    this.model.session.clear();
    this.model.tabCtrl.reset();
    this.model.timeRecordsList.clear();
    this.model.weekFilter.reset();
    this.navigateToLogin(new LoginController(this.model));
  }

  private onApiError = (e: ApiError, skip?: true) => {
    // TODO
    this.logger.error(e.message);
    if (!skip) throw e;
  };
}
