import {makeAutoObservable, reaction} from 'mobx';
import {IDisposer} from 'mobx-utils';
import {addDays} from 'date-fns';

import * as api from '../api';
import {AsanaTask} from './asanaTask';
import {ProxyField, ProxyFieldValidator} from './formField';
import {debounce} from '../utils/debounce';
import {appProvider} from '../appProvider';
import {strings} from '../locales/i18n';
import {dateToShortFormat} from '../utils/formatters';
import {Root} from './root';
import {AsanaTasksList} from './asanaTasksList';

const MIN_SEARCH_STRING_LENGTH = 3;
const ASANA_TASK_GID_LENGTH = 16;

export const enum SearchMode {
  FromAsana = 'FromAsana',
  InCachedTasks = 'InCachedTasks',
}

export class AsanaTasksFilteredList {
  model: Root;
  list: AsanaTasksList;
  searchStringField: ProxyField;
  searchString: string = '';
  searchMode: SearchMode = SearchMode.FromAsana;
  disposer: IDisposer | null = null;
  needLoadMore: boolean = true;
  selected: AsanaTask | null = null;
  loading: boolean = false;
  projectId: string = '';
  showOnlyMyTasks: boolean = false; // TODO thinking about separate filters model

  constructor(root: Root) {
    makeAutoObservable(this);
    this.model = root;
    this.list = new AsanaTasksList(root);
    this.searchStringField = new ProxyField({
      getter: () => this.searchString,
      setter: (val) => this.setSearchString(val as string),
      validator: (val) => this.validateSearch(val),
    });
    this.disposer = reaction(
      () => this.searchString,
      (val) => {
        const tasksIds = parseAsanaTaskIds(val);
        if (tasksIds.length) {
          this.getTaskById(tasksIds[tasksIds.length - 1]);
          return;
        }
        if (val && !this.isInCachedTasksMode && !this.showOnlyMyTasks) {
          this.setLoading(true);
          this.searchTasksDebounced(this);
        } else if (this.hasCachedAssigneeTasks) {
          this.setSearchMode(SearchMode.InCachedTasks);
          this.searchStringField.cleanAllErrors();
        }
      },
    );
  }

  get cachedTaskList() {
    return this.model.asanaTasks.byProjectId.get(this.projectId);
  }

  get isInCachedTasksMode() {
    return this.searchMode === SearchMode.InCachedTasks;
  }

  get empty() {
    if (this.selected) return false;
    if (this.isInCachedTasksMode && this.hasCachedTasks) return false;
    return !this.loading && this.searchStringField.isValid && this.tasks.length === 0;
  }

  get compareSearchString() {
    if (!this.searchString) return '';
    return this.searchString.toLowerCase().trim();
  }

  get tasks() {
    // TODO refactor
    if (this.showOnlyMyTasks) {
      if (this.hasCachedAssigneeTasks) {
        return this.tasksCachedFiltered;
      } else {
        return this.tasksSorted;
      }
    }
    if (this.searchMode === SearchMode.FromAsana) {
      return this.tasksSorted;
    }
    return this.tasksCachedFiltered;
  }

  get tasksFiltered(): AsanaTask[] {
    return this.list.tasks.filter(this.filterTasks);
  }

  get hasCachedTasks(): boolean {
    return this.tasksCachedFiltered.length > 0;
  }

  get hasCachedAssigneeTasks(): boolean {
    return this.tasksCachedAssignee.length > 0;
  }

  get tasksCached(): AsanaTask[] {
    if (!this.cachedTaskList) return [];
    return this.cachedTaskList.tasks;
  }

  get tasksCachedAssignee(): AsanaTask[] {
    return this.tasksCached.filter((el) => el.isAssigneeToMe);
  }

  get tasksCachedFiltered(): AsanaTask[] {
    if (this.searchStringField.isValid) {
      const filtered = this.tasksCached.filter(this.filterCachedTasks);
      return [...filtered].sort(sortByAssignee);
    }
    return this.tasksCachedAssignee.filter(this.filterCachedTasks);
  }

  get tasksSorted(): AsanaTask[] {
    const tasks: AsanaTask[] = [];
    for (const task of this.tasksFiltered) {
      if (!task.subtasks.length) {
        tasks.push(task);
      } else {
        tasks.push(...task.subtasks);
      }
    }
    return [...tasks].sort(sortByAssignee);
  }

  get maxCreatedAt() {
    const list = this.list.tasks.filter((el) => !this.list.parentIds.has(el.id));
    return Math.max(0, ...list.map((el) => el.createdAt));
  }

  searchTasks = () => {
    this.setLoading(false);
    this.setNeedLoadMore(true);
    if (!this.searchString) {
      this.list.clear();
      this.searchStringField.markAsClean();
      return;
    }
    this.searchStringField.markAsDirty();
    if (!this.searchStringField.isValid) return;
    this.makeSearch();
  };

  searchMyTasks = () => {
    this.list.clear();
    this.setLoading(false);
    this.setNeedLoadMore(true);
    this.searchStringField.markAsClean();
    appProvider.application.searchMyAsanaTasks(this.projectId);
  };

  makeSearch = (isLoadMore?: boolean): Promise<void> => {
    if (!this.needLoadMore) return Promise.resolve();
    return appProvider.application
      .searchByAsanaTask({
        projectId: this.projectId,
        text: this.searchString,
        createdOn: dateToShortFormat(addDays(this.maxCreatedAt, 1)),
        isLoadMore,
      })
      .then(this.loadMore);
  };

  getTaskById = (id: string) => {
    appProvider.application.getAsanaTaskById(this.projectId, id).then((task) => {
      this.setSearchMode(SearchMode.FromAsana);
      this.setSelected(task);
      this.searchStringField.setValue('');
      this.searchStringField.markAsClean();
    });
  };

  loadMore = (res: api.AsanaTask[]) => {
    if (!res || res.length === 0) {
      this.setNeedLoadMore(false);
    }
    return this.makeSearch(true);
  };

  searchTasksDebounced = debounce(this.searchTasks, 500);

  reloadSearchResults = () => {
    if (this.showOnlyMyTasks) {
      this.searchMyTasks();
      return;
    }
    if (!this.searchStringField.isValid) return;
    this.list.clear();
    this.setSearchMode(SearchMode.FromAsana);
    this.searchTasks();
  };

  getSubtasks(parentId: string) {
    const filtered = this.list.tasks.filter((el) => this.filterSubTasks(el, parentId));
    return [...filtered].sort(sortByAssignee);
  }

  setSearchString(val: string) {
    if (val && val.length === 1) {
      this.searchString = val.trim();
      return;
    }
    this.searchString = val;
  }

  setFromApi(data: api.AsanaTask[], isLoadMore?: boolean) {
    if (!isLoadMore) this.list.clear();
    this.list.setFromApi(data);
  }

  setProjectId(val?: string) {
    if (!val) return;
    if (this.projectId) {
      this.list.clear();
    }
    this.projectId = val;
  }

  clearSearch() {
    this.searchStringField.setValue('');
    this.searchStringField.markAsClean();
    this.list.clear();
    this.setShowOnlyMyTasks(false);
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

  setNeedLoadMore(val: boolean) {
    this.needLoadMore = val;
  }

  setSelected(task: AsanaTask | null) {
    this.selected = task;
  }

  setSearchMode(mode: SearchMode) {
    this.searchMode = mode;
  }

  setShowOnlyMyTasks(val: boolean) {
    if (val) {
      this.searchMyTasks();
    }
    this.showOnlyMyTasks = val;
  }

  toggleOnlyMyTasksMode = () => {
    this.setShowOnlyMyTasks(!this.showOnlyMyTasks);
  };

  destroy() {
    if (this.disposer) this.disposer();
  }

  filterCachedTasks = (task: AsanaTask) => {
    if (this.showOnlyMyTasks && !task.isAssigneeToMe) return false;
    if (!this.compareSearchString) return true;
    if (task.parent && task.parent.compareName.indexOf(this.compareSearchString) > -1) return true;
    return task.compareName.indexOf(this.compareSearchString) > -1;
  };

  filterTasks = (task: AsanaTask) => {
    if (this.showOnlyMyTasks && !task.isAssigneeToMe) return false;
    if (task.parent || this.selected?.id === task.id) return false;
    const isRootTask = this.list.parentIds.has(task.id);
    if (!isRootTask && task.compareName.indexOf(this.compareSearchString) > -1) return true;
    return task.subtasks.some((el) => el.compareName.indexOf(this.compareSearchString) > -1);
  };

  filterSubTasks = (task: AsanaTask, parentId: string) => {
    if (task.parent?.id !== parentId || task.id === this.selected?.id) return false;
    return task.compareName.indexOf(this.compareSearchString) > -1;
  };

  validateSearch: ProxyFieldValidator = (val) => {
    if (this.showOnlyMyTasks) return undefined;
    const value = val as string;
    if (!value || value.length < MIN_SEARCH_STRING_LENGTH) return strings('error.task.search.string.length');
  };
}

function sortByAssignee(a: AsanaTask, b: AsanaTask) {
  return Number(b.isAssigneeToMe) - Number(a.isAssigneeToMe);
}

function parseAsanaTaskIds(str: string) {
  const re = /[0-9]+/g;
  const parsed = flatten((str.match(re) || []).map((e) => RegExp(re.source, re.flags).exec(e)));
  return parsed.filter((el) => el && el.length === ASANA_TASK_GID_LENGTH) as string[];
}

function flatten(array: any[]) {
  let flattend: any[] = [];
  (function flat(arr) {
    arr.forEach(function (el: any) {
      if (Array.isArray(el)) flat(el);
      else flattend.push(el);
    });
  })(array);
  return flattend;
}
