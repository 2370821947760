import {makeAutoObservable} from 'mobx';

import * as api from '../api';
import {dateToShortFormat} from '../utils/formatters';

export const DEFAULT_WEEK_HOURS = 40;
const WORK_DAYS_IN_WEEK = 5;

export class User {
  id: string = '';
  asanaUserId: string = '';
  email: string = '';
  firstName: string = '';
  fullName: string = '';
  velocityInWeeks: number = 0;
  weekHours: number = DEFAULT_WEEK_HOURS;
  absentDays: Set<string> = new Set();
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get availableWorkHours() {
    return this.weekHours || this.velocityInWeeks || DEFAULT_WEEK_HOURS;
  }

  get userAvatarLetter() {
    if (this.firstName.length) return this.firstName.substring(0, 1);
    return '';
  }

  checkAbsentDay(day: Date | number) {
    return this.absentDays.has(dateToShortFormat(day));
  }

  get hoursPerDay() {
    return Math.round((this.availableWorkHours / WORK_DAYS_IN_WEEK) * 100) / 100;
  }

  setFromApi(p?: api.CheckUserResponseItem) {
    if (!p) return;
    this.id = p.id;
    this.email = p.fields.email;
    this.velocityInWeeks = p.fields.velocity_in_weeks;
    this.weekHours = p.fields.week_hours || 0;
    this.firstName = p.fields.first_name || '';
    this.fullName = p.fields.name || '';
  }

  setAbsentDaysFromApi(recs?: api.AbsentDaysResponseItem[]) {
    if (!recs || !Array.isArray(recs)) return;
    for (const r of recs) {
      if (r.fields?.date) this.absentDays.add(r.fields.date);
    }
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

  processAsanaUsers(users?: api.AsanaUser[]) {
    if (!Array.isArray(users)) return;
    for (const user of users) {
      if (this.email === user.email) {
        this.setAsanaUserId(user.gid);
      }
    }
  }

  setAsanaUserId(id: string) {
    this.asanaUserId = id;
  }

  clear() {
    this.id = '';
    this.email = '';
    this.velocityInWeeks = 0;
    this.weekHours = DEFAULT_WEEK_HOURS;
    this.absentDays = new Set();
    this.loading = false;
  }
}
