import React, {ReactText, useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import theme from '../theme';
import {ITabItem} from '../types';
import {TabButton} from './TabButton';
import {isNative} from '../constants/general';

interface Props {
  value?: ReactText | null;
  data: ITabItem[];
  onSelect: (id: ITabItem) => void;
}

export const Tabs: React.FC<Props> = observer((props: Props) => {
  const onPress = useCallback(
    (item: ITabItem) => {
      props.onSelect(item);
    },
    [props],
  );

  const renderButtons = () => {
    return props.data.map((item, idx) => {
      const isActive = item.id === props.value;
      const style = StyleSheet.flatten([s.button, props.data.length < idx ? s.marginRight8 : null]);
      return <TabButton item={item} isActive={isActive} style={style} onPress={onPress} key={item.id} />;
    });
  };

  return <View style={[s.container, !isNative ? s.web : null]}>{renderButtons()}</View>;
});

const s = StyleSheet.create({
  container: {
    marginHorizontal: theme.space16,
    padding: theme.aligned(4),
    borderRadius: theme.cardBorderRadius,
    backgroundColor: theme.bgColorDark500,
    flexDirection: 'row',
  },
  web: {
    width: theme.webScreensWidth,
    marginHorizontal: 0,
  },
  button: {
    flex: 1,
  },
  marginRight8: {
    marginRight: theme.space8,
  },
});
