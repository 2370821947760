import {Dimensions, Insets, PixelRatio} from 'react-native';
import {isNative} from './constants/general';

const mockupWidth = 375;
//const mockupHeight = 778;
const windowSize = Dimensions.get('window');

const WEB_PT = 1;

function caclThemePoint() {
  if (!isNative) {
    return WEB_PT;
  }
  //if (Platform.OS === 'ios'
  return windowSize.width / mockupWidth;
  // TODO thinking about optimal formula
  //return (windowSize.height / mockupHeight + windowSize.width / mockupWidth) * 0.5;
}

const PT = caclThemePoint();

const slopD = PT * 12;
class Theme {
  /**
   * Point size in design document
   */
  pt: number = PT;

  /**
   * Window width
   */
  windowWidth: number = windowSize.width;

  /**
   * Window height
   */
  windowHeight: number = windowSize.height;

  /**
   * Platform styleType
   */
  stylesType = isNative ? 'native' : 'web';

  /**
   * Main background color (dark grey)
   */
  bgColor = '#1C2027';

  /**
   * Main background color (dark grey)
   */
  bgColorWeb = '#141414';

  /**
   * Main font color
   */
  textColor = '#E5E6EB';

  /**
   * Primary font color
   */
  textColorPrimary = '#FAFAFA';

  /**
   * Font color Light 300
   */
  textColorLight300 = '#868A92';

  /**
   * Font color Light 700 (white)
   */
  textColorLight700 = '#5C6176';

  /**
   * Font color Light 900
   */
  textColorLight900 = '#979CAE';

  /**
   * Font color Secondary
   */
  textColorSecondary = '#BEC1CD';

  /**
   * Blue Dark text color
   */
  blueDarkColor = '#0A84FF';

  /**
   * Grey modal background color
   */
  modalBkgColor = '#5C6176';

  /**
   * background color light 300
   */
  bgColorLight300 = '#E5E6EB';

  /**
   * background color dark 100
   */

  bgColorDark100 = '#1F2128';

  /**
   * background color dark 200
   */
  bgColorDark200 = '#292C35';

  /**
   * background color dark 300 Web
   */
  bgColorDark300Web = '#2D2D2D';

  /**
   * background color dark 200
   */
  bgColorDark200Web = '#252525';

  /**
   * background color dark 400
   */
  bgColorDark400 = '#3D414F';

  /**
   * background color dark 300
   */
  bgColorDark300 = '#333642';

  /**
   * background color dark 500
   */
  bgColorDark500 = '#474C5C';

  /**
   * background color puprle 100
   */
  bgColorPurple100 = '#9288F8';

  /**
   * background color puprle 100
   */
  bgColorPrimary = '#695FCF';

  /**
   * background color red 100
   */
  bgColorRed100 = '#FD4E37';

  /**
   * background color red Web
   */
  bgColorRedWeb = '#F43F5E';

  /**
   * background color green 100
   */
  bgColorGreen100 = '#54BE6B';

  /**
   * background color dark 600
   */
  bgColorDark600 = '#515769';

  /**
   * background color dark 700
   */
  bgColorDark700 = '#5C6176';

  lightGreyColor = 'rgba(217, 217, 217, 0.3)';

  /**
   * asana logo color
   */

  salmonColor = '#F06A6A';

  /**
   *  Space sizes
   */
  space4 = this.aligned(4);
  space8 = this.aligned(8);
  space10 = this.aligned(10);
  space12 = this.aligned(12);
  space14 = this.aligned(14);
  space16 = this.aligned(16);
  space18 = this.aligned(18);
  space20 = this.aligned(20);
  space24 = this.aligned(24);
  space32 = this.aligned(32);
  space40 = this.aligned(40);

  /**
   *  Font sizes
   */
  fontSize10 = this.pt * 10;
  fontSize12 = this.pt * 12;
  fontSize13 = this.pt * 13;
  fontSize14 = this.pt * 14;
  fontSize16 = this.pt * 16;
  fontSize18 = this.pt * 18;
  fontSize20 = this.pt * 20;
  fontSize24 = this.pt * 24;
  fontSize32 = this.pt * 32;

  /**
   *  Font family
   */
  fontFamilyMain = !isNative ? 'Raleway' : 'Roboto-Regular';

  /**
   *  Font family Medium
   */
  fontFamilyMedium = !isNative ? 'Raleway' : 'Roboto-Medium';

  /**
   *  Font regular letter spacing
   */
  letterSpacing = 0.5;

  /**
   * Card Border radius
   */
  cardBorderRadius = this.aligned(isNative ? 8 : 4);

  /**
   * Modal Border radius
   */
  modalBorderRadius = this.aligned(12);

  /**
   * Web screens width
   */
  webScreensWidth = this.aligned(323);

  /**
   *  Function for more simpler convert from size in points to size in pixels
   */
  aligned(pt: number): number {
    return PixelRatio.roundToNearestPixel(this.pt * pt);
  }

  /**
   * Hitslop for small toouchable elements (icons, etc)
   */
  smallElementsHitSlop: Insets = {top: slopD, left: slopD, bottom: slopD, right: slopD};
}

export function withOpacity(colorInRgb: string, opacity: number) {
  const res = colorInRgb.match(/\((.*)\)/)?.pop();
  if (!res) return colorInRgb;
  return `rgba(${res},${opacity})`;
}

const theme = new Theme();
export default theme;
