import 'react-native-gesture-handler';
import React, {useEffect} from 'react';
import {Platform, UIManager, NativeModules, ViewStyle} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

import Routes from './Routes';
import {appProvider} from './appProvider';
import theme from './theme';
import {SafeAreaInsetsProvider} from './elements/SafeAreaInsetsProvider';
import {ModalHost} from './components/ModalsHost';
import {SnacksProvider} from './components/SnacksProvider';
import {isNative} from './constants/general';

const App: React.FC = observer(() => {
  const {model} = appProvider.application;

  if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  useEffect(() => {
    if (Platform.OS === 'android') {
      NativeModules.SplashScreenModule.hide();
    }
    return () => {
      model.appCtrl?.destroy();
    };
  }, [model.appCtrl]);

  if (!model.appCtrl) return null;

  const safeAreaStyle = {backgroundColor: theme.bgColor};
  const safeAreaStyleWeb = {backgroundColor: theme.bgColorWeb, height: '100vh'};

  return (
    <SafeAreaProvider style={isNative ? safeAreaStyle : safeAreaStyleWeb}>
      <GestureHandlerRootView style={rootStyle}>
        <NavigationContainer
          ref={appProvider.application.model.appCtrl?.navigationRef}
          onReady={() => {
            // After create new instance of Activity need to start app (on android)
            if (model.navigationIsReady) {
              appProvider.application.processAppStart();
            } else {
              model.setNavigationReady();
            }
          }}>
          <Routes />
        </NavigationContainer>
        <ModalHost ctrl={appProvider.application.modal} />
        <SnacksProvider />
        <SafeAreaInsetsProvider onSetInsets={model.appCtrl.setInsets} />
      </GestureHandlerRootView>
    </SafeAreaProvider>
  );
});

const rootStyle: ViewStyle = {flex: 1, overflow: 'hidden'};

export default App;
