import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Text, Pressable} from 'react-native';

import {IconButton} from '../elements/IconButton';
import theme from '../theme';
import {strings} from '../locales/i18n';
import {appProvider} from '../appProvider';
import {subWeeks} from 'date-fns';
import {expr} from 'mobx-utils';

type Props = {
  date: number;
  onPress?: () => void;
};

export const AddTimeBlock: React.FC<Props> = observer((props) => {
  const disabled = expr(() => {
    const minimumDate = subWeeks(new Date(), 2).getTime();
    return props.date < minimumDate;
  });

  const onPress = () => {
    if (props.onPress) {
      props.onPress();
      return;
    }
    appProvider.application.navigateToAddTimeRecord({date: props.date});
  };
  return (
    <Pressable style={s.row} onPress={onPress} disabled={disabled}>
      <IconButton icon="plus" isCircular onPress={onPress} disabled={disabled} />
      <Text style={s.text}>{strings('action.add.time')}</Text>
    </Pressable>
  );
});

const s = StyleSheet.create({
  row: {
    paddingRight: theme.space16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottomLeftRadius: theme.cardBorderRadius,
    borderBottomRightRadius: theme.cardBorderRadius,
    marginBottom: theme.space16,
    backgroundColor: theme.bgColorDark200,
    paddingVertical: theme.space16,
  },
  text: {
    paddingLeft: theme.space8,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
  },
});
