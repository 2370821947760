import React from 'react';
import {createNativeStackNavigator, NativeStackNavigationProp} from '@react-navigation/native-stack';
import {RouteProp} from '@react-navigation/native';
import {ScreenProps} from 'react-native-screens/src/types';

import {MainScreen} from './screens/MainScreen';
import {LoginScreen} from './screens/LoginScreen';
import {AddOrEditTimeRecord} from './screens/AddOrEditTimeRecord';
import {AppScreens} from './constants/screens';
import {LoadingScreen} from './screens/LoadingScreeen';
import {LoginController} from './models/loginController';
import {TimeRecordForm} from './models/timeRecordForm';
import {DailyEditScreen} from './screens/DailyEditScreen';
import {DailyRecord} from './models/dailyRecord';

const Stack = createNativeStackNavigator();

export type RootStackParamList = {
  [AppScreens.MainScreen]: {};
  [AppScreens.DailyEditScreen]: {record: DailyRecord};
  [AppScreens.LoginScreen]: {ctrl: LoginController};
  [AppScreens.AddOrEditTimeRecord]: {form: TimeRecordForm};
};

type RootStackScreens = keyof RootStackParamList;

export type RouteScreenProp<T extends RootStackScreens> = RouteProp<RootStackParamList, T>;
export type NavigationScreenProp<T extends RootStackScreens> = NativeStackNavigationProp<RootStackParamList, T>;

export type NavigationScreenProps<T extends RootStackScreens> = {
  route: RouteScreenProp<T>;
  navigation: NavigationScreenProp<T>;
};

const defaultOptions = {
  gestureEnabled: false,
  headerShown: false,
  statusBarStyle: 'light' as ScreenProps['statusBarStyle'],
};

const Routes: React.FC = () => {
  return (
    <Stack.Navigator initialRouteName={AppScreens.LoadingScreen}>
      <Stack.Screen name={AppScreens.MainScreen} component={MainScreen} options={defaultOptions} />
      <Stack.Screen name={AppScreens.LoginScreen} options={defaultOptions}>
        {(props) => <LoginScreen {...(props as NavigationScreenProps<AppScreens.LoginScreen>)} />}
      </Stack.Screen>
      <Stack.Screen name={AppScreens.LoadingScreen} component={LoadingScreen} options={defaultOptions} />
      <Stack.Screen name={AppScreens.DailyEditScreen} options={defaultOptions}>
        {(props) => <DailyEditScreen {...(props as NavigationScreenProps<AppScreens.DailyEditScreen>)} />}
      </Stack.Screen>
      <Stack.Screen name={AppScreens.AddOrEditTimeRecord} options={defaultOptions}>
        {(props) => <AddOrEditTimeRecord {...(props as NavigationScreenProps<AppScreens.AddOrEditTimeRecord>)} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

export default Routes;
