import * as api from '../api';
import {AsanaTask} from './asanaTask';
import {Root} from './root';

export class AsanaTasksList {
  model: Root;
  ids: string[] = [];
  parentIds: Set<string> = new Set();
  byId: Map<string, AsanaTask> = new Map();
  loading: boolean = false;

  constructor(root: Root) {
    this.model = root;
  }

  get empty() {
    return this.tasks.length === 0;
  }

  get tasks(): AsanaTask[] {
    const list: AsanaTask[] = [];
    this.ids.map((id) => {
      const ag = this.byId.get(id);
      if (ag) list.push(ag);
    });
    return list;
  }

  get assigneeTasks() {
    return this.tasks.filter((el) => el.isAssigneeToMe);
  }

  setFromApi(data: api.AsanaTask[]) {
    for (const r of data) {
      this.addFromApi(r);
      this.parentIds.delete(r.gid);
      if (r.parent) {
        this.addFromApi(r.parent);
        if (!this.byId.has(r.parent.gid)) {
          this.parentIds.add(r.parent.gid);
        }
      }
    }
  }

  setProject4AllTasksInList(projectId: string) {
    for (const task of this.tasks) {
      if (!task.hasProjectInfo) task.setProject(projectId);
    }
  }

  addFromApi(p: api.AsanaTask): AsanaTask {
    const id = p.gid;
    let ag = this.byId.get(id);
    if (ag) {
      ag.setFromApi(p);
    } else {
      ag = new AsanaTask(this.model, p);
      this.byId.set(id, ag);
      this.ids.push(id);
    }
    return ag;
  }

  add(task: AsanaTask) {
    this.byId.set(task.id, task);
    this.ids.push(task.id);
  }

  remove(id: string) {
    this.ids = this.ids.filter((el) => el !== id);
    this.byId.delete(id);
  }

  clear() {
    this.ids = [];
    this.byId = new Map();
    this.parentIds = new Set();
  }

  serialize(): any[] {
    const p: any[] = [];
    for (const task of this.tasks) {
      const serialized = task.serialize();
      p.push(serialized);
    }
    return p;
  }
}
