import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const getThreads = makeHandler(
  'getThreads',
  () => ({
    method: 'GET',
    path: `/ThreadsGrid?${getRequestFields()}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: ThreadsResponse) => res.data.records,
);

export const getThread = makeHandler(
  'getThread',
  (id: string) => ({
    method: 'GET',
    path: `/ThreadsGrid/${id}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: {data: ThreadResponseItem}) => res.data,
);

export type ThreadsResponse = IResponse<IThread>;
export type ThreadResponseItem = IResponseItem<IThread>;

export interface IThread {
  thread: string;
  status: string;
  is_private: boolean;
  asana_gid_thread: string;
}

export interface IThreadWithId extends IThread {
  id: string;
}

function getRequestFields() {
  const fields = ['thread', 'status', 'is_private', 'asana_gid_thread'];
  return objectToQueryParamString({'fields[]': fields});
}
