import {ApiHandlerParams, ApiHandlerResponse, ApiHandlerTypes, getHandler} from './calls';
import {ApiError, isApiError} from './error';
import {ApiTokens, Request, ApiUrls} from './request';
import {appProvider} from '../appProvider';

type ApiConfig = {
  timeout?: number;
  onError?: ErrorHandler;
};

type ErrorHandler = (e: ApiError) => void;

type ProtoError = {
  errors: Record<string, string[]>;
};

export const failStatuses = new Set<number>([401, 404]);
const extraGoodHttpStatuses = new Set<number>([204]);

export class Api {
  private timeout: number;
  private errorHandler?: ErrorHandler;

  constructor(config: ApiConfig) {
    this.timeout = config.timeout ?? 60 * 1000;
    if (config.onError) this.errorHandler = config.onError;
  }

  call<Type extends ApiHandlerTypes>(type: Type, params: ApiHandlerParams<Type>): Promise<ApiHandlerResponse<Type>>;
  call(type: any, params: any): any {
    const h = getHandler(type);
    const req = h.prepare(params);
    const a = this.makeRequest(req).then((data) => h.decode(data));
    if (this.errorHandler) a.catch(this.errorHandler);
    return a;
  }

  private makeRequest(params: Request): Promise<any> {
    const url = `${ApiUrls[params.apiType]}${params.path}`;
    const headers: Record<string, string> = {
      Authorization: `Bearer ${ApiTokens[params.apiType]}`,
      'Content-Type': 'application/json',
      ...params.headers,
    };

    const jsonData = params.data ? JSON.stringify(params.data) : null;
    const init: RequestInit = {
      method: params.method,
      headers: headers,
      body: jsonData,
    };

    const abortController = new AbortController();
    init.signal = abortController.signal;

    let timeoutHandle: ReturnType<typeof setTimeout> | undefined = setTimeout(
      () => abortController.abort(),
      this.timeout,
    );
    const cleanupTimeout = () => {
      if (!timeoutHandle) return;
      clearTimeout(timeoutHandle);
      timeoutHandle = undefined;
    };

    const requestNumber = Math.round(Math.random() * 10000);

    appProvider.application.logger.info(requestNumber + ': api request: ', url, init);
    return fetch(url, init)
      .then((res) => {
        if (res.ok || extraGoodHttpStatuses.has(res.status)) {
          const contentType = res.headers.get('Content-Type');
          const contentLength = res.headers.get('content-length');
          if (contentType === 'text/plain' || contentLength === '0' || !contentType) {
            // TODO may need to tune this logic
            return Promise.resolve({});
          }
          return res.json().then((r) => {
            let responseData: any = r.data || {data: r} || {};
            if (r.hasOwnProperty('page')) {
              // pagination handling
              responseData = {data: r.data || {data: r}, page: r.page};
            }
            appProvider.application.logger.info(
              requestNumber + ': api response: ' + JSON.stringify(responseData) + '\n',
            );
            return responseData;
          });
        }

        const err = new ApiError('NetworkError');
        err.httpStatus = res.status;
        err.httpStatusText = res.statusText;
        err.message = '';

        return res.text().then((text: string) => {
          appProvider.application.logger.error(text);
          try {
            const data = JSON.parse(text) as ProtoError;
            const errors = data?.errors || (data as ProtoError);
            let errorDetail: Record<string, string> = {};
            for (let i in errors) {
              if (errors.hasOwnProperty(i)) {
                let e = errors[i];
                if (e instanceof Array) {
                  err.message = `${i}: ${e.join(';')}`;
                  errorDetail[i] = e.join(';');
                } else {
                  errorDetail[i] = e;
                }
              }
            }
            if (errorDetail.hasOwnProperty('detail')) {
              err.message = errorDetail.detail;
            }
            err.details = errorDetail;
          } catch (e) {
            err.message = text;
          }
          throw err;
        });
      })
      .catch((e: Error) => {
        const app = appProvider.application;
        if (!app.model.netInfoCtrl.isInternetReachable) {
          app.showNoConnectionModal();
        }
        if (isApiError(e)) throw e;
        const err = new ApiError('NetworkError', e.toString());
        throw err;
      })
      .finally(() => {
        cleanupTimeout();
      });
  }
}
