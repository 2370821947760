import {makeAutoObservable} from 'mobx';

import * as api from '../api';

export class Thread {
  id: string = '';
  thread: string = '';
  status: string = '';
  isPrivate: boolean = false;
  asanaId: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  get title() {
    return this.thread;
  }

  get isActive() {
    return (this.status = 'Active');
  }

  setFromApi(p: api.IThreadWithId) {
    this.id = p.id;
    this.thread = p.thread;
    this.status = p.status;
    this.isPrivate = p.is_private;
    this.asanaId = p.asana_gid_thread;
  }

  serialize(): any {
    return {
      id: this.id,
      thread: this.thread,
      status: this.status,
      is_private: this.isPrivate,
      asana_gid_thread: this.asanaId,
    };
  }
}
