import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';

import theme from '../../theme';
import {strings} from '../../locales/i18n';
import {appProvider} from '../../appProvider';
import {DailyRecordsList} from '../DailyRecordsList';
import {Button} from '../../elements/Button';
import {FormInputBlock} from '../FormInputBlock';
import {FormInputLabel} from '../FormInputLabel';
import {DailyBlockScrollWrapper} from './DailyBlockScrollWrapper';

type Props = {
  onClose?: () => void;
};

export const DailyBlock: React.FC<Props> = observer(() => {
  const app = appProvider.application;
  const model = app.model;
  const ctrl = model.dailyCtrl;
  const showYesterdayBlock = !model.dailyCtrl.confirmed; // TODO add logic если вчера был отпуск или day off
  const showTodayBlock = true; // TODO add logic

  const renderYesterdayBlock = () => {
    if (!showYesterdayBlock) return null;
    return <DailyRecordsList record={model.dailyCtrl.yesterday} />;
  };

  const renderTodayBlock = () => {
    if (!showTodayBlock) return null;
    return <DailyRecordsList record={model.dailyCtrl.today} />;
  };

  const onConfirmPress = () => {
    if (!ctrl.confirmed && !ctrl.yesterday.empty) {
      app.updateDailyRecord(ctrl.yesterday.id, ctrl.yesterday.record4confirm);
    }
    if (!ctrl.today.empty) {
      app.updateDailyRecord(ctrl.today.id, ctrl.today.record4export);
    }
    app.showSnackMessage(!ctrl.confirmed ? strings('info.daily.report.sent') : strings('info.record.edited'));
  };

  const renderButtonBlock = () => {
    const title = ctrl.confirmed ? strings('common.save') : strings('action.send');
    return (
      <View style={s.buttonBlock}>
        <Button title={title} disabled={ctrl.empty} onPress={onConfirmPress} />
      </View>
    );
  };

  const renderBlockersBlock = () => {
    if (ctrl.today.empty) {
      return null;
    }
    return (
      <View style={s.blockersBlock}>
        <FormInputLabel labelText={strings('info.has.blockers')} field={ctrl.today.blockersField} />
        <FormInputBlock
          field={ctrl.today.blockersField}
          type="textarea"
          textarea={{multiline: true, numberOfLines: 2}}
        />
      </View>
    );
  };

  return (
    <View style={styles[theme.stylesType].container}>
      <DailyBlockScrollWrapper>
        <Text style={styles[theme.stylesType].title}>{strings('info.daily.report')}</Text>
        <Text style={s.text}>{app.model.dailyCtrl.title}</Text>
        {renderYesterdayBlock()}
        {renderTodayBlock()}
        {renderBlockersBlock()}
        {renderButtonBlock()}
        <View style={s.heightSpacer} />
      </DailyBlockScrollWrapper>
    </View>
  );
});

interface IScreenStyles {
  container: ViewStyle;
  title: TextStyle;
}

interface IStyles {
  [x: string]: IScreenStyles;
}

const styles: IStyles = {
  web: {
    container: {
      flex: 1,
      backgroundColor: theme.bgColorWeb,
      width: theme.webScreensWidth + theme.space16 * 2,
      alignContent: 'center',
      margin: 'auto',
    },
    title: {
      fontSize: theme.fontSize32,
      color: theme.textColorSecondary,
      fontFamily: theme.fontFamilyMain,
      fontWeight: '600',
    },
  },
  native: {
    container: {
      flex: 1,
      justifyContent: 'space-between',
    },
    title: {
      paddingTop: theme.aligned(40),
      fontSize: theme.fontSize32,
      color: theme.textColorSecondary,
      fontFamily: theme.fontFamilyMain,
      fontWeight: '600',
    },
  },
};

const s = StyleSheet.create({
  text: {
    fontSize: theme.fontSize16,
    color: theme.textColorSecondary,
    fontFamily: theme.fontFamilyMain,
    fontWeight: '400',
    paddingTop: theme.space16,
    lineHeight: theme.space24,
  },
  subTitle: {
    paddingTop: theme.aligned(40),
    paddingBottom: theme.space16,
    fontSize: theme.fontSize24,
    color: theme.textColorSecondary,
    fontFamily: theme.fontFamilyMain,
    fontWeight: '500',
  },
  heightSpacer: {
    height: theme.aligned(30),
  },
  separator: {
    height: theme.pt,
    backgroundColor: theme.bgColorDark400,
  },
  buttonBlock: {
    paddingTop: theme.space8,
  },
  blockersBlock: {
    paddingTop: theme.space8,
    paddingBottom: theme.space16,
  },
});
