import React, {ReactNode, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, StyleSheet, ViewStyle} from 'react-native';
import {Modalize} from 'react-native-modalize';

import theme from '../theme';
import {IModalController} from '../types';

interface Props {
  renderer: () => ReactNode;
  modalController: IModalController;
  withOverlay?: boolean;
  style?: ViewStyle;
  closeOnOverlayTap?: boolean;
  overlayStyle?: ViewStyle;
  headerComponent?: ReactNode;
  adjustToContentHeight?: boolean;
  FloatingComponent?: ReactNode;
  contentContainerStyle?: ViewStyle;
  scrollEventThrottle?: number;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => NativeSyntheticEvent<NativeScrollEvent>;
  disableScrollIfPossible?: boolean; // workaround for bug on android (scroll disabled using adjustToContentHeight)
  panGestureEnabled?: boolean;
  withHandle?: boolean;
  snapPoint?: number;
}

export const BottomSheetModal: React.FC<Props> = observer((props) => {
  const modalizeRef = useRef<Modalize>(null);

  useEffect(() => {
    Keyboard.dismiss();
    modalizeRef.current?.open();
  });

  const onClose = () => props.modalController.hide();

  return (
    <Modalize
      disableScrollIfPossible={props.disableScrollIfPossible}
      closeOnOverlayTap={props.closeOnOverlayTap}
      overlayStyle={props.overlayStyle}
      withOverlay={props.withOverlay}
      scrollViewProps={{
        onScroll: props?.onScroll,
        contentContainerStyle: props.contentContainerStyle,
        scrollEventThrottle: props.scrollEventThrottle,
        showsVerticalScrollIndicator: false,
        keyboardShouldPersistTaps: 'always',
        keyboardDismissMode: 'on-drag',
      }}
      snapPoint={props.snapPoint}
      withHandle={props.withHandle}
      panGestureEnabled={props.panGestureEnabled}
      HeaderComponent={props.headerComponent}
      FloatingComponent={props.FloatingComponent}
      modalStyle={[s.container, props.style]}
      rootStyle={Platform.OS === 'android' ? s.androidRootStyle : null}
      adjustToContentHeight={props.adjustToContentHeight}
      ref={modalizeRef}
      onClose={onClose}>
      {props.renderer()}
    </Modalize>
  );
});

const s = StyleSheet.create({
  container: {
    backgroundColor: theme.bgColor,
    elevation: 0,
  },
  androidRootStyle: {
    zIndex: 1,
  },
});
