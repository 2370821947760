import React from 'react';
import {View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';

import theme from '../theme';
import {appProvider} from '../appProvider';
import {IMenuItem} from '../types';
import {strings} from '../locales/i18n';
import {MenuCardsList} from './MenuCardsList';

type Props = {
  menuItems?: IMenuItem[];
};

export const BottomSheetMenuIOS: React.FC<Props> = observer((props) => {
  const app = appProvider.application;
  const containerStyle: ViewStyle = {marginBottom: app.model.appCtrl?.safeAreaInsets.bottom || theme.space12};

  const renderItems = () => {
    if (!props.menuItems?.length) return null;
    return (
      <>
        <View style={styles[theme.stylesType].cardsContainer}>
          <MenuCardsList items={props.menuItems} noMargin />
        </View>
        <View style={sSpacer} />
      </>
    );
  };

  return (
    <View style={containerStyle}>
      {renderItems()}
      <MenuCardsList
        containerStyle={styles[theme.stylesType].cancelCardButton}
        items={[{title: strings('common.cancel'), onPress: () => app.modal.hideLastEntry()}]}
        noMargin
      />
    </View>
  );
});

const sSpacer: ViewStyle = {
  height: theme.space12,
};

interface IScreenStyles {
  cardsContainer: ViewStyle;
  cancelCardButton: ViewStyle;
}

interface IStyles {
  [x: string]: IScreenStyles;
}

const styles: IStyles = {
  web: {
    cardsContainer: {
      borderRadius: theme.cardBorderRadius,
      backgroundColor: theme.bgColorDark200Web,
    },
    cancelCardButton: {
      backgroundColor: theme.bgColorDark200Web,
      borderRadius: theme.cardBorderRadius,
    },
  },
  native: {
    cardsContainer: {
      borderRadius: theme.aligned(14),
      backgroundColor: theme.modalBkgColor,
    },
    cancelCardButton: {
      backgroundColor: theme.modalBkgColor,
      borderRadius: theme.aligned(14),
    },
  },
};
