import {makeAutoObservable} from 'mobx';
import {endOfToday, isFriday, isSaturday, isSunday, isToday, isYesterday, startOfDay, subDays} from 'date-fns';

import * as api from '../api';
import {DailyRecord} from './dailyRecord';
import {Root} from './root';
import {decodeDate} from '../utils/decodeDate';
import {formatAirTableParsedDate, formatAirTableParsedDateWithTime} from '../utils/formatters';
import {strings} from '../locales/i18n';
import {middleOfDay} from '../utils/dateUtils';

export class DailyController {
  model: Root;
  today: DailyRecord;
  yesterday: DailyRecord;
  loading: boolean = false;
  yesterdayDate = subDays(new Date(), 1);

  constructor(root: Root) {
    this.today = new DailyRecord(root);
    this.today.setDailyDate(Date.now());
    this.yesterday = new DailyRecord(root);
    this.yesterday.setDailyDate(subDays(Date.now(), 1));
    this.model = root;
    makeAutoObservable(this);
  }

  get confirmed() {
    return Boolean(this.yesterday.confirmDate);
  }

  get empty() {
    return (this.today.empty && this.yesterday.empty) || (this.confirmed && this.today.empty);
  }

  get lastDateDay() {
    if (isSaturday(this.yesterdayDate)) return middleOfDay(subDays(this.yesterdayDate, 1));
    if (isSunday(this.yesterdayDate)) return middleOfDay(subDays(this.yesterdayDate, 2));
    return middleOfDay(this.yesterdayDate);
  }
  get lastDateText(): string {
    if (isSaturday(this.yesterdayDate)) return strings('common.last.two.days');
    if (isSunday(this.yesterdayDate)) return strings('common.last.three.days');
    return strings('common.yesterday');
  }
  get title() {
    return `${strings('info.daily.text1')}${this.lastDateText.toLowerCase()}${strings('info.daily.text2')}`;
  }

  get airTableFilter(): string {
    const isBefore = `IS_BEFORE({daily_date}, ${formatAirTableParsedDateWithTime(endOfToday())})`;
    const isAfter = `IS_AFTER({daily_date}, ${formatAirTableParsedDate(startOfDay(this.lastDateDay))})`;
    return `${isAfter}, ${isBefore}`;
  }

  setFromApi(data: api.DailyResponseItem[]) {
    for (const p of data) {
      const dailyDate = decodeDate(p.fields.daily_date);
      if (isToday(dailyDate)) {
        this.today.setFromApi({...p.fields, id: p.id});
      } else {
        this.processYesterday(p, dailyDate);
      }
    }
  }

  processYesterday(p: api.DailyResponseItem, dailyDate: number) {
    const isHoliday = isSunday(this.yesterdayDate) || isSunday(this.yesterdayDate);
    const isValid = (isHoliday && isFriday(dailyDate)) || isYesterday(dailyDate);
    if (isValid) {
      this.yesterday.setFromApi({...p.fields, id: p.id});
    }
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}
