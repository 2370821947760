import React from 'react';
import {observer} from 'mobx-react-lite';

import {TimeRecordForm} from '../models/timeRecordForm';

type Props = {
  form: TimeRecordForm;
};

export const TimeRecordDatePiker: React.FC<Props> = observer(() => {
  // TODO
  return null;
});
