import {Translations} from '../types';
const ru: Translations = {
  'common.continue': 'Продолжить',
  'common.back': 'Назад',
  'common.of': 'из',
  'common.verification.code': 'Код верификации',
  'common.with.email': 'с email',
  'common.logout': 'Выйти',
  'common.this.week': 'Текущая неделя',
  'common.today': 'Сегодня',
  'common.past.week': 'Предыдущая неделя',
  'common.total': 'Всего',
  'common.history': 'История',
  'common.time': 'Время',
  'common.hello': 'Привет',
  'common.cancel': 'Отмена',
  'common.delete': 'Удалить',
  'common.save': 'Сохранить',
  'common.time.record': 'Время',
  'common.date': 'Дата',
  'common.project': 'Проект',
  'common.process': 'Процесс',
  'common.confirm': 'Подтвердить',
  'common.notes': 'Комментарий',
  'common.timesheet': 'Записи',
  'common.hi': 'Привет',
  'common.task': 'Задача',
  'common.daily': 'Дейли',
  'common.yesterday': 'Вчера',
  'common.last.two.days': 'Последние 2 дня',
  'common.last.three.days': 'Последние 3 дня',

  'placeholder.email': 'Email',
  'placeholder.comment': 'Напишите что-нибудь...',
  'placeholder.search': 'Поиск',
  'placeholder.select.task': 'Выберите задачу',
  'placeholder.task.search': 'Например Work planning',

  'action.resend.code': 'Выслать заново',
  'action.add.time': 'Добавить время',
  'action.entry.edit': 'Редактировать запись',
  'action.entry.delete': 'Удалить запись',
  'action.daily.delete': 'Удалить запись из дейли',
  'action.end.record': 'Записать',
  'action.play': 'Старт',
  'action.pause': 'Пауза',
  'action.try.again': 'Повторить',
  'action.login': 'Логин',
  'action.resume': 'Продолжить',
  'action.cancel.positive': 'Да',
  'action.continue.negative': 'Нет',
  'action.search.more': 'Поискать еще',
  'action.repeat.search': 'Повторить поиск',
  'action.drop.cache': 'Очистить кеш поиска',
  'action.open.in.asana': 'Открыть задачу в Asana',
  'action.continue.tracking': 'Продолжить трекинг',
  'action.yes.stop': 'Да, остановить',
  'action.no.cancel': 'Нет, отменить',
  'action.understand': 'Понятно',
  'action.replace': 'Заменить',
  'action.add': 'Добавить',
  'action.new.record': 'Добавить новую запись',
  'action.send': 'Отправить',

  'error.incorrect.email': 'Неверный email',
  'error.invalid.code': 'Неверный код',
  'error.email.not.found': 'Введенный email не найден',
  'error.authorization.problem': 'Проблема с авторизацией',
  'error.session.problem': 'Проблема при запросе сессии',
  'error.invalid.value': 'Неверное значение',
  'error.task.search.string.length': 'Введите слово из 3х или более символов',
  'error.no.project.selected': 'Сперва нужно выбрать Проект/Процесс',
  'error.unable.task.select': 'Навозможно выбрать задачу',
  'error.warning': 'Внимание!',

  'info.code.was.resend': 'Вам был выслан новый код',
  'info.hours.tracked': 'Всего часов',
  'info.try.again': 'попробуйте повторить позже',
  'info.login.subtitle1': 'Добро пожаловать в Violet Time Tracker',
  'info.login.subtitle2': 'Следите за своими проектами',
  'info.verification.code.notice': 'Мы выслали код верификации на ваш email. Код действует в течение 1 часа',
  'info.timerecord.deleted': 'Запись удалена',
  'info.new.time.entry': 'Новая запись времени',
  'info.edit.time.entry': 'Изменить запись времени',
  'info.working.hours': 'Рабочее время',
  'info.oops': 'Извините',
  'info.couldnt.connect': 'Мы не смогли подключиться к Интернету.',
  'info.check.connection': 'Проверьте настройки подключения и попробуйте еще раз',
  'info.session.expired.title': 'Cессия истекла',
  'info.session.expired': 'Пожалуйста, войдите еще раз',
  'info.cancel.addition': 'Отменить добавление?',
  'info.cancel.editing': 'Отменить редактирование?',
  'info.data.not.saved': 'При отмене данные не будут сохранены',
  'info.no.data.saved': 'Данные не сохранены',
  'info.record.edited': 'Запись сохранена',
  'info.record.added': 'Запись добавлена',
  'info.no.results.found': 'Ничего не найдено',
  'info.start.typing.task': 'Начните ввод названия задачи',
  'info.task.linked.another.project': 'Задача привязана к другому проекту',
  'info.continue.tracking.confirm': 'Чтобы возобновить трекинг, сперва остановите текущий',
  'info.continue.tracking.title': 'Возобновить трекинг',
  'info.task.not.linked.any.project': 'Задача не связана ни с одим проектом. Сообщите своему PM',
  'info.daily.report': 'Новый дейли отчет',
  'info.daily.text1': 'Заполни, чем занимался ',
  'info.daily.text2': ' и планируешь заняться сегодня',
  'info.daily.task.selector.title': 'Выбери задачи для дейли',
  'info.daily.report.sent': 'Отчет успешно отправлен',
  'info.has.blockers': 'Какие блокеры?',
};

export default ru;
