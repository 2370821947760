import {observer} from 'mobx-react-lite';
import {Platform, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle} from 'react-native';
import React from 'react';

import theme from '../theme';
import {ConfirmationModalButton} from '../types';
import {isNative} from '../constants/general';

type Props = {
  onClose: () => void;
  title?: string;
  contentRenderer?: () => React.ReactNode;
  text?: string;
  buttons: ConfirmationModalButton[];
  buttonsInVerticalRows?: boolean;
};

interface IModalStyles {
  modalView: ViewStyle;
  title: TextStyle;
  button: ViewStyle;
  buttonVertical: ViewStyle;
  buttonSingle: ViewStyle;
  text: TextStyle;
  buttonText: TextStyle;
  horizontalButtonsWrapper: ViewStyle;
  verticalButtonsWrapper: ViewStyle;
}

interface IStyles {
  android: IModalStyles;
  ios: IModalStyles;
  web: IModalStyles;
}

const OS = (isNative ? Platform.OS : 'web') as 'web' | 'ios' | 'android';

export const ConfirmationModal: React.FC<Props> = observer((props) => {
  const {title, contentRenderer, text, buttons} = props;

  const renderTitle = () => {
    if (!title) return null;
    return <Text style={platformStyles[OS].title}>{title}</Text>;
  };

  const renderText = () => {
    if (!text) return null;
    return <Text style={platformStyles[OS].text}>{(text || '').trim()}</Text>;
  };

  const renderButton = (item: ConfirmationModalButton) => {
    const buttonStyle = [
      props.buttonsInVerticalRows ? platformStyles[OS].buttonVertical : platformStyles[OS].button,
      buttons.length === 1 ? platformStyles[OS].buttonSingle : null,
      item.isWarning === undefined && OS === 'web' ? styles.buttonConfirm : null,
      item.isWarning && OS === 'web' ? styles.buttonWarning : null,
    ];

    const textStyle = [
      platformStyles[OS].buttonText,
      item.isWarning === undefined && OS !== 'web' ? styles.buttonConfirmText : null,
      item.isWarning && OS !== 'web' ? styles.warningText : null,
    ];
    return (
      <TouchableOpacity style={buttonStyle} onPress={item.action}>
        <Text numberOfLines={1} style={textStyle}>
          {item.text}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderButtonsBlock = () => {
    if (props.buttonsInVerticalRows) return renderVerticalButtons();

    return (
      <View style={platformStyles[OS].horizontalButtonsWrapper}>
        {buttons.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {renderButton(item)}
              {index === 0 && OS === 'ios' && <View style={styles.borderRight} />}
              {buttons.length > 1 && index === 0 && OS === 'web' && <View style={styles.buttonSpacer} />}
            </React.Fragment>
          );
        })}
      </View>
    );
  };

  const renderVerticalButtons = () => {
    return (
      <View style={platformStyles[OS].verticalButtonsWrapper}>
        {buttons.reverse().map((btn, index) => {
          return <React.Fragment key={index}>{renderButton(btn)}</React.Fragment>;
        })}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={platformStyles[OS].modalView}>
        {contentRenderer ? contentRenderer() : renderTitle()}
        {renderText()}
        {renderButtonsBlock()}
      </View>
    </View>
  );
});

const platformStyles: IStyles = {
  android: {
    modalView: {
      width: '85%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      backgroundColor: theme.modalBkgColor,
      borderRadius: theme.aligned(8),
      elevation: theme.aligned(10),
    },
    title: {
      textAlign: 'left',
      fontWeight: '500',
      fontSize: theme.aligned(20),
      lineHeight: theme.aligned(24),
      color: theme.textColor,
      paddingTop: theme.aligned(24),
      paddingHorizontal: theme.aligned(24),
    },
    button: {
      justifyContent: 'center',
      paddingBottom: theme.space16,
      paddingLeft: theme.space20,
    },
    buttonVertical: {
      height: theme.aligned(50),
      paddingRight: theme.space16,
    },
    buttonSingle: {
      alignItems: 'flex-end',
      width: '100%',
    },
    text: {
      textAlign: 'left',
      fontWeight: '400',
      fontSize: theme.fontSize16,
      lineHeight: theme.aligned(20),
      paddingTop: theme.aligned(16),
      paddingHorizontal: theme.aligned(24),
      paddingBottom: theme.space20,
      color: theme.textColor,
    },
    buttonText: {
      textAlign: 'center',
      fontWeight: '500',
      fontSize: theme.fontSize14,
      lineHeight: theme.aligned(16),
      color: theme.textColor,
      paddingTop: theme.space10,
      textTransform: 'uppercase',
    },
    horizontalButtonsWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      maxHeight: theme.aligned(58),
      margin: 0,
      paddingRight: theme.space16,
    },
    verticalButtonsWrapper: {
      alignItems: 'flex-end',
      justifyContent: 'center',
      minWidth: '100%',
      margin: 0,
      padding: 0,
    },
  },
  ios: {
    modalView: {
      width: '80%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.modalBkgColor,
      borderRadius: theme.aligned(18),
      borderColor: theme.bgColorDark400,
      borderWidth: theme.pt,
    },
    title: {
      textAlign: 'center',
      fontWeight: '600',
      fontSize: theme.aligned(18),
      lineHeight: theme.aligned(20),
      color: theme.textColor,
      paddingTop: theme.aligned(19),
      paddingHorizontal: theme.space16,
    },
    button: {
      width: '50%',
      justifyContent: 'center',
      borderTopWidth: theme.pt,
      borderTopColor: theme.lightGreyColor,
    },
    buttonVertical: {
      borderTopWidth: theme.pt,
      borderTopColor: theme.lightGreyColor,
      height: theme.aligned(50),
      justifyContent: 'center',
      minWidth: '100%',
    },
    buttonSingle: {
      width: '100%',
    },
    text: {
      color: theme.textColor,
      textAlign: 'center',
      fontWeight: '500',
      fontSize: theme.aligned(13),
      lineHeight: theme.aligned(16),
      paddingTop: theme.aligned(7),
      paddingHorizontal: theme.space16,
      paddingBottom: theme.space20,
    },
    buttonText: {
      textAlign: 'center',
      fontWeight: '500',
      fontSize: theme.aligned(15),
      lineHeight: theme.aligned(15),
      color: theme.blueDarkColor,
      paddingHorizontal: theme.space16,
      paddingBottom: theme.space12,
      paddingTop: theme.space10,
    },
    horizontalButtonsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
      maxHeight: theme.aligned(58),
      margin: 0,
      padding: 0,
    },
    verticalButtonsWrapper: {
      width: '100%',
    },
  },
  web: {
    modalView: {
      maxWidth: theme.aligned(500),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.bgColorDark200Web,
      borderRadius: theme.aligned(8),
      elevation: theme.aligned(10),
    },
    title: {
      textAlign: 'center',
      fontWeight: '500',
      fontSize: theme.aligned(20),
      lineHeight: theme.aligned(24),
      color: theme.textColor,
      paddingTop: theme.aligned(40),
      paddingHorizontal: theme.aligned(24),
    },
    button: {
      width: theme.aligned(112),
      justifyContent: 'center',
      padding: theme.space16,
      borderRadius: theme.cardBorderRadius,
    },
    buttonVertical: {
      height: theme.aligned(50),
      paddingRight: theme.space16,
    },
    buttonSingle: {
      width: theme.aligned(112),
      justifyContent: 'center',
      padding: theme.space16,
      borderRadius: theme.cardBorderRadius,
    },
    text: {
      textAlign: 'center',
      fontWeight: '400',
      fontSize: theme.fontSize16,
      lineHeight: theme.aligned(20),
      paddingTop: theme.aligned(16),
      paddingHorizontal: theme.aligned(24),
      paddingBottom: theme.aligned(32),
      color: theme.textColor,
    },
    buttonText: {
      textAlign: 'center',
      fontWeight: '500',
      fontSize: theme.fontSize14,
      lineHeight: theme.aligned(16),
      color: theme.textColor,
      textTransform: 'uppercase',
    },
    horizontalButtonsWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      maxHeight: theme.aligned(58),
      margin: 0,
      marginHorizontal: theme.space16,
      marginBottom: theme.aligned(40),
    },
    verticalButtonsWrapper: {},
  },
};

const styles = StyleSheet.create({
  background: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: theme.modalBkgColor,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    width: '100%',
  },
  dividingLine: {
    borderBottomWidth: theme.aligned(1),
    color: theme.lightGreyColor,
    width: '100%',
  },
  borderRight: {
    width: 1,
    height: '100%',
    backgroundColor: theme.lightGreyColor,
  },
  buttonConfirmText: {
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.blueDarkColor,
  },
  warningText: {
    color: theme.bgColorRed100,
  },
  buttonConfirm: {
    backgroundColor: '#2D2D2D',
  },
  buttonWarning: {
    backgroundColor: theme.bgColorRedWeb,
    marginRight: theme.aligned(12),
  },
  buttonSpacer: {
    width: theme.aligned(10),
  },
});
