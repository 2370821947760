import {observer} from 'mobx-react-lite';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

import images from '../images';
import {appProvider} from '../appProvider';
import theme from '../theme';
import {strings} from '../locales/i18n';
import {Button} from '../elements/Button';
import {isNative} from '../constants/general';

export const NoConnectionBlock: React.FC = observer(() => {
  const onTryAgain = () => {
    ctrl.forceSetIsInternetReachable();
  };

  const ctrl = appProvider.application.model.netInfoCtrl;
  return (
    <View style={styles.content}>
      <View style={styles.imageBlock}>
        <Image source={images.no_connection} style={!isNative ? styles.image : null} />
      </View>
      <View style={styles.bottomBlock}>
        <Text style={styles.title}>{strings('info.oops')}</Text>
        <Text style={styles.text}>
          {strings('info.couldnt.connect')} {strings('info.check.connection')}
        </Text>
        <View style={styles.buttonBlock}>
          <Button
            title={strings('action.try.again')}
            onPress={onTryAgain}
            loading={ctrl.awaitUpdateListener}
            disabled={ctrl.awaitUpdateListener}
          />
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  content: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.bgColorDark100,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  imageBlock: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  image: {
    width: theme.aligned(150),
    height: theme.aligned(150),
  },
  bottomBlock: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: theme.space16,
  },
  title: {
    paddingTop: theme.aligned(56),
    paddingBottom: theme.aligned(16),
    color: theme.textColor,
    fontSize: theme.fontSize32,
    paddingHorizontal: theme.space16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  text: {
    paddingHorizontal: theme.space16,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    lineHeight: theme.space24,
    textAlign: 'center',
  },
  buttonBlock: {
    paddingTop: theme.aligned(40),
    width: isNative ? theme.windowWidth - theme.space16 * 2 : theme.webScreensWidth,
  },
});
