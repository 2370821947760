import {makeAutoObservable} from 'mobx';

import * as api from '../api';
import {Agreement} from './agreement';
import {Root} from './root';
import {Pagination} from './pagination';

export class AgreementsList {
  private model: Root;
  ids: string[] = [];
  byId: Map<string, Agreement> = new Map();
  loading: boolean = false;
  loadingMore: boolean = false;
  pagination: Pagination;

  constructor(root: Root) {
    this.pagination = new Pagination();
    this.model = root;
    makeAutoObservable(this);
  }

  get empty() {
    return this.ids.length === 0;
  }

  get list(): Agreement[] {
    const profileEmail = this.model.session.email;
    return this.listAll.filter((el) => el.hasEmail(profileEmail) || !el.projectType);
  }

  get listAll(): Agreement[] {
    const list: Agreement[] = [];
    this.ids.map((id) => {
      const ag = this.byId.get(id);
      if (ag) list.push(ag);
    });
    return list;
  }

  getFromAsanaId(asanaId: string) {
    return this.listAll.find((el) => {
      const asanaProject = this.model.asanaProjects.byId.get(asanaId);
      return asanaProject?.projectNumber && asanaProject?.projectNumber === el.projectNumber;
    });
  }

  setFromApi(data: api.IAgreementWithId[]) {
    for (const r of data) {
      this.addFromApi(r);
    }
  }

  addFromApi(p: api.IAgreementWithId): Agreement {
    const id = p.id;
    let ag = this.byId.get(id);
    if (ag) {
      ag.setFromApi(p);
    } else {
      ag = new Agreement();
      ag.setFromApi(p);
      this.byId.set(id, ag);
      this.ids.push(id);
    }
    return ag;
  }

  remove(id: string) {
    this.ids = this.ids.filter((el) => el !== id);
    this.byId.delete(id);
  }

  clear() {
    this.ids = [];
    this.byId = new Map();
    this.setLoading(false);
  }

  setLoading(val: boolean) {
    this.loading = val;
    this.setLoadingMore(false);
  }

  getProjectsNumbers4Search(asanaProjectId: string): string[] {
    const agreement = this.model.agreementsList.getFromAsanaId(asanaProjectId);
    if (!agreement) {
      return [asanaProjectId];
    }
    const linkedProjectsNumbers = this.model.asanaProjects.getLinkedProjectsNumbers(agreement.projectNumber);
    if (linkedProjectsNumbers.length === 0) {
      return [asanaProjectId];
    }
    if (linkedProjectsNumbers.includes(asanaProjectId)) {
      return linkedProjectsNumbers;
    }
    return [...linkedProjectsNumbers, asanaProjectId];
  }

  setLoadingMore(val: boolean) {
    this.loadingMore = val;
  }
}
