import {makeAutoObservable, reaction} from 'mobx';
import {IDisposer} from 'mobx-utils';
import {differenceInSeconds} from 'date-fns';

import {IFormField, ProxyField, ProxyFieldValidator} from './formField';
import {Root} from './root';
import {appProvider} from '../appProvider';
import * as api from '../api';
import {ITimerController, LoginStep} from '../types';
import {DecrementTimerController} from './timerController';
import {strings} from '../locales/i18n';
import {REVIWER_EMAIL, REVIWER_PIN} from '../constants/general';

const RELOAD_TIMER_LIMIT = 60; // in seconds

export class LoginController {
  step: LoginStep = LoginStep.Email;
  model: Root;
  email: string = '';
  emailField: IFormField;
  pinCode: string = '';
  pinCodeField: IFormField;
  loading: boolean = false;
  reloadTimer: ITimerController = new DecrementTimerController(RELOAD_TIMER_LIMIT);
  lastReloadTimerStopped: number = 0;
  isReloadInProgress: boolean = false;

  private disposer: IDisposer | null = null;

  constructor(root: Root) {
    this.emailField = new ProxyField({
      getter: () => this.email,
      setter: (val) => this.setEmail(val as string),
      validator: this.validateEmail,
    });
    this.pinCodeField = new ProxyField({
      getter: () => this.pinCode,
      setter: (val) => this.setPinCode(val as string),
      validator: this.validatePin,
    });
    this.model = root;
    makeAutoObservable(this);
    if (root.user.email) this.setEmail(root.user.email);
    this.createReloadTimerReaction();
  }

  createReloadTimerReaction() {
    if (this.model.session.createdTime) {
      const timeDiff = differenceInSeconds(Date.now(), this.model.session.createdTime);
      if (timeDiff <= RELOAD_TIMER_LIMIT) {
        this.reloadTimer.start();
      }
    }
    this.disposer = reaction(
      () => !this.reloadTimer.isTimerActive,
      () => this.setLastReloadTimerStopped(),
    );
  }

  get canRequestNewPin() {
    if (this.reloadTimer.isTimerActive) return false;
    if (this.lastReloadTimerStopped) return true;
    const timeDiff = differenceInSeconds(Date.now(), this.model.session.createdTime);
    return timeDiff > RELOAD_TIMER_LIMIT;
  }

  get isEmailStep() {
    return this.step === LoginStep.Email;
  }

  setLastReloadTimerStopped() {
    this.lastReloadTimerStopped = new Date().getTime();
    this.isReloadInProgress = false;
  }

  setEmail(val: string) {
    this.email = val;
    if (!val) {
      this.emailField.cleanAllErrors();
    }
  }

  setPinCode(val: string) {
    this.pinCodeField.cleanAllErrors();
    this.pinCode = val;
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

  onLogin = () => {
    if (!this.emailField.isValid || this.loading) return;
    const app = appProvider.application;
    this.setLoading(true);
    app
      .checkUserByEmail(this.email)
      .then((valid) => {
        if (!valid) {
          this.emailField.setError(strings('error.email.not.found'));
          this.emailField.markAsDirty();
          return;
        } else {
          return app.getSessionById().then(() => {
            if (app.model.session.awaitPinCodeCheck) {
              this.setPinCodeStep();
              return Promise.resolve();
            } else {
              return this.createSessionRequest();
            }
          });
        }
      })
      .finally(() => this.setLoading(false));
  };

  onConfirm = () => {
    this.pinCodeField.markAsDirty();
    if (!this.pinCodeField.isValid || this.loading) return;
    appProvider.application.setSessionToken(this);
  };

  onRequestNewPin = () => {
    if (!this.canRequestNewPin) return;
    appProvider.application.createSessionRequest(this).then(() => {
      this.reloadTimer.start();
      this.setReloadInProgress();
    });
  };

  createSessionRequest() {
    this.model.session.clear();
    return appProvider.application.createSessionRequest(this).then(() => {
      this.reloadTimer.start();
    });
  }

  processSessionResponse(res: api.ISession) {
    if (res.email === this.email) this.model.session.setAuthorized();
  }

  setPinCodeStep() {
    this.step = LoginStep.PinCode;
  }

  setEmailStep() {
    this.step = LoginStep.Email;
  }

  setReloadInProgress() {
    this.isReloadInProgress = true;
  }

  validateEmail: ProxyFieldValidator = (val) => {
    if (!validateEmailFunc(val as string)) return strings('error.incorrect.email');
    return undefined;
  };

  validatePin: ProxyFieldValidator = (val) => {
    if (!val) return strings('error.invalid.code');
    if (this.emailField.value === REVIWER_EMAIL && val === REVIWER_PIN) return undefined;
    if (val !== this.model.session.verificationCode) return strings('error.invalid.code');
    return undefined;
  };

  destroy() {
    if (this.disposer) this.disposer();
  }
}

export function validateEmailFunc(email?: string) {
  if (!email) return false;
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return reg.test(email);
}
