import React, {useEffect} from 'react';
import {EdgeInsets, useSafeAreaInsets} from 'react-native-safe-area-context';

type Props = {
  onSetInsets: (insets: EdgeInsets) => void;
};

export const SafeAreaInsetsProvider: React.FC<Props> = (props) => {
  const insets = useSafeAreaInsets();
  useEffect(() => {
    props.onSetInsets(insets);
  }, [insets, props]);
  return null;
};
