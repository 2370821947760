import {makeAutoObservable} from 'mobx';
import {
  AppState,
  AppStateStatus,
  EmitterSubscription,
  Keyboard,
  KeyboardEventListener,
  NativeEventSubscription,
  Platform,
} from 'react-native';
import {IDisposer} from 'mobx-utils';
import {EdgeInsets} from 'react-native-safe-area-context';

import {Root} from './root';
import {appProvider} from '../appProvider';
import {AppScreens} from '../constants/screens';
import {AppNavigationType} from '../types';
import {debounce} from '../utils/debounce';
import theme from '../theme';

export const DEFAULT_SAFEAREA_INSETS: EdgeInsets = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const keyboardShowEvent = Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow';
const keyboardHideEvent = Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide';

export class AppController {
  _navigation?: AppNavigationType;
  private disposers: IDisposer[] = [];
  model: Root;
  app = appProvider.application;
  keyboardVisible: boolean = false;
  keyboardHeight: number = 0;
  screenWidth: number = theme.windowWidth;
  safeAreaInsets: EdgeInsets = DEFAULT_SAFEAREA_INSETS;
  appStateStatus: AppStateStatus = 'active';
  showKeyboardListener: EmitterSubscription;
  hideKeyboardListener: EmitterSubscription;
  appStateListener: NativeEventSubscription;
  appFocusListener: NativeEventSubscription | null = null; // Android only
  processActiveAppStateDebounced = debounce(this.processActiveAppState, 300);

  constructor(root: Root) {
    makeAutoObservable(this);
    this.model = root;
    this.appStateListener = AppState.addEventListener('change', this.handleAppStateChange);
    if (Platform.OS === 'android') this.appFocusListener = AppState.addEventListener('focus', this.handleAppFocus);
    this.showKeyboardListener = Keyboard.addListener(keyboardShowEvent, this.handleKeyboardDidShow);
    this.hideKeyboardListener = Keyboard.addListener(keyboardHideEvent, this.handleKeyboardDidHide);
  }

  handleKeyboardDidShow: KeyboardEventListener = (e) => {
    this.setKeyboardVisible(true);
    this.setKeyboardHeight(e.endCoordinates.height);
  };

  handleKeyboardDidHide: KeyboardEventListener = () => {
    this.setKeyboardVisible(false);
    this.setKeyboardHeight(0);
  };

  get navigation() {
    return this._navigation?.current || undefined;
  }

  get navigationRef() {
    return this._navigation;
  }

  get currentRoute() {
    return this.navigation?.getCurrentRoute();
  }

  get currentScreen(): AppScreens {
    return this.currentRoute?.name as AppScreens;
  }

  get currentScreenParams() {
    return this.currentRoute?.params;
  }

  get isAppInActiveStatus() {
    return this.appStateStatus === 'active';
  }

  get isWideWebScreen() {
    return this.screenWidth > 860;
  }

  private handleAppStateChange = (status: AppStateStatus) => {
    this.app.logger.log(`App State: ${status}`);
    this.setAppStateStatus(status);
    if (status === 'active') this.processActiveAppStateDebounced(this);
  };

  private handleAppFocus = () => {
    // Android only
    this.processActiveAppStateDebounced(this);
  };

  setAppStateStatus(val: AppStateStatus) {
    this.appStateStatus = val;
  }

  setKeyboardHeight = (height: number) => {
    this.keyboardHeight = height;
  };

  setKeyboardVisible = (val: boolean) => {
    this.keyboardVisible = val;
  };

  setInsets = (insets: EdgeInsets) => {
    this.safeAreaInsets = insets;
  };

  setNavigationRef(ref: AppNavigationType) {
    this._navigation = ref;
  }

  setScreenWidth(val: number) {
    this.screenWidth = val;
  }

  processActiveAppState() {
    this.app.getTimerData();
  }

  dispose() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  destroy() {
    this.dispose();
    if (this.appStateListener) this.appStateListener.remove();
    if (this.showKeyboardListener) this.showKeyboardListener.remove();
    if (this.hideKeyboardListener) this.hideKeyboardListener.remove();
  }
}
