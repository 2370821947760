import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const getWelcomeTexts = makeHandler(
  'getWelcomeTexts',
  () => ({
    method: 'GET',
    path: `/WelcomeTexts?${getRequestFields()}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: WelcomeTextsResponse) => res.data.records,
);

export type WelcomeTextsResponse = IResponse<WelcomeTexts>;
export type WelcomeTextsResponseItem = IResponseItem<WelcomeTexts>;

export type WelcomeTexts = {
  text: string;
  language: 'ru' | 'en';
};

function getRequestFields() {
  const fields = ['text', 'language'];
  return objectToQueryParamString({'fields[]': fields});
}
