import React from 'react';
import {observer} from 'mobx-react-lite';
import {ActivityIndicator, ScrollView, StyleSheet, Text, TouchableOpacity, View, ViewStyle} from 'react-native';
import {expr} from 'mobx-utils';

import theme from '../theme';
import {strings} from '../locales/i18n';
import {appProvider} from '../appProvider';
import {TimeRecordForm} from '../models/timeRecordForm';
import OwnIcons from '../elements/OwnIcons';
import {FormInputBlock} from './FormInputBlock';
import {isNative} from '../constants/general';

type Props = {
  onClose: () => void;
  form: TimeRecordForm;
};

export const AgreementOrThreadSelector: React.FC<Props> = observer((props) => {
  const app = appProvider.application;
  const agList = app.model.agreementsList;
  const thList = app.model.threadsList;
  const loading = expr(() => agList.loading || thList.loading);

  const renderList = () => {
    if (!props.form.agListFiltered.length) return null;
    const ListComponent = isNative ? View : ScrollView;
    return (
      <ListComponent style={s.list}>
        {props.form.agListFiltered.map((el) => {
          const selected = el.id === props.form.agreementOrThreadId;
          const onSelect = () => {
            props.form.setAgreementOrThread(el);
            props.onClose();
          };
          return (
            <TouchableOpacity style={[s.item, selected ? s.selected : null]} onPress={onSelect} key={el.id}>
              <Text style={s.text}>{el.title}</Text>
              {selected ? <OwnIcons name="check" color={theme.bgColorPurple100} /> : null}
            </TouchableOpacity>
          );
        })}
      </ListComponent>
    );
  };

  return (
    <View style={styles[theme.stylesType].container}>
      <View style={styles[theme.stylesType].titleRow}>
        <Text style={s.back} onPress={props.onClose} numberOfLines={1} adjustsFontSizeToFit>
          {strings('common.cancel')}
        </Text>
        <Text style={s.text}>
          {strings('common.project')} / {strings('common.process')}
        </Text>
        <View style={s.spacer} />
      </View>
      <View style={s.searchBlock}>
        <FormInputBlock
          field={props.form.searchStringField}
          type="search"
          placeholder={strings('placeholder.search')}
        />
      </View>
      {loading ? (
        <ActivityIndicator color={theme.textColor} size={'large'} style={s.activityIndicator} />
      ) : (
        renderList()
      )}
    </View>
  );
});

interface ISelectorStyles {
  container: ViewStyle;
  titleRow: ViewStyle;
}

interface IStyles {
  [x: string]: ISelectorStyles;
}

const styles: IStyles = {
  web: {
    container: {
      flex: 1,
      width: '100%',
      maxWidth: theme.aligned(500),
      alignSelf: 'center',
      backgroundColor: theme.bgColorWeb,
    },
    titleRow: {
      backgroundColor: theme.bgColorWeb,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.space16,
    },
  },
  native: {
    container: {
      borderTopLeftRadius: theme.modalBorderRadius,
      borderTopRightRadius: theme.modalBorderRadius,
      backgroundColor: theme.bgColorDark300,
      padding: theme.space16,
      flex: 1,
    },
    titleRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.space12,
      marginBottom: theme.space32,
    },
  },
};

const s = StyleSheet.create({
  back: {
    width: theme.aligned(60),
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  text: {
    paddingRight: theme.space8,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    lineHeight: theme.space20,
    fontWeight: '500',
    flexShrink: 1,
  },
  spacer: {
    width: theme.aligned(60),
  },
  item: {
    paddingHorizontal: theme.space12,
    paddingVertical: theme.space12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  list: {
    backgroundColor: theme.bgColorDark400,
    borderRadius: theme.cardBorderRadius,
    paddingVertical: theme.space12,
    paddingHorizontal: theme.space12,
  },
  activityIndicator: {
    padding: theme.space24,
    alignSelf: 'center',
    flex: 1,
  },
  selected: {
    backgroundColor: theme.bgColorDark700,
    borderRadius: theme.cardBorderRadius,
  },
  searchBlock: {
    marginBottom: theme.space24,
  },
});
