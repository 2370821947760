import React, {ClassAttributes, ReactNode, useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TextInputProps,
  ViewStyle,
  TextStyle,
  StyleProp,
  LayoutAnimation,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';

import theme from '../theme';
import {IFormField} from '../models/formField';
import {dateToStringWithYear} from '../utils/formatters';
import OwnIcons from '../elements/OwnIcons';
import {isNative} from '../constants/general';

export type InputType = 'email' | 'pincode' | 'time' | 'date' | 'dummy' | 'textarea' | 'search';

export type TextareaProps = {
  numberOfLines?: number;
  multiline?: boolean;
  scrollEnabled?: boolean;
};

interface IProps {
  field: IFormField;
  type: InputType;
  autofocus?: boolean;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  disabled?: boolean;
  hint?: string;
  maxLength?: number;
  placeholder?: string;
  wrapperStyle?: ViewStyle;
  inputStyle?: TextStyle;
  rightIconStyle?: StyleProp<TextStyle>;
  renderIconBlock?: (secure?: boolean) => ReactNode;
  secureTextEntry?: boolean;
  minHeight?: number;
  onPress?: () => void;
  textarea?: TextareaProps;
  underlineStyle?: ViewStyle;
  hideErrors?: boolean;
}

export const FormInputBlock: React.FC<IProps> = observer((props: IProps) => {
  const showError = expr(() => !!props.field.error && props.field.isDirty);

  const hintBlock = () => {
    switch (true) {
      case showError && !props.hideErrors:
        return (
          <Text style={[styles.hintText, styles.errorText, props.type === 'pincode' ? styles.pinCodeError : null]}>
            {props.field.error}
          </Text>
        );
      case !!props.hint:
        return <Text style={styles.hintText}>{props.hint || ''}</Text>;
      default:
        return null;
    }
  };

  const underline = () => {
    if (props.type !== 'pincode') return null;
    const style = StyleSheet.flatten([styles.underline, props.underlineStyle, showError ? styles.warning : null]);
    return <View style={style} />;
  };

  useEffect(() => {
    if (!props.field.focused) {
      props.field.inputRef.current?.blur();
    }
  }, [props.field.inputRef, props.field.focused]);

  const onChangeText = (val: string) => {
    props.field.setValue(val);
  };

  const onBlur = () => {
    props.field.markAsDirty();
    props.field.setFocused(false);
    LayoutAnimation.easeInEaseOut();
  };

  const onFocus = () => {
    // props.field.setFocused(true);
    props.field.cleanAllErrors();
    // LayoutAnimation.easeInEaseOut();
  };

  const focusedStyle = expr(() => styles.focused);

  const inputStyles: StyleProp<TextStyle> = expr(() => {
    const value = props.field.value;
    const isNumeric = typeof value !== 'string';
    const style: TextStyle[] = [styles.input, {textAlign: props.type === 'pincode' ? 'center' : 'auto'}];
    if (props.field.focused) style.push(focusedStyle);
    if (props.inputStyle) style.push(props.inputStyle);
    if (props.renderIconBlock) style.push(styles.rightPadding);
    if (props.type === 'textarea') style.push(styles.textArea);
    if (props.type === 'search') style.push(styles.searchInputPadding);
    if (props.type === 'dummy') {
      style.push(styles.dummy);
      if (!isNative && props.field.height) style.push({height: props.field.height});
    }
    if (props.disabled) {
      style.push(styles.disabled);
    }
    if (props.type === 'pincode') {
      style.push(styles.pinCodeInputStyle);
      props.underlineStyle && style.push(props.underlineStyle);
      if (!isNumeric && value.length === 0) {
        style.push(styles.pinCodeEmpty);
      }
    } else {
      if ((isNumeric || value.length > 0) && !props.field.isValid) style.push(styles.errorBorder);
    }

    return style;
  });

  const inputProps: TextInputProps & ClassAttributes<TextInput> = {
    onBlur,
    onFocus,
    onChangeText,
    maxLength: props.maxLength,
    style: inputStyles,
    value: String(props.field.value),
    underlineColorAndroid: 'transparent',
    autoFocus: props.autofocus,
    placeholderTextColor: theme.textColorLight900,
    maxFontSizeMultiplier: 1,
    ref: props.field.inputRef,
    secureTextEntry: props.secureTextEntry,
    ...props.textarea,
  };

  let rightIconBlock: ReactNode = null;
  let leftIconBlock: ReactNode = null;
  if (props.placeholder) inputProps.placeholder = props.placeholder;
  const rightIconBlockStyle = [styles.rightIconBlock, props.rightIconStyle ? props.rightIconStyle : null];
  rightIconBlock = !!props.renderIconBlock && <View style={rightIconBlockStyle}>{props.renderIconBlock()}</View>;
  switch (props.type) {
    case 'email':
      inputProps.autoCapitalize = 'none';
      inputProps.autoCorrect = false;
      inputProps.keyboardType = 'email-address';
      rightIconBlock = !!props.field.value && props.field.focused && !!props.renderIconBlock && (
        <View style={rightIconBlockStyle}>{props.renderIconBlock()}</View>
      );
      break;
    case 'pincode':
      inputProps.autoCapitalize = 'none';
      inputProps.autoCorrect = false;
      inputProps.keyboardType = 'numeric';
      inputProps.maxLength = 6;
      break;
    case 'time':
      inputProps.autoCapitalize = 'none';
      inputProps.autoCorrect = false;
      inputProps.keyboardType = 'numeric';
      inputProps.maxLength = 5;
      rightIconBlock = !!props.renderIconBlock && <View style={rightIconBlockStyle}>{props.renderIconBlock()}</View>;
      break;
    case 'date':
      inputProps.editable = false;
      inputProps.value = props.disabled ? props.placeholder : dateToStringWithYear(props.field.value);
      rightIconBlock = !!props.renderIconBlock && <View style={rightIconBlockStyle}>{props.renderIconBlock()}</View>;
      break;
    case 'dummy':
      inputProps.editable = false;
      inputProps.multiline = true;
      inputProps.onContentSizeChange = (ev) => {
        props.field.setHeight(ev.nativeEvent.contentSize.height);
      };
      rightIconBlock = !!props.renderIconBlock && <View style={rightIconBlockStyle}>{props.renderIconBlock()}</View>;
      inputProps.onPressIn = () => onFocus();
      break;
    case 'textarea':
      inputProps.autoCapitalize = props.autoCapitalize || 'none';
      inputProps.autoCorrect = false;
      inputProps.keyboardType = 'default';
      if (props.textarea?.numberOfLines) inputProps.numberOfLines = props.textarea.numberOfLines;
      rightIconBlock = null;
      break;
    case 'search':
      inputProps.autoCapitalize = 'none';
      inputProps.keyboardType = 'default';
      rightIconBlock = !!props.renderIconBlock && <View style={rightIconBlockStyle}>{props.renderIconBlock()}</View>;
      leftIconBlock = (
        <View style={styles.leftIconBlock}>
          <OwnIcons style={styles.searchIcon} name="search" />
        </View>
      );
      break;
  }
  if (props.disabled) {
    inputProps.editable = false;
  }

  const renderTouchBlock = () => {
    if (!props.onPress) return null;
    if (props.type !== 'dummy' && props.type !== 'date') return null;
    const onPress = () => {
      if (props.onPress) props.onPress();
      onBlur();
    };
    return (
      <TouchableOpacity
        style={styles.dummyTouch}
        onPressIn={() => onFocus()}
        onPressOut={onPress}
        disabled={props.disabled}
      />
    );
  };

  return (
    <View style={[styles.wrapper, props.wrapperStyle]}>
      <View style={styles.row}>
        <View style={styles.wrapperInputIcons}>
          {leftIconBlock}
          {React.createElement(TextInput, inputProps)}
          {rightIconBlock}
        </View>
      </View>
      {underline()}
      {hintBlock()}
      {renderTouchBlock()}
    </View>
  );
});

const styles = StyleSheet.create({
  input: {
    fontSize: theme.fontSize16,
    borderColor: theme.bgColorDark400,
    backgroundColor: theme.bgColorDark400,
    borderWidth: theme.pt,
    borderRadius: theme.cardBorderRadius,
    fontFamily: theme.fontFamilyMain,
    paddingTop: Platform.OS === 'android' ? theme.space8 : theme.space12,
    paddingBottom: Platform.OS === 'android' ? theme.space8 : theme.space12,
    paddingHorizontal: theme.space12,
    color: theme.textColor,
    flex: 1,
    textAlignVertical: 'center',
  },
  dummy: {
    lineHeight: theme.aligned(20),
  },
  inputError: {
    borderColor: theme.bgColorRed100,
  },
  focused: {
    borderColor: theme.bgColorPurple100,
  },
  errorBorder: {
    borderColor: theme.bgColorRed100,
  },
  errorText: {
    fontFamily: theme.fontFamilyMain,
    fontSize: theme.fontSize14,
    lineHeight: theme.space16,
    fontWeight: '500',
    color: theme.bgColorRed100,
    marginTop: theme.aligned(8),
  },
  hintText: {
    fontFamily: theme.fontFamilyMain,
    fontSize: theme.fontSize14,
    lineHeight: theme.space16,
    fontWeight: '500',
    color: theme.textColor,
    marginTop: theme.aligned(8),
  },
  wrapper: {
    alignSelf: 'stretch',
  },
  leftIconBlock: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    justifyContent: 'center',
  },
  rightIconBlock: {
    position: 'absolute',
    right: theme.space12,
    justifyContent: 'center',
    zIndex: 99,
    top: 0,
    bottom: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperInputIcons: {
    flex: 1,
    flexDirection: 'row',
  },
  pinCodeInputStyle: {
    backgroundColor: isNative ? theme.bgColorDark100 : 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    lineHeight: theme.aligned(40),
    fontSize: theme.fontSize32,
    fontWeight: '500',
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.aligned(20),
    marginLeft: Platform.OS === 'ios' ? theme.aligned(20) : 0,
  },
  underline: {
    height: theme.pt,
    backgroundColor: theme.bgColorPurple100,
    width: theme.windowWidth - theme.aligned(56) * 2,
    alignSelf: 'center',
  },
  pinCodeEmpty: {
    marginLeft: 0,
  },
  pinCodeError: {
    marginLeft: theme.aligned(40),
  },
  warning: {
    backgroundColor: theme.bgColorRed100,
  },
  rightPadding: {
    paddingRight: theme.aligned(20),
  },
  textArea: {
    minHeight: theme.aligned(80),
    paddingTop: theme.space12,
    paddingBottom: theme.aligned(20),
    textAlignVertical: 'top',
    lineHeight: theme.fontSize18,
  },
  searchIcon: {
    color: theme.textColorLight900,
    paddingLeft: theme.space12,
    fontSize: theme.fontSize18,
  },
  searchInputPadding: {
    paddingLeft: theme.aligned(36),
  },
  dummyTouch: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
  },
  disabled: {
    color: theme.textColorLight900,
  },
});
