import React, {useEffect} from 'react';
import {Platform, StatusBar, StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import {Screen} from '../elements/Screen';
import theme from '../theme';
import {NavigationScreenProps} from '../Routes';
import {AppScreens} from '../constants/screens';
import {EmailEnterForm} from '../components/EmailEnterForm';
import {PinCodeEnterForm} from '../components/PinCodeEnterForm/PinCodeEnterForm';
import {RadialGradientBlock} from '../elements/RadialGradientBlock';
import {AndroidStatusBar} from '../elements/AndroidStatusBar';
import {isNative} from '../constants/general';

type Props = NavigationScreenProps<AppScreens.LoginScreen>;

export const LoginScreen: React.FC<Props> = observer((props) => {
  const ctrl = props.route.params.ctrl;

  useEffect(() => {
    if (Platform.OS === 'ios') return;
    setTimeout(() => {
      // this is a hack for android
      // TODO find more clean solution
      StatusBar.setBackgroundColor(theme.bgColorDark100);
    }, 1000);
  });

  const renderGradientBlock = () => {
    if (!ctrl.isEmailStep) return null;
    return (
      <View style={s.gradientBlock}>
        <RadialGradientBlock width={theme.windowWidth} height={theme.aligned(400)} />
      </View>
    );
  };
  return (
    <Screen safearea style={s.screen}>
      <AndroidStatusBar backgroundColor={theme.bgColorDark100} />
      {renderGradientBlock()}
      {ctrl.isEmailStep ? <EmailEnterForm ctrl={ctrl} /> : <PinCodeEnterForm ctrl={ctrl} />}
    </Screen>
  );
});

const s = StyleSheet.create({
  screen: {
    backgroundColor: isNative ? theme.bgColorDark100 : theme.bgColorWeb,
  },
  content: {
    backgroundColor: theme.bgColorDark100,
    flex: 1,
  },
  spacer: {
    height: theme.space16,
  },
  gradientBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});
