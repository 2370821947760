import React from 'react';
import {StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import {ModalController} from '../models/modalsController';
import {ModalEntry} from '../models/modalEntry';
import {isNative} from '../constants/general';

type Props = {
  ctrl: ModalController;
  entry: ModalEntry;
};

export const ModalEntryView: React.FC<Props> = observer((props) => {
  if (!props.ctrl.isVisible) return null;
  if (props.ctrl.canHideLastEntry && !isNative) return <>{props.entry.render(props.entry)}</>;
  const style = [styles.entry, props.entry.type === 'dialog' ? styles.centered : null];
  return (
    <View key={props.entry.id} style={style}>
      {props.entry.render(props.entry)}
    </View>
  );
});

const styles = StyleSheet.create({
  entry: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
