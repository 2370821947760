import React from 'react';
import {observer} from 'mobx-react-lite';
import {Screen} from '../elements/Screen';
import {HeaderBlock} from '../elements/HeaderBlock';
import {StyleSheet} from 'react-native';
import {LayoutRectangle} from 'react-native/Libraries/Types/CoreEventTypes';
import theme from '../theme';
import {appProvider} from '../appProvider';
import {DailyTaskSelector} from '../components/DailyTaskSelector';
import {NavigationScreenProps} from '../Routes';
import {AppScreens} from '../constants/screens';

type Props = NavigationScreenProps<AppScreens.DailyEditScreen>;

export const DailyEditScreen: React.FC<Props> = observer((props) => {
  const {record} = props.route.params;
  if (!record) return null;
  const app = appProvider.application;
  const onLayout = (layout: LayoutRectangle) => {
    app.model.appCtrl?.setScreenWidth(layout.width);
  };

  return (
    <Screen safearea style={s.screen} onLayout={onLayout}>
      <HeaderBlock />
      <DailyTaskSelector record={record} />
    </Screen>
  );
});

const s = StyleSheet.create({
  screen: {
    backgroundColor: theme.bgColorWeb,
  },
});
