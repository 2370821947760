import {makeAutoObservable} from 'mobx';
import {formatISO, isToday, isYesterday} from 'date-fns';

import {TimeRecord} from './timeRecord';
import {ProxyField, ProxyFieldValidator} from './formField';
import {strings} from '../locales/i18n';
import {Root} from './root';
import * as api from '../api';
import {formatDuration, formatTimeToDurationInSeconds} from '../utils/formatters';
import {AddTimeRecordConfig, CallbackFunction, TimeRecordType} from '../types';
import {AsanaTask} from './asanaTask';
import {middleOfDay} from '../utils/dateUtils';

export class TimeRecordForm {
  model: Root;
  record: TimeRecord | null = null;
  recordId: string = '';
  timeField: ProxyField;
  time: string = '01:00';
  dateField: ProxyField;
  date: number = Date.now();
  agreementOrThreadField: ProxyField;
  agreementId: string = '';
  taskField: ProxyField;
  asanaTask: AsanaTask | null = null;
  threadId: string = '';
  commentField: ProxyField;
  comment: string = '';
  searchStringField: ProxyField;
  searchString: string = '';
  showDatePicker: boolean = false;
  dateLocked: boolean = false;

  loading: boolean = false;
  afterAddAction: CallbackFunction | null = null;

  constructor(root: Root, record?: TimeRecord) {
    this.model = root;

    this.timeField = new ProxyField({
      getter: () => this.time,
      setter: (val) => this.setTime(val as string),
      validator: this.validateTime,
    });
    this.dateField = new ProxyField({
      getter: () => this.date,
      setter: (val) => this.setDate(val as number),
      validator: this.validateDate,
    });

    this.agreementOrThreadField = new ProxyField({
      getter: () => this.agreement?.title || this.thread?.title || '',
      setter: () => false,
      validator: this.validateNotEmpty,
    });
    this.taskField = new ProxyField({
      getter: () => this.asanaTask?.name || '',
      setter: () => false,
      validator: this.validateNotEmpty,
    });
    this.commentField = new ProxyField({
      getter: () => this.comment,
      setter: (val) => this.setComment(val as string),
    });

    this.searchStringField = new ProxyField({
      getter: () => this.searchString,
      setter: (val) => this.setSearchString(val as string),
    });
    this.processRecord(record);
    if (!record && root.timeRecordsList.lastAddedAgreementOrThread) {
      this.setAgreementOrThread(root.timeRecordsList.lastAddedAgreementOrThread, true);
    }
    makeAutoObservable(this);
  }

  get agreementOrThreadId() {
    return this.agreementId || this.threadId;
  }

  get agreement() {
    return this.model.agreementsList.byId.get(this.agreementId);
  }

  get recordDate() {
    // record time with fixed time 12:00AM
    return middleOfDay(this.date);
  }

  get datePlaceholder() {
    if (!this.dateLocked) return '';
    switch (true) {
      case isYesterday(this.recordDate):
        return strings('common.yesterday');
      case isToday(this.recordDate):
        return strings('common.today');
      default:
        return '';
    }
  }

  get thread() {
    return this.model.threadsList.byId.get(this.threadId);
  }

  get agreementsAndThreadsList() {
    const agreements = this.model.agreementsList.list
      .map((el) => ({
        id: el.id,
        title: el.title,
        type: TimeRecordType.Project,
      }))
      .sort(sortByTitle);
    const threads = this.model.threadsList.list
      .map((el) => ({
        id: el.id,
        title: el.title,
        type: TimeRecordType.Process,
      }))
      .sort(sortByTitle);
    return [...agreements, ...threads];
  }

  get dateFromTimestamp() {
    return new Date(this.date);
  }

  get record4export(): api.TimeRecord4Export {
    const res: api.TimeRecord4Export = {
      record_date: formatISO(this.recordDate),
      team_grid_user: [this.model.session.userId],
      comment: this.comment,
      hours_spent: formatTimeToDurationInSeconds(this.time),
      asana_task_gid: '',
      asana_task_name: '',
      asana_subtask_gid: '',
      asana_subtask_name: '',
    };
    if (this.asanaTask?.parent) {
      res.asana_task_gid = this.asanaTask.parent.id || '';
      res.asana_task_name = this.asanaTask.parent.name || '';
      res.asana_subtask_gid = this.asanaTask.id || '';
      res.asana_subtask_name = this.asanaTask.name || '';
    } else {
      res.asana_task_gid = this.asanaTask?.id || '';
      res.asana_task_name = this.asanaTask?.name || '';
    }
    if (this.agreementId) {
      res.agreement_grid_item = [this.agreementId];
    } else {
      res.thread_grid_item = [this.threadId];
    }
    return res;
  }

  get hasChanges() {
    if (!this.record) return true;
    return (
      this.record.recordDate !== this.date ||
      this.record.agreementId !== this.agreementId ||
      this.record.threadId !== this.threadId ||
      this.record.comment !== this.comment ||
      this.record.asanaTaskId !== (this.asanaTask?.id || '') ||
      formatTimeToDurationInSeconds(this.time) !== this.record.hoursSpent
    );
  }

  get isValid() {
    return (
      this.timeField.isValid && this.agreementOrThreadField.isValid && this.taskField.isValid && this.dateField.isValid
    );
  }

  get isEdit() {
    return Boolean(this.recordId);
  }

  get searchStringToCompare() {
    return this.searchString.trim().toLowerCase();
  }

  get agListFiltered() {
    if (!this.searchString) return this.agreementsAndThreadsList;
    return this.agreementsAndThreadsList.filter((el) => {
      const compareTitle = el.title?.trim()?.toLowerCase() || '';
      return compareTitle.indexOf(this.searchStringToCompare) > -1;
    });
  }

  get asanaProjectId(): string {
    return this.agreement?.asanaId || this.thread?.asanaId || '';
  }

  configure(cfg: AddTimeRecordConfig) {
    this.setDate(cfg.date);
    if (cfg.lockDate) {
      this.setDateLocked();
      if (isToday(cfg.date)) {
        this.setTime('00:00');
      }
    }
    if (cfg.afterAddAction) {
      this.setAfterAddAction(cfg.afterAddAction);
    }
  }

  markAsDitry() {
    this.agreementOrThreadField.markAsDirty();
    this.timeField.markAsDirty();
    this.dateField.markAsDirty();
    this.taskField.markAsDirty();
  }

  setTime(val: string) {
    this.time = adaptTimeValue(val);
  }

  setDate(val: number) {
    this.date = val;
    if (!this.isEdit && this.record) this.record.recordDate = val;
  }

  setDateLocked() {
    this.dateLocked = true;
  }

  setDateFromPicker = (date: Date) => {
    this.setDate(date.getTime());
    this.hideDatePicker();
  };

  setAgreementOrThread(val: {id: string; type: TimeRecordType}, initMode?: boolean) {
    switch (val.type) {
      case TimeRecordType.Project:
        if (this.agreementId !== val.id) this.resetTask();
        this.agreementId = val.id;
        this.threadId = '';
        if (initMode && this.record) {
          this.record.agreementId = val.id;
          this.record.threadId = '';
        }
        break;
      case TimeRecordType.Process:
        if (this.threadId !== val.id) this.resetTask();
        this.threadId = val.id;
        this.agreementId = '';
        if (initMode && this.record) {
          this.record.agreementId = '';
          this.record.threadId = val.id;
        }
        break;
    }
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

  setAfterAddAction(action: CallbackFunction) {
    this.afterAddAction = action;
  }

  resetTask() {
    this.asanaTask = null;
    this.taskField.markAsClean();
  }

  setTask(task: AsanaTask) {
    this.asanaTask = task;
  }

  setComment(val: string) {
    this.comment = val;
  }

  setSearchString(val: string) {
    this.searchString = val;
  }

  clearSearch() {
    this.searchString = '';
  }

  setShowDatePicker(val: boolean) {
    this.showDatePicker = val;
  }

  hideDatePicker = () => {
    this.setShowDatePicker(false);
    this.dateField.setFocused(false);
  };

  processRecord(record?: TimeRecord) {
    if (!record) {
      this.processEmptyRecord();
    } else {
      this.record = record.clone();
      this.recordId = record.id;
      this.time = formatDuration(record.hoursSpent);
      this.date = record.recordDate;
      this.agreementId = record.agreementId;
      this.threadId = record.threadId;
      this.comment = record.comment;
      this.processTask(record);
    }
  }

  processEmptyRecord() {
    this.record = new TimeRecord();
    this.record.hoursSpent = formatTimeToDurationInSeconds(this.time);
    this.record.recordDate = this.date;
  }

  processTask(record: TimeRecord) {
    if (record.asanaSubTaskId && record.asanaSubTaskName) {
      const task: api.AsanaTask = {
        gid: record.asanaSubTaskId,
        name: record.asanaSubTaskName,
      };
      if (record.asanaTaskId && record.asanaTaskName) {
        task.parent = {
          gid: record.asanaTaskId,
          name: record.asanaTaskName,
        };
      }
      this.setTask(new AsanaTask(this.model, task));
    } else if (record.asanaTaskId && record.asanaTaskName) {
      this.setTask(new AsanaTask(this.model, {gid: record.asanaTaskId, name: record.asanaTaskName}));
    }
  }

  resetForm() {
    this.time = formatDuration(1);
    this.date = Date.now();
    this.agreementId = '';
    this.threadId = '';
    this.commentField.setValue('');
    this.asanaTask = null;
  }

  validateTime: ProxyFieldValidator = (val) => {
    const stringVal = val as string;
    if (!stringVal || stringVal.length < 4) return strings('error.invalid.value');
    return undefined;
  };

  validateDate: ProxyFieldValidator = (val) => {
    if (!val) return strings('error.invalid.value');
    return undefined;
  };

  validateNotEmpty: ProxyFieldValidator = (val) => {
    //if (!this.asanaProjectId) return strings('error.no.project.selected');
    if (!val) return strings('error.invalid.value');
    return undefined;
  };
}

export function adaptTimeValue(val: string) {
  const valueArr = val.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
  const value = valueArr ? `${valueArr[1]}:${valueArr[2]}` : '';
  const hours = value.split(':')[0] || '';
  let newHours = '';
  const minutes = value.split(':')[1] || '';
  let newMinutes = '';
  if (hours.length === 1 && parseInt(hours) > 2) {
    newHours = `0${hours}`;
  } else if (parseInt(hours) >= 23) {
    newHours = '23';
  } else {
    newHours = `${hours}`;
  }
  if (minutes.length === 1 && parseInt(minutes) > 5) {
    newMinutes = `0${minutes}`;
  } else {
    newMinutes = `${minutes}`;
  }
  if (newMinutes) {
    return `${newHours}:${newMinutes}`;
  } else {
    return `${newHours}`;
  }
}

const sortByTitle = (a: {title: string}, b: {title: string}) => {
  return a.title.localeCompare(b.title, 'en', {numeric: true});
};
