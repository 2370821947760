import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Pressable, Text, View} from 'react-native';

import {appProvider} from '../appProvider';
import theme from '../theme';
import {isNative} from '../constants/general';

export const SnacksProvider = observer(() => {
  const ctrl = appProvider.application.model.snacksCtrl;
  useEffect(() => () => ctrl.destroy(), [ctrl]);
  if (!ctrl.visible) return null;

  return (
    <Pressable style={[StyleSheet.absoluteFill, s.container]} onPress={ctrl.hide}>
      <View style={s.snackBlock}>
        <Text style={s.text}>{ctrl.message}</Text>
      </View>
    </Pressable>
  );
});

const s = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.aligned(56),
    flexDirection: 'row',
  },
  snackBlock: {
    justifyContent: 'space-between',
    borderRadius: theme.cardBorderRadius,
    backgroundColor: isNative ? theme.bgColorDark400 : theme.bgColorDark200Web,
    padding: theme.space24,
    minWidth: '50%',
    maxWidth: theme.aligned(500),
  },
  text: {
    fontWeight: '600',
    fontFamily: theme.fontFamilyMain,
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(20),
    color: theme.textColor,
    textAlign: 'center',
  },
});
