import {makeAutoObservable} from 'mobx';

const HIDE_TIMEOUT = 2000;

export class SnacksController {
  message: string = '';
  timeoutHandle: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get visible() {
    return Boolean(this.message);
  }

  show(message: string) {
    this.clearTimeout();
    this.message = message;
    this.timeoutHandle = setTimeout(this.hide, HIDE_TIMEOUT);
  }

  hide = () => {
    this.message = '';
  };

  clearTimeout() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  destroy() {
    this.clearTimeout();
  }
}
