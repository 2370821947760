import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';

import OwnIcons, {IconName} from './OwnIcons';
import theme from '../theme';

type Props = {
  onPress?: () => void;
  icon: IconName;
  iconsSize?: number;
  isCircular?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
};
export const IconButton: React.FC<Props> = (props) => {
  const style = StyleSheet.flatten([
    props.isCircular ? s.circular : s.squared,
    props.disabled ? s.disabled : null,
    props.backgroundColor ? {backgroundColor: props.backgroundColor} : null,
  ]);
  const iconColor = props.disabled ? theme.textColorLight700 : theme.bgColorLight300;
  return (
    <TouchableOpacity style={style} onPress={props.onPress} disabled={!props.onPress || props.disabled}>
      <OwnIcons name={props.icon} size={props.iconsSize ? props.iconsSize : theme.space8} color={iconColor} />
    </TouchableOpacity>
  );
};

const s = StyleSheet.create({
  circular: {
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.space24,
    width: theme.space24,
    borderRadius: theme.space12,
    backgroundColor: theme.bgColorDark400,
  },
  squared: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.space8,
    backgroundColor: theme.bgColorDark500,
    height: theme.aligned(36),
    width: theme.aligned(36),
  },
  disabled: {
    backgroundColor: theme.bgColorDark300,
  },
});
