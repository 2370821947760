import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

import theme from '../theme';
import {appProvider} from '../appProvider';
import {strings} from '../locales/i18n';

export const TodayLink: React.FC = observer(() => {
  const app = appProvider.application;
  if (app.model.weekFilter.isCurrentWeek) return null;
  const onPress = () => {
    app.model.weekFilter.reset();
    app.getTimeRecords(app.model.weekFilter);
  };

  return (
    <TouchableOpacity onPress={onPress} style={s.link}>
      <Text style={s.text}>{strings('common.today')}</Text>
    </TouchableOpacity>
  );
});

const s = StyleSheet.create({
  link: {
    paddingHorizontal: theme.space12,
  },
  text: {
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    lineHeight: theme.aligned(20),
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
});
