import {StyleProp, StyleSheet, Text, TextStyle, View} from 'react-native';
import React from 'react';
import {observer} from 'mobx-react-lite';

import theme from '../theme';
import {IFormField} from '../models/formField';

type Props = {
  labelText: string;
  field: IFormField;
  maxLength?: number;
  warning?: boolean;
  disabled?: boolean;
};

export const FormInputLabel: React.FC<Props> = observer((props) => {
  const renderMaxLengthBlock = () => {
    if (!props.maxLength) return null;
    const value = String(props.field.value);
    const lengthStyle: StyleProp<TextStyle> = [styles.text, styles.maxLength];
    return (
      <Text style={lengthStyle}>
        {value.length}/{props.maxLength}
      </Text>
    );
  };

  return (
    <View style={styles.row}>
      <Text maxFontSizeMultiplier={1.5} style={[styles.text, props.disabled ? styles.disabled : null]}>
        {props.labelText} {props.warning ? <Text style={styles.warning}>*</Text> : null}
      </Text>
      <View style={styles.spacer} />
      {renderMaxLengthBlock()}
    </View>
  );
});

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginBottom: theme.space8,
    justifyContent: 'space-between',
  },
  spacer: {
    flex: 1,
  },
  warning: {
    color: theme.bgColorRed100,
  },
  text: {
    fontFamily: theme.fontFamilyMain,
    fontSize: theme.fontSize14,
    lineHeight: theme.space16,
    fontWeight: '500',
    flexShrink: 1,
    color: theme.textColor,
  },
  maxLength: {
    flexWrap: 'nowrap',
    color: theme.textColorSecondary,
    minWidth: theme.aligned(50),
    textAlign: 'right',
  },
  disabled: {
    color: theme.textColorLight900,
  },
});
