import Application from './application';

class AppProvider {
  private _application: Application | undefined = undefined;

  get application(): Application {
    if (!this._application) throw new Error('Application was not registered');
    return this._application;
  }

  setApplication(app: Application | undefined) {
    if (this._application) {
      this._application.dispose();
    }
    this._application = app;
  }
}

export const appProvider = new AppProvider();
