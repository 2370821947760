import NetInfo, {NetInfoState, NetInfoSubscription} from '@react-native-community/netinfo';
import {makeAutoObservable} from 'mobx';

export default class NetInfoController {
  private _isInternetReachable: boolean = true;
  private netInfoDisposer: NetInfoSubscription;
  awaitUpdateListener: boolean = false;
  tryAgainTimer: ReturnType<typeof setTimeout> | null = null;
  onCloseModal: () => void = () => false;

  constructor() {
    makeAutoObservable(this);
    this.netInfoDisposer = NetInfo.addEventListener(this.handleNetInfoChangeState);
  }

  updateListener() {
    this.netInfoDisposer = NetInfo.addEventListener(this.handleNetInfoChangeState);
    this.setAwaitUpdateListener(false);
    if (this.isInternetReachable) {
      this.onCloseModal();
    }
  }

  get isInternetReachable(): boolean {
    return this._isInternetReachable;
  }

  setIsInternetReachable(val: boolean | null) {
    if (val === null) return;
    this._isInternetReachable = val;
  }

  forceSetIsInternetReachable = () => {
    this.setIsInternetReachable(true);
    this.setAwaitUpdateListener(true);
    this.tryAgainTimer = setTimeout(() => {
      this.updateListener();
    }, 3000);
  };

  private setAwaitUpdateListener(val: boolean) {
    this.awaitUpdateListener = val;
  }

  private handleNetInfoChangeState = (state: NetInfoState) => {
    this.setIsInternetReachable(state.isInternetReachable);
  };

  setOnCloseModal(func: () => void) {
    this.onCloseModal = () => {
      func();
      this.clearOnCloseModal();
    };
  }

  clearOnCloseModal() {
    this.onCloseModal = () => false;
  }

  destroy() {
    if (this.netInfoDisposer) this.netInfoDisposer();
    if (this.tryAgainTimer) clearTimeout(this.tryAgainTimer);
    this.clearOnCloseModal();
  }
}
