import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const createSessionRequest = makeHandler(
  'createSessionRequest',
  (email: string) => ({
    method: 'POST',
    path: `/Sessions`,
    data: {records: [{fields: {email: email}}]},
    apiType: RequestApiType.AirTable,
  }),
  (res: SessionResponse) => res.data.records,
);

export const getSessionById = makeHandler(
  'getSessionById',
  (id: string) => ({
    method: 'GET',
    path: `/Sessions/${id}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: {data: SessionResponseItem}) => res.data.fields,
);

export const checkUserByEmail = makeHandler(
  'checkUserByEmail',
  (email: string) => ({
    method: 'GET',
    path: `/TeamGrid?${checkEmailParams(email)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: CheckUserResponse) => res.data?.records[0],
);

export const setSessionToken = makeHandler(
  'setSessionToken',
  (req: {token: string; id: string}) => ({
    method: 'PATCH',
    path: `/Sessions`,
    data: {records: [{id: req.id, fields: {token: req.token}}]},
    apiType: RequestApiType.AirTable,
  }),
  (res: SessionResponse) => res.data.records,
);

export interface ISession {
  email: string;
  token: string;
  last_modified: string;
  created_time: string;
  verification_code?: string;
}

export type SessionResponse = IResponse<ISession>;
export type SessionResponseItem = IResponseItem<ISession>;

export type CheckUserResponse = IResponse<TimeGridUser>;
export type CheckUserResponseItem = IResponseItem<TimeGridUser>;

export type TimeGridUser = {
  email: string;
  velocity_in_weeks: number;
  week_hours?: number;
  first_name: string;
  name: string;
};

enum TeamGridStatus {
  Active = 'Active',
  Dismissal = 'Dismissal',
  AwaitingPayment = 'Awaiting Payment',
  Out = 'Out',
}

function checkEmailParams(email: string) {
  const fields = ['email', 'velocity_in_weeks', 'week_hours', 'first_name', 'name'];
  return objectToQueryParamString({
    'fields[]': fields,
    filterByFormula: `AND({email} = "${email}", NOT({active} = "${TeamGridStatus.Out}"))`,
  });
}
