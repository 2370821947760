import {
  AIRTABLE_API_TOKEN,
  AIRTABLE_API_TOKEN_DEV,
  AIRTABLE_API_URL,
  AIRTABLE_API_URL_DEV,
  ASANA_API_TOKEN,
  ASANA_API_URL,
} from '../constants/general';

export type Request = {
  /** HTTP method */
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  /** path */
  path: string;
  /** JSON data */
  data?: object;
  /** Any additional headers */
  headers?: Record<string, string>;
  apiType: RequestApiType;
};

export interface IResponse<t> {
  data: {
    records: IResponseItem<t>[];
    offset?: string;
  };
}

export interface IResponseItem<t> {
  id: string;
  fields: t;
}

export enum RequestApiType {
  AirTable = 'AirTable',
  AirTableDev = 'AirTableDev',
  Asana = 'Asana',
}

export const ApiUrls = {
  [RequestApiType.AirTable]: AIRTABLE_API_URL,
  [RequestApiType.AirTableDev]: AIRTABLE_API_URL_DEV,
  [RequestApiType.Asana]: ASANA_API_URL,
};

export const ApiTokens = {
  [RequestApiType.AirTable]: AIRTABLE_API_TOKEN,
  [RequestApiType.AirTableDev]: AIRTABLE_API_TOKEN_DEV,
  [RequestApiType.Asana]: ASANA_API_TOKEN,
};
