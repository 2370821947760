import i18next from 'i18next';
import * as RNLocalize from 'react-native-localize';

import memoize from '../utils/memoize';
import {TranslationsKey} from '../types';

import en from './en';
import ru from './ru';
export const AVAILABLE_LANGS: any = {en, ru};
export let locale: string = 'en';

type TemplatesConfig = Record<string, string>;

export const strings = memoize(
  (key: TranslationsKey, config: TemplatesConfig) => i18next.t(key as string, config),
  (key: string, config: any) => (config ? key + JSON.stringify(config) : key),
);

export const findBestAvailableLanguage = () => {
  const fallback = {languageTag: 'en', isRTL: false};
  const {languageTag} = RNLocalize.findBestAvailableLanguage(Object.keys(AVAILABLE_LANGS)) || fallback;
  locale = languageTag;
  return languageTag;
};

export const setI18nConfig = () => {
  const languageTag = findBestAvailableLanguage();
  i18next.init({
    compatibilityJSON: 'v3',
    lng: languageTag,
    debug: __DEV__,
    resources: {
      [languageTag]: {
        translation: AVAILABLE_LANGS[languageTag],
      },
    },
    initImmediate: true,
  });

  // clear translation cache
  // @ts-ignore // TODo fix me
  strings.cache.clear();
};
