import React from 'react';

type Props = {
  width: number;
  height: number;
};

export const RadialGradientBlock: React.FC<Props> = () => {
  // TODO
  return null;
};
