import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  isSaturday,
  isSunday,
  isThisWeek,
  startOfWeek,
  subWeeks,
} from 'date-fns';

import {makeAutoObservable} from 'mobx';
import {Root} from './root';
import {formatAirTableParsedDate, formatAirTableParsedDateWithTime} from '../utils/formatters';

export class WeekFilter {
  model: Root;
  currentDate = Date.now();

  constructor(root: Root, currDate?: number) {
    makeAutoObservable(this);
    if (currDate) {
      this.currentDate = currDate;
    }
    this.model = root;
  }

  get userHoursAvailable() {
    const weekdays = eachDayOfInterval({start: this.startOfWeek, end: this.endOfWeek});
    let hours = this.model.user.availableWorkHours;
    const holidays = this.model.holidays;
    const hoursPerDay = this.model.user.hoursPerDay;
    for (const day of weekdays) {
      switch (true) {
        case this.isWorkDay(day):
          if (holidays.checkHoliday(day) || this.model.user.checkAbsentDay(day)) {
            hours = hours - hoursPerDay;
          }
          break;
        case !this.isWorkDay(day) && holidays.checkAntiHoliday(day):
          hours = hours + hoursPerDay;
          break;
      }
    }
    return Math.round(hours * 100) / 100;
  }

  isWorkDay(date: Date | number) {
    return !isSunday(date) && !isSaturday(date);
  }

  get startOfWeek() {
    return startOfWeek(this.currentDate, {weekStartsOn: 1});
  }

  get endOfWeek() {
    return endOfWeek(this.currentDate, {weekStartsOn: 1});
  }

  get isCurrentWeek() {
    return isThisWeek(this.currentDate, {weekStartsOn: 1});
  }

  get airTableFilter(): string {
    const isBefore = `IS_BEFORE({record_date}, ${formatAirTableParsedDateWithTime(this.endOfWeek)})`;
    const isAfter = `IS_AFTER({record_date}, ${formatAirTableParsedDate(this.startOfWeek)})`;
    return `${isAfter}, ${isBefore}`;
  }

  toNext = () => {
    if (this.isCurrentWeek) return;
    this.currentDate = addWeeks(this.currentDate, 1).getTime();
  };

  toPrev = () => {
    this.currentDate = subWeeks(this.currentDate, 1).getTime();
  };

  reset = () => {
    this.currentDate = Date.now();
  };
}
