import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Text, Pressable, View, TouchableOpacity} from 'react-native';
import {expr} from 'mobx-utils';
import {isToday, isYesterday} from 'date-fns';

import theme from '../theme';
import {TimeRecord} from '../models/timeRecord';
import {appProvider} from '../appProvider';
import {strings} from '../locales/i18n';
import {IMenuItem, IModalConfirmConfig} from '../types';
import {TimeRecordsRow} from './TimeRecordsRow';
import {BlinkingDot} from '../elements/BlinkingDot';
import {FormInputBlock} from './FormInputBlock';
import {IconButton} from '../elements/IconButton';
import {formatTimeToDurationInSeconds} from '../utils/formatters';
import {isWeb} from 'react-native-modalize/lib/utils/devices';

type Props = {
  record: TimeRecord;
  deleteAction?: IMenuItem;
  editable?: boolean;
};

export const TimeRecordsItem: React.FC<Props> = observer((props) => {
  const app = appProvider.application;

  const isActiveTimerRecord = expr(() => app.model.timer.recordId === props.record.id);

  const onEdit = () => {
    app.navigateToEditTimeRecord(props.record);
    app.modal.hideLastEntry();
  };

  const onDeletePress = () => {
    const {title, subTitle} = props.record;
    const config: IModalConfirmConfig = {
      onConfirm: onDelete,
      title: `${strings('action.entry.delete')}?`,
      text: `${title ? title + '\n' : ''}${subTitle || ''}`,
      confirmText: strings('common.delete'),
    };
    app.showConfirmModal(config);
  };

  const onDelete = () => {
    const rec = props.record;
    app.deleteTimeRecord(rec.id);
    if (isToday(rec.recordDate)) {
      app.deleteTimeRecordFromDaily(app.model.dailyCtrl.today, rec.id);
    }
    if (isYesterday(rec.recordDate)) {
      app.deleteTimeRecordFromDaily(app.model.dailyCtrl.yesterday, rec.id);
    }
  };

  const continueTrackingAction = () => app.continueTracking(props.record);

  const onContinueTracking = () => {
    const timer = app.model.timer;
    if (!timer.isActive) {
      continueTrackingAction();
      return;
    }
    const config: IModalConfirmConfig = {
      onConfirm: continueTrackingAction,
      contentRenderer: renderContinueTrackingConfirmBlock,
      buttons: [
        {isConfirm: true, isWarning: true, text: strings('action.yes.stop'), action: continueTrackingAction},
        {text: strings('action.no.cancel'), action: () => app.modal.hideLastEntry()},
      ],
    };
    app.showConfirmModal(config);
  };

  const saveTime = () => {
    const field = props.record.timeField;
    if (!field) return;
    field.markAsDirty();
    if (!field.isValid) return;
    props.record.setLoading(true);
    app
      .updateTimeRecord(props.record.id, {hours_spent: formatTimeToDurationInSeconds(props.record.time)})
      .finally(() => props.record.setLoading(false));
    props.record.setTimeEditMode(false);
  };

  const onPress = () => {
    props.record.setTimeEditMode(false);
    const menuItems: IMenuItem[] = [];
    if (!isActiveTimerRecord) {
      menuItems.push({title: strings('action.continue.tracking'), onPress: onContinueTracking});
    }
    if (props.record.canEdit) {
      menuItems.push({title: strings('action.entry.edit'), onPress: onEdit});
    }
    menuItems.push({title: strings('action.open.in.asana'), onPress: props.record.openAsanaTaskLink});
    if (props.deleteAction) {
      menuItems.push(props.deleteAction);
    } else {
      menuItems.push({title: strings('action.entry.delete'), warning: true, onPress: onDeletePress});
    }
    app.showMenuModal(menuItems);
  };

  const renderContinueTrackingConfirmBlock = () => {
    const timer = app.model.timer;
    if (!timer.isActive) return null;
    return (
      <View style={s.modalBlock}>
        <Text style={s.modalTitle}>{strings('info.continue.tracking.confirm')}</Text>
        {timer.timeRecord ? (
          <View style={s.modalCurrRecord}>
            <TimeRecordsRow record={timer.timeRecord} showAgreementId />
            <View style={s.modalDotBlock}>
              <BlinkingDot />
            </View>
          </View>
        ) : null}
      </View>
    );
  };

  const onPressTime = () => {
    if (!props.editable) return;
    props.record.setTimeEditMode(true);
    if (isWeb) app.showTimeEditModal(props.record);
  };

  const renderTimeBlock = () => {
    if (!props.editable) {
      return <Text style={s.text}>{props.record.hoursSpentFormatted}</Text>;
    }
    if (isWeb || !props.record.timeField) {
      return (
        <TouchableOpacity onPress={onPressTime} hitSlop={theme.smallElementsHitSlop}>
          <Text style={s.text}>{props.record.hoursSpentFormatted}</Text>
        </TouchableOpacity>
      );
    }
    return (
      <View style={s.timeEditRow}>
        <View style={s.timeEditBlock}>
          <FormInputBlock field={props.record.timeField} type="time" autofocus hideErrors />
        </View>
        <IconButton
          icon="check"
          backgroundColor={theme.bgColorGreen100}
          iconsSize={theme.aligned(14)}
          onPress={saveTime}
        />
        <View style={s.buttonSpacer} />
        <IconButton
          icon="cancel"
          backgroundColor={theme.bgColorRed100}
          iconsSize={theme.fontSize14}
          onPress={() => props.record.setTimeEditMode(false)}
        />
      </View>
    );
  };

  const renderActiveTimerDot = () => {
    if (!isActiveTimerRecord) return null;
    return (
      <View style={s.dotBlock}>
        <BlinkingDot />
      </View>
    );
  };

  return (
    <Pressable style={s.row} onPress={onPress}>
      <TimeRecordsRow record={props.record} />
      {renderTimeBlock()}
      {renderActiveTimerDot()}
    </Pressable>
  );
});

const s = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.space16,
    backgroundColor: theme.bgColorDark200,
    minHeight: theme.aligned(80),
  },
  text: {
    color: theme.textColor,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    lineHeight: theme.aligned(20),
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
    textDecorationLine: isWeb ? 'underline' : 'none',
  },
  modalTitle: {
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    lineHeight: theme.aligned(20),
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
    textAlign: 'center',
  },
  dotBlock: {
    position: 'absolute',
    right: theme.space8,
    top: theme.space16,
  },
  modalDotBlock: {
    position: 'absolute',
    right: theme.space8,
    top: theme.space8,
  },
  modalBlock: {
    flexShrink: 1,
    paddingTop: theme.space16,
    paddingBottom: theme.space24,
    padding: theme.space16,
    alignItems: 'center',
  },
  modalCurrRecord: {
    borderColor: theme.lightGreyColor,
    borderWidth: theme.pt,
    marginTop: theme.space24,
    padding: theme.space8,
    borderRadius: theme.cardBorderRadius,
    maxWidth: '100%',
  },
  spacer: {
    height: theme.space16,
  },
  timeEditRow: {
    flexDirection: 'row',
    height: theme.aligned(44),
    alignItems: 'center',
  },
  timeEditBlock: {
    flexShrink: 1,
    minWidth: theme.aligned(68),
    justifyContent: 'center',
    marginRight: theme.space4,
  },
  buttonSpacer: {
    width: theme.aligned(2),
  },
});
