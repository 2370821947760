import {Root} from './root';
import * as api from '../api';
import {AsanaProject} from './asanaProject';

export class AsanaProjects {
  model: Root;
  ids: string[] = [];
  byId: Map<string, AsanaProject> = new Map();
  loading: boolean = false;

  constructor(root: Root) {
    this.model = root;
  }

  get list() {
    const list: AsanaProject[] = [];
    for (const id of this.ids) {
      const item = this.byId.get(id);
      if (item) list.push(item);
    }
    return list;
  }

  getLinkedProjectsNumbers(projectNumber?: string) {
    if (!projectNumber) {
      return [];
    }
    return this.list
      .filter((el) => {
        return el.projectNumber && el.projectNumber === projectNumber;
      })
      .map((el) => el.gid);
  }

  setFromApi(projects: api.AsanaProject[]) {
    for (const p of projects) {
      const prj = this.byId.get(p.gid);
      if (prj) {
        prj.setFromApi(p);
      } else {
        this.byId.set(p.gid, new AsanaProject(this.model, p));
        this.ids.push(p.gid);
      }
    }
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

  clear() {
    this.byId = new Map();
    this.ids = [];
  }

  serialize(): any[] {
    return this.list.map((el) => el.serialize());
  }
}
