import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Text, View} from 'react-native';

import {TimeRecord} from '../models/timeRecord';
import {FormInputBlock} from './FormInputBlock';
import {IconButton} from '../elements/IconButton';
import theme from '../theme';
import {formatTimeToDurationInSeconds} from '../utils/formatters';
import {appProvider} from '../appProvider';
import OwnIcons from '../elements/OwnIcons';
import {expr} from 'mobx-utils';

type Props = {
  record: TimeRecord;
  onClose: () => void;
};

export const TimeEditModal: React.FC<Props> = observer((props) => {
  const app = appProvider.application;
  const field = props.record.timeField;
  const saveTime = () => {
    const field = props.record.timeField;
    if (!field) return;
    field.markAsDirty();
    if (!field.isValid) return;
    props.onClose();
    props.record.setLoading(true);
    app
      .updateTimeRecord(props.record.id, {hours_spent: formatTimeToDurationInSeconds(props.record.time)})
      .finally(() => props.record.setLoading(false));
    props.record.setTimeEditMode(false);
  };
  const onCancel = () => {
    props.record.setTimeEditMode(false);
    props.onClose();
  };

  if (!field) {
    return null;
  }

  const showError = expr(() => field && !!field.error && field.isDirty);

  return (
    <View style={s.container}>
      <Text style={s.title} numberOfLines={1} ellipsizeMode="tail">
        {props.record.title}
      </Text>
      <View style={s.subTitleRow}>
        <OwnIcons name="asana_logo" style={s.asanaIcon} color={theme.salmonColor} />
        <Text style={s.subTitle} numberOfLines={2} ellipsizeMode="tail">
          {props.record.subTitleCalculated}
        </Text>
      </View>
      <View style={s.row}>
        <View style={s.timeEditBlock}>
          <FormInputBlock field={field} type="time" autofocus />
        </View>
        <View style={[s.buttonsBlock, showError ? s.errorSpacer : null]}>
          <IconButton
            icon="check"
            backgroundColor={theme.bgColorGreen100}
            iconsSize={theme.aligned(14)}
            onPress={saveTime}
          />
          <View style={s.buttonSpacer} />
          <IconButton
            icon="cancel"
            backgroundColor={theme.bgColorRed100}
            iconsSize={theme.fontSize14}
            onPress={onCancel}
          />
        </View>
      </View>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    width: '80%',
    maxWidth: theme.aligned(400),
    minWidth: theme.webScreensWidth,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: theme.aligned(180),
    backgroundColor: theme.bgColorDark200Web,
    borderRadius: theme.aligned(8),
    borderWidth: 1,
    borderColor: theme.bgColorDark400,
    elevation: theme.aligned(10),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: theme.aligned(68),
  },
  timeEditBlock: {
    flexShrink: 1,
    minWidth: theme.aligned(68),
    justifyContent: 'center',
    marginRight: theme.space4,
  },
  buttonSpacer: {
    width: theme.aligned(2),
  },
  title: {
    textAlign: 'center',
    paddingHorizontal: theme.space16,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(18),
    fontFamily: theme.fontFamilyMedium,
    letterSpacing: theme.letterSpacing,
    fontWeight: 'bold',
  },
  subTitleRow: {
    paddingTop: theme.space4,
    flexDirection: 'row',
    paddingHorizontal: theme.space16,
  },
  subTitle: {
    textAlign: 'center',
    paddingBottom: theme.space16,
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    lineHeight: theme.aligned(18),
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '400',
  },
  asanaIcon: {
    lineHeight: theme.aligned(20),
    paddingRight: theme.space4,
  },
  buttonsBlock: {
    flexDirection: 'row',
    paddingTop: theme.space4,
  },
  errorSpacer: {
    paddingBottom: theme.space24,
  },
});
