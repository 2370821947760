import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Text, View} from 'react-native';

import theme from '../theme';
import {IconButton} from '../elements/IconButton';
import {appProvider} from '../appProvider';
import {weekDateFormat} from '../utils/formatters';
import {TodayLink} from './TodayLink';

export const WeekSelector: React.FC = observer(() => {
  const app = appProvider.application;
  const weekFilter = app.model.weekFilter;

  const onPrevPress = () => {
    weekFilter.toPrev();
    app.getTimeRecords(app.model.weekFilter);
  };

  const onNextPress = () => {
    weekFilter.toNext();
    app.getTimeRecords(app.model.weekFilter);
  };

  return (
    <View style={s.row}>
      <IconButton icon="arrow_prev" onPress={onPrevPress} iconsSize={theme.aligned(16)} />
      <View style={s.spacer} />
      <IconButton
        icon="arrow_next"
        onPress={onNextPress}
        iconsSize={theme.aligned(16)}
        disabled={weekFilter.isCurrentWeek}
      />
      <Text style={s.text} adjustsFontSizeToFit numberOfLines={1}>
        {weekDateFormat(weekFilter.startOfWeek, weekFilter.endOfWeek)}
      </Text>
      <TodayLink />
    </View>
  );
});

const s = StyleSheet.create({
  row: {
    paddingVertical: theme.space16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    width: theme.space12,
  },
  text: {
    paddingLeft: theme.space16,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    flexShrink: 1,
  },
});
