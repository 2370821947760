import React from 'react';
import {PixelRatio, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {IMenuItem} from '../types';
import theme from '../theme';

type Props = {
  menuItems?: IMenuItem[];
};

const MODAL_WIDTH = theme.windowWidth - PixelRatio.roundToNearestPixel(theme.windowWidth * 0.2);

export const MenuModalAndroid: React.FC<Props> = observer((props) => {
  const renderItems = () => {
    if (!props.menuItems?.length) return null;
    return (
      <View style={styles.cardContainer}>
        {props.menuItems.map((el: IMenuItem, index: number) => {
          return (
            <TouchableOpacity key={index} style={styles.button} onPress={el.onPress}>
              <Text style={styles.buttonText}>{el.title}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return <View style={styles.container}>{renderItems()}</View>;
});

const styles = StyleSheet.create({
  container: {
    width: MODAL_WIDTH,
    alignItems: 'center',
    flexDirection: 'row',
    elevation: theme.aligned(10),
  },
  cardContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
    borderRadius: theme.aligned(8),
    backgroundColor: theme.modalBkgColor,
    elevation: theme.aligned(10),
    paddingVertical: theme.space24,
  },
  button: {
    width: '100%',
    paddingHorizontal: theme.aligned(16),
    paddingVertical: theme.aligned(12),
  },
  buttonText: {
    fontSize: theme.fontSize16,
    lineHeight: theme.aligned(24),
    fontWeight: '400',
    color: theme.textColor,
  },
  paddingVertical: {
    paddingVertical: theme.aligned(28),
  },
});
