import {Root} from './root';
import {AsanaTasksList} from './asanaTasksList';
import * as api from '../api';
import {AsanaTask} from './asanaTask';

export class AsanaTasks {
  model: Root;
  byProjectId: Map<string, AsanaTasksList> = new Map();

  constructor(root: Root) {
    this.model = root;
  }

  tasksByProject(projectId: string): AsanaTask[] {
    const list = this.byProjectId.get(projectId);
    if (!list) return [];
    return list.tasks;
  }

  get serialized() {
    return this.serialize();
  }

  setFromApi(projectId: string, tasks: api.AsanaTask[]) {
    if (!Array.isArray(tasks)) return;
    if (!projectId) return;
    let list = this.byProjectId.get(projectId);
    if (!list) {
      list = new AsanaTasksList(this.model);
    }
    list.setFromApi(tasks);
    list.setProject4AllTasksInList(projectId);
    this.byProjectId.set(projectId, list);
  }

  load(s: any) {
    if (!s) return;
    const projectIds = Object.keys(s);
    if (!Array.isArray(projectIds)) return;
    for (const projectId of projectIds) {
      const list = s[projectId] as api.AsanaTask[];
      if (list && Array.isArray(list)) {
        this.setFromApi(projectId, list);
      }
    }
  }

  addFromApi(projectId: string, task: api.AsanaTask) {
    let list = this.byProjectId.get(projectId);
    if (!list) {
      list = new AsanaTasksList(this.model);
    }
    const newTask = list.addFromApi(task);
    this.byProjectId.set(projectId, list);
    return newTask;
  }

  clear(projectId?: string) {
    if (!projectId) {
      this.byProjectId = new Map();
      return;
    }
    const list = this.byProjectId.get(projectId);
    if (!list) return;
    list.clear();
    this.model.bumpStoreToFileIndicator();
  }

  serialize(): any[] {
    let p: any = {};
    for (const projectId of this.byProjectId.keys()) {
      const list = this.byProjectId.get(projectId);
      if (list) {
        p = {...p, [projectId]: list.serialize()};
      }
    }
    return p;
  }
}
