import React from 'react';
import {observer} from 'mobx-react-lite';
import {LogoutBlock} from '../components/LogoutBlock';
import {Image, StyleSheet, Text, View} from 'react-native';

import theme from '../theme';
import {appProvider} from '../appProvider';
import images from '../images';
import {isNative} from '../constants/general';
import Icon from './OwnIcons';
import {BlinkingDot} from './BlinkingDot';
import {TabType} from '../types';

export const HeaderBlock: React.FC = observer(() => {
  const app = appProvider.application;
  const tabCtrl = app.model.tabCtrl;

  const renderTimeTrakerIcon = () => {
    return (
      <View>
        <Icon onPress={() => tabCtrl.setTab(TabType.Time)} name="timer" style={s.timerIcon} />
        <View style={s.dotBlock}>{app.model.timer.isActive ? <BlinkingDot /> : null}</View>
      </View>
    );
  };

  const user = app.model.user;
  return (
    <View style={[s.row, !app.model.appCtrl?.isWideWebScreen ? s.centeredHeader : null]}>
      <Image
        style={StyleSheet.flatten([s.logo, !app.model.appCtrl?.isWideWebScreen ? s.wideMargin : null])}
        source={images.violet_logo_horizontal}
      />
      <View style={s.rightSide}>
        {renderTimeTrakerIcon()}
        <View style={s.avaBlock}>
          <Text style={s.avaText}>{user.userAvatarLetter}</Text>
        </View>
        <LogoutBlock
          logoStyle={s.logout}
          textColor={{color: theme.textColorPrimary}}
          iconColor={theme.textColorLight300}
        />
      </View>
    </View>
  );
});

const s = StyleSheet.create({
  activityIndicator: {
    margin: theme.space16,
    alignSelf: 'flex-start',
  },
  logo: {
    width: 88,
    height: 24,
    marginRight: theme.space16,
  },
  logout: {
    marginVertical: 0,
    marginHorizontal: 0,
    paddingVertical: theme.aligned(11),
    paddingHorizontal: theme.aligned(24),
    backgroundColor: theme.bgColorDark200Web,
  },
  rightSide: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    paddingLeft: theme.space16,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.cardBorderRadius,
    backgroundColor: isNative ? theme.bgColorDark200 : theme.bgColorWeb,
    paddingTop: theme.space24,
    paddingBottom: theme.space12,
    paddingHorizontal: theme.space24,
    justifyContent: 'space-between',
  },
  centeredHeader: {
    justifyContent: 'center',
    paddingBottom: theme.space16,
  },
  wideMargin: {
    marginRight: theme.space40,
  },
  avaBlock: {
    width: theme.aligned(40),
    height: theme.aligned(40),
    borderRadius: theme.space20,
    backgroundColor: theme.bgColorPurple100,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.space12,
  },
  avaText: {
    fontSize: theme.fontSize18,
    color: theme.textColor,
    fontFamily: theme.fontFamilyMain,
    textAlignVertical: 'center',
    lineHeight: theme.aligned(40),
    fontWeight: '500',
  },
  recordBlock: {
    padding: theme.space16,
  },
  timerIcon: {
    color: theme.textColorSecondary,
    fontSize: theme.aligned(34),
    paddingRight: theme.space16,
  },
  dotBlock: {
    position: 'absolute',
    right: theme.space14,
    top: theme.aligned(4),
  },
});
