import {parse} from 'date-fns';

export function decodeDate(data: string | undefined): number {
  try {
    const res = data ? Date.parse(data) : 0;
    return res;
  } catch {
    return 0;
  }
}

export function decodeShortDate(date: string /* in short format YYYY-MM-DD  (example - 2022-05-05) */) {
  try {
    const res = date ? parse(date, 'yyyy-MM-dd', new Date()).getTime() : 0;
    return res;
  } catch {
    return 0;
  }
}
