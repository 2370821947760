import {makeHandler} from './makeHandler';
import {RequestApiType} from './request';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {ASANA_WORKSPACE_ID} from '../constants/general';

export const getAsanaProjects = makeHandler(
  'getAsanaProjects',
  () => ({
    method: 'GET',
    path: `/workspaces/${ASANA_WORKSPACE_ID}/projects/?${getProjectsRequestParams()}`,
    apiType: RequestApiType.Asana,
  }),
  (res: AsanaProject[]) => res,
);

const PROJECTS_OPT_FIELDS = 'permalink_url,notes,default_view,modified_at,public,name,resource_type';

function getProjectsRequestParams() {
  return objectToQueryParamString({
    archived: false,
    opt_fields: PROJECTS_OPT_FIELDS,
  });
}

export enum AsanaProjectView {
  List = 'list',
  Board = 'board',
}

export type AsanaProject = {
  gid: string;
  name: string;
  permalink_url: string;
  notes: string;
  default_view: AsanaProjectView;
  modified_at: string;
  public: boolean;
  resource_type: string;
};
