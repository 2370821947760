import React from 'react';
import {Image, StyleSheet} from 'react-native';

import {Screen} from '../elements/Screen';
import {isNative} from '../constants/general';
import theme from '../theme';
import images from '../images';

export const LoadingScreen: React.FC = () => {
  return (
    <Screen style={s.screen}>
      <Image source={images.violet_logo} />
    </Screen>
  );
};

const s = StyleSheet.create({
  screen: {
    backgroundColor: isNative ? theme.bgColorDark200 : theme.bgColorWeb,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
