import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {observer} from 'mobx-react-lite';

import {WeekSelector} from './WeeksSelector';
import {HoursTrackedBlock} from './HoursTrackedBlock';
import theme from '../theme';
import {appProvider} from '../appProvider';
import {strings} from '../locales/i18n';
import {isNative} from '../constants/general';
import {formatDuration} from '../utils/formatters';

export const TimeRecordsHeader: React.FC = observer(() => {
  const app = appProvider.application;
  const list = app.model.timeRecordsList;
  const title = app.model.weekFilter.isCurrentWeek ? strings('common.this.week') : strings('common.past.week');

  return (
    <>
      <Text style={s.weekTitle}>{title}</Text>
      <WeekSelector />
      <HoursTrackedBlock
        hoursSpent={app.model.timeRecordsList.totalHoursSpent}
        hoursSpentFormatted={formatDuration(app.model.timeRecordsList.totalSecondsSpent)}
        hoursAvailable={app.model.weekFilter.userHoursAvailable}
        loading={list.loading}
      />
      <View style={s.row}>
        <Text style={s.timesheet}>{strings('common.timesheet')}</Text>
        {list.loading ? <ActivityIndicator color={theme.textColor} size="small" /> : null}
      </View>
    </>
  );
});

const s = StyleSheet.create({
  weekTitle: {
    color: theme.textColor,
    fontSize: isNative ? theme.fontSize32 : theme.fontSize24,
    paddingTop: isNative ? theme.space40 : 0,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  timesheet: {
    color: theme.textColor,
    paddingRight: theme.space12,
    fontSize: theme.fontSize24,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  row: {
    paddingTop: theme.aligned(40),
    flexDirection: 'row',
    alignItems: 'center',
  },
});
