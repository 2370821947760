import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import theme from '../theme';
import {IMenuItem} from '../types';
import {MenuItem} from './MenuItem';

interface IProps {
  items?: IMenuItem[];
  noMargin?: boolean;
  containerStyle?: ViewStyle;
  itemHeight?: number;
}

export const MenuCardsList: React.FC<IProps> = (props: IProps) => {
  const items = props.items;
  if (!items) return null;
  const noMargin = props.noMargin ? {margin: 0} : null;
  return (
    <View style={[s.card, noMargin, props.containerStyle]}>
      {items.map((el: IMenuItem, index: number) => (
        <MenuItem key={index} item={el} hideUnderline={index === items.length - 1} itemHeight={props.itemHeight} />
      ))}
    </View>
  );
};

const s = StyleSheet.create({
  card: {
    flex: 1,
    margin: theme.space16,
  },
});
