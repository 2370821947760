import {IFs} from './storageTypes';

export class WebStorage implements IFs {
  get ApplicationDataDir(): string {
    return '';
  }
  get CachesDir(): string {
    return '';
  }
  saveJsonFile(path: string, data: any): Promise<void> {
    localStorage.setItem(path, JSON.stringify(data));
    return Promise.resolve();
  }
  loadJsonFile(path: string): Promise<any> {
    return Promise.resolve(localStorage.getItem(path))
      .then((data) => (data ? JSON.parse(data) : undefined))
      .catch(() => undefined);
  }
}
