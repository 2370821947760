import {makeAutoObservable} from 'mobx';
import {createRef, RefObject} from 'react';
import {TextInput} from 'react-native';

export interface IFormField {
  readonly value: string | number;
  readonly error: string | undefined;
  readonly isValid: boolean;
  readonly isDirty: boolean;
  readonly focused: boolean;
  readonly height: number;
  setValue(value: string | number): void;
  setError(ident: string | undefined): void;
  cleanError(): void;
  markAsDirty(): void;
  markAsClean(): void;
  cleanAllErrors(): void;
  setFocused(val: boolean): void;
  setHeight(val: number): void;
  inputRef: RefObject<TextInput>;
}

type ProxyFieldGetter = () => string | number;
type ProxyFieldSetter = (val: string | number) => void;
export type ProxyFieldValidator = (val: string | number) => string | undefined;

export interface IProxyFieldConfig {
  getter: ProxyFieldGetter;
  setter: ProxyFieldSetter;
  validator?: ProxyFieldValidator;
}
export class ProxyField implements IFormField {
  inputRef = createRef<TextInput>();
  constructor(config: IProxyFieldConfig) {
    makeAutoObservable(this);
    this._getter = config.getter;
    this._setter = config.setter;
    if (config.validator) this._validator = config.validator;
    if (this.value) this.isDirty = true;
  }

  private _getter: ProxyFieldGetter;
  private _setter: ProxyFieldSetter;
  private _validator: ProxyFieldValidator | undefined;

  private _customError: string | undefined = undefined;
  private _customErrorValueSnapshot: string | number = '';

  isDirty: boolean = false;
  focused: boolean = false;

  height: number = 0;

  get error(): string | undefined {
    return this.validatorError || this.customError;
  }

  get isValid(): boolean {
    return !this.validatorError && !this.customError;
  }

  private get validatorError(): string | undefined {
    if (this._validator) {
      return this._validator(this.value);
    }
    return undefined;
  }

  private get customError(): string | undefined {
    if (this._customError && this._customErrorValueSnapshot === this.value) {
      return this._customError;
    }
    return undefined;
  }

  get value(): string | number {
    return this._getter();
  }

  setValue(val: string): void {
    this._setter(val);
  }

  setHeight(val: number) {
    this.height = val;
  }

  setError(ident: string | undefined) {
    this._customError = ident;
    this._customErrorValueSnapshot = this.value;
    this.markAsDirty();
  }

  cleanError() {
    this._customError = undefined;
    this._customErrorValueSnapshot = '';
  }

  markAsDirty() {
    if (!this.isDirty) this.isDirty = true;
  }

  markAsClean() {
    this.isDirty = false;
  }

  cleanAllErrors() {
    this.markAsClean();
    this.cleanError();
  }

  setFocused(val: boolean) {
    this.focused = val;
  }
}
