import React from 'react';
import {ActivityIndicator, RefreshControl, SectionList, StyleSheet, Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';

import {appProvider} from '../appProvider';
import {TimeRecordsListSection} from '../models/timeRecordsList';
import theme from '../theme';
import {dateToString, formatDuration} from '../utils/formatters';
import {AddTimeBlock} from './AddTimeBlock';
import {TimeRecordsItem} from './TimeRecordsItem';
import {TimeRecordsHeader} from './TimeRecordsListHeader';
import {LogoutBlock} from './LogoutBlock';
import {strings} from '../locales/i18n';
import {isNative} from '../constants/general';

type SectionProps = {
  section: TimeRecordsListSection;
};

export const TimeRecordsSection: React.FC<SectionProps> = observer((props) => {
  const totalTime = expr(() => props.section.data.reduce((acc, curr) => acc + curr.hoursSpent, 0));
  return (
    <View style={s.sectionRow}>
      <Text style={[s.title, s.flexShrink]}>{dateToString(props.section.day)}</Text>
      <Text style={s.totalText}>
        {strings('common.total')} {formatDuration(totalTime)}
      </Text>
    </View>
  );
});

export const TimeRecordsList: React.FC = observer(() => {
  const app = appProvider.application;
  const list = app.model.timeRecordsList;
  const renderLoading = () => {
    if (list.empty || list.loading) {
      return <ActivityIndicator style={s.activityIndicator} size="large" color={theme.textColor} />;
    }
    return null;
  };

  const onRefresh = () => {
    const app = appProvider.application;
    app.getTimeRecords(app.model.weekFilter);
  };

  const renderRefreshControl = () => {
    return (
      <RefreshControl
        refreshing={false}
        tintColor={theme.textColor}
        titleColor={theme.textColor}
        onRefresh={onRefresh}
      />
    );
  };
  return (
    <SectionList
      ListEmptyComponent={() => renderLoading()}
      ListHeaderComponent={<TimeRecordsHeader />}
      ListFooterComponent={isNative ? <LogoutBlock /> : undefined}
      stickySectionHeadersEnabled={false}
      showsVerticalScrollIndicator={false}
      sections={list.sectionsByDate}
      renderItem={(info) => {
        return <TimeRecordsItem record={info.item} />;
      }}
      renderSectionHeader={(item) => <TimeRecordsSection section={item.section} />}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={() => <View style={s.separator} />}
      renderSectionFooter={(item) => (
        <>
          <View style={s.separator} />
          <AddTimeBlock date={item.section.day.getTime()} />
        </>
      )}
      refreshControl={renderRefreshControl()}
    />
  );
});

const s = StyleSheet.create({
  title: {
    paddingHorizontal: theme.space16,
    paddingVertical: theme.space16,
    color: theme.textColorLight900,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    textTransform: 'capitalize',
  },
  totalText: {
    textAlign: 'right',
    paddingVertical: theme.space16,
    paddingRight: theme.space16,
    color: theme.textColorLight900,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
  },
  flexShrink: {
    flexShrink: 1,
  },
  separator: {
    height: theme.pt,
    backgroundColor: theme.bgColorDark400,
  },
  bottomBorderRadius: {
    borderBottomLeftRadius: theme.cardBorderRadius,
    borderBottomRightRadius: theme.cardBorderRadius,
    marginBottom: theme.space16,
  },
  sectionRow: {
    marginTop: theme.space16,
    borderTopLeftRadius: theme.cardBorderRadius,
    borderTopRightRadius: theme.cardBorderRadius,
    borderBottomColor: theme.bgColorDark400,
    borderBottomWidth: theme.pt,
    backgroundColor: theme.bgColorDark300,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spacer: {
    height: theme.aligned(40),
  },
  activityIndicator: {
    paddingTop: theme.aligned(40),
    alignSelf: 'center',
    alignItems: 'center',
    flex: 1,
  },
});
