import {getTimeRecords, deleteTimeRecord, addTimeRecord, updateTimeRecord, getTimeRecord} from './codecs.records';
import {createSessionRequest, getSessionById, setSessionToken, checkUserByEmail} from './codecs.session';
import {getAgreements, getAgreement, getAgreementByAsanaGid} from './codecs.agreements';
import {getThreads, getThread} from './codecs.threads';
import {getTimerByEmail, updateTimerRecord, addTimerRecord} from './codecs.timer';
import {getAbsentDays, getHolidays} from './codecs.workschedule';
import {getWelcomeTexts} from './codecs.settings';
import {searchForTasks, getAsanaTaskById, searchForMyTasks} from './codecs.asana.tasks';
import {getAsanaUsers} from './codecs.asana.users';
import {addDailyRecord, getDailyRecords, updateDailyRecord} from './codecs.daily';
import {getAsanaProjects} from './codecs.asana.projects';

const handlers = [
  getTimeRecord,
  getTimeRecords,
  createSessionRequest,
  getSessionById,
  checkUserByEmail,
  setSessionToken,
  addTimeRecord,
  updateTimeRecord,
  deleteTimeRecord,
  getAgreements,
  getAgreement,
  getTimerByEmail,
  updateTimerRecord,
  addTimerRecord,
  getAbsentDays,
  getHolidays,
  getThread,
  getThreads,
  getWelcomeTexts,
  searchForTasks,
  getAsanaUsers,
  getAsanaTaskById,
  searchForMyTasks,
  getAgreementByAsanaGid,
  getDailyRecords,
  addDailyRecord,
  updateDailyRecord,
  getAsanaProjects,
] as const;

const handlersMap: Map<ApiHandlerTypes, ApiHandlers> = new Map();
for (let h of handlers) {
  handlersMap.set(h.type, h);
}

type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never;

type ApiHandlers = (typeof handlers)[number];
export type ApiHandlerTypes = ApiHandlers['type'];
export type ApiHandler<Type extends ApiHandlerTypes = ApiHandlerTypes> = DiscriminateUnion<ApiHandlers, 'type', Type>;

export type ApiHandlerParams<Type extends ApiHandlerTypes> = Parameters<ApiHandler<Type>['prepare']>[0] extends {}
  ? Parameters<ApiHandler<Type>['prepare']>[0]
  : undefined;
export type ApiHandlerResponse<Type extends ApiHandlerTypes> = ReturnType<ApiHandler<Type>['decode']>;

export function getHandler<Type extends ApiHandlerTypes>(type: Type): ApiHandler<Type> {
  return handlersMap.get(type) as ApiHandler<Type>;
}
