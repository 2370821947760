import React from 'react';
import {StyleSheet, Text, TouchableOpacity, StyleProp, ViewStyle} from 'react-native';

import theme from '../theme';
import {ITabItem} from '../types';
import {isNative} from '../constants/general';

interface Props {
  item: ITabItem;
  isActive?: boolean;
  onPress: (item: ITabItem) => void;
  style?: StyleProp<ViewStyle>;
}

export const TabButton: React.FC<Props> = (props: Props) => {
  const onPress = () => props.onPress(props.item);
  const style = StyleSheet.flatten([s.container, props.style, props.isActive ? s.active : null]);
  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Text style={s.text}>{props.item.text}</Text>
    </TouchableOpacity>
  );
};

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.aligned(6),
  },
  text: {
    paddingLeft: !isNative ? 0 : theme.space8,
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    lineHeight: theme.aligned(24),
  },
  active: {
    backgroundColor: theme.bgColorPurple100,
    borderRadius: theme.cardBorderRadius,
  },
});
