import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, Text, TouchableOpacity, TextStyle, ViewStyle} from 'react-native';
import {isNative} from '../constants/general';

import Icon from '../elements/OwnIcons';
import theme from '../theme';
import {appProvider} from '../appProvider';
import {strings} from '../locales/i18n';

interface IProps {
  logoStyle?: ViewStyle;
  iconColor?: string;
  textColor?: TextStyle;
}

export const LogoutBlock: React.FC<IProps> = observer((props: IProps) => {
  const onLogout = () => appProvider.application.logout();
  const style = StyleSheet.flatten([s.row, props.logoStyle]);
  const iconColor = props.iconColor ? props.iconColor : theme.bgColorRed100;
  let TextStyles: TextStyle[] = [];
  TextStyles.push(s.text);
  props.textColor && TextStyles.push(props.textColor);

  // TODO: after moving to new violet-DS, remove exception for left icon & left icon
  return (
    <TouchableOpacity style={style} onPress={onLogout}>
      {isNative && <Icon name="logout" color={theme.bgColorRed100} />}
      <Text style={TextStyles}>{strings('common.logout')}</Text>
      {!isNative && <Icon name="logout_web" color={iconColor} size={theme.fontSize18} />}
    </TouchableOpacity>
  );
});

const s = StyleSheet.create({
  row: {
    paddingLeft: theme.space16,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.cardBorderRadius,
    marginVertical: theme.space16,
    backgroundColor: theme.bgColorDark200,
    paddingVertical: theme.space16,
  },
  text: {
    paddingHorizontal: theme.space10,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    color: theme.bgColorRed100,
  },
});
