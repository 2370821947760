import {makeAutoObservable} from 'mobx';

import * as api from '../api';
import {IHoliday} from '../api';
import {decodeShortDate} from '../utils/decodeDate';
import {dateToShortFormat} from '../utils/formatters';

export class HolidaysList {
  dates: string[] = [];
  byDates: Map<string, Holiday> = new Map();
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get list(): Holiday[] {
    const list: Holiday[] = [];
    this.dates.map((id) => {
      const h = this.byDates.get(id);
      if (h) list.push(h);
    });
    return list;
  }

  checkHoliday(date: Date | number) {
    const h = this.byDates.get(dateToShortFormat(date));
    return Boolean(h && h.isHoliday);
  }

  checkAntiHoliday(date: Date | number) {
    const h = this.byDates.get(dateToShortFormat(date));
    return Boolean(h && h.isAntiHoliday);
  }

  setFromApi(items?: api.HolidaysResponseItem[]) {
    if (!items || !Array.isArray(items)) return;
    this.clear();
    for (const p of items) {
      this.addFromApi(p);
    }
  }

  addFromApi(p?: api.HolidaysResponseItem) {
    if (!p?.fields) return;
    const holiday = new Holiday(p.fields);
    if (!this.dates.includes(p.fields.date)) {
      this.dates.push(p.fields.date);
    }
    this.byDates.set(p.fields.date, holiday);
  }

  clear() {
    this.byDates = new Map();
    this.dates = [];
  }
}

class Holiday {
  date: string; // in russian short format D.M.YYYY  (example - 3.5.2022)
  type: api.HolidayType;
  timestamp: number;

  get isHoliday() {
    return this.type !== api.HolidayType.AntiHoliday;
  }

  get isAntiHoliday() {
    return this.type === api.HolidayType.AntiHoliday;
  }

  constructor(p: IHoliday) {
    makeAutoObservable(this);
    this.date = p.date;
    this.timestamp = decodeShortDate(p.date);
    this.type = p.type;
  }
}
