import React from 'react';
import {observer} from 'mobx-react-lite';
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';

import theme from '../theme';
import {strings} from '../locales/i18n';
import {appProvider} from '../appProvider';
import {TimeRecordsRow} from './TimeRecordsRow';
import {isNative} from '../constants/general';

export const WelcomeTextBlock: React.FC = observer(() => {
  const model = appProvider.application.model;
  if (model.welcomeTextsList.loading) {
    return <ActivityIndicator size={'large'} color={theme.textColor} style={s.activityIndicator} />;
  }
  if (model.timer.timeRecord) {
    return (
      <View style={s.recordBlock}>
        <TimeRecordsRow record={model.timer.timeRecord} showAgreementId centered={!isNative} />
      </View>
    );
  }

  if (!isNative) return null;

  const user = model.user;
  const textsList = model.welcomeTextsList;

  return (
    <View style={s.container}>
      <View style={s.avaBlock}>
        <Text style={s.avaText}>{user.userAvatarLetter}</Text>
      </View>
      <View style={s.textsBlock}>
        <Text style={s.userName}>
          {strings('common.hi')}, {user.firstName}
        </Text>
        <Text style={s.welcomeText}>{textsList.getRandomText()}</Text>
      </View>
    </View>
  );
});

const s = StyleSheet.create({
  activityIndicator: {
    margin: theme.space16,
    alignSelf: 'flex-start',
  },
  container: {
    paddingHorizontal: theme.space16,
    paddingTop: theme.space16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  avaBlock: {
    width: theme.aligned(40),
    height: theme.aligned(40),
    borderRadius: theme.space20,
    backgroundColor: theme.bgColorPurple100,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.space12,
  },
  avaText: {
    fontSize: theme.fontSize24,
    color: theme.textColor,
    fontFamily: theme.fontFamilyMain,
    textAlignVertical: 'center',
    lineHeight: theme.aligned(40),
    fontWeight: '500',
  },
  textsBlock: {
    flexShrink: 1,
  },
  userName: {
    color: theme.textColorSecondary,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    fontWeight: '500',
  },
  welcomeText: {
    paddingTop: theme.aligned(3),
    color: theme.textColorSecondary,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMain,
    textAlign: !isNative ? 'center' : 'left',
  },
  recordBlock: {
    padding: theme.space16,
    alignItems: isNative ? 'flex-start' : 'center',
  },
});
