import {IFs} from './storageTypes';

export class Storage {
  private fs: IFs;

  private readonly AppDataFile: string;

  constructor(fs: IFs) {
    this.fs = fs;
    this.AppDataFile = this.fs.ApplicationDataDir + '/appData.json';
  }

  store(data: any): Promise<void> {
    return this.fs.saveJsonFile(this.AppDataFile, data);
  }
  load(): Promise<any> {
    return this.fs.loadJsonFile(this.AppDataFile);
  }
}
