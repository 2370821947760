import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';

import OwnIcons from '../elements/OwnIcons';
import theme from '../theme';
import {TimeRecord} from '../models/timeRecord';
import {appProvider} from '../appProvider';

type Props = {
  record: TimeRecord;
  showAgreementId?: boolean;
  centered?: boolean;
};

export const TimeRecordsRow: React.FC<Props> = observer((props) => {
  const agreementIdComplex = expr(() => {
    if (!props.showAgreementId || !props.record.agreementId) return '';
    const agreement = appProvider.application.model.agreementsList.byId.get(props.record.agreementId);
    if (!agreement?.idComplex) return '';
    return `[${agreement.idComplex}] `;
  });

  const renderSubTitle = () => {
    if (!props.record.subTitleCalculated) return null;
    if (props.record.asanaTaskLink) {
      return (
        <View style={[s.subTitleRow, props.centered ? s.centered : null]}>
          <OwnIcons name="asana_logo" style={s.asanaIcon} color={theme.salmonColor} />
          <Text style={s.linkText} numberOfLines={2}>
            {props.record.subTitleCalculated}
          </Text>
        </View>
      );
    }
    return (
      <Text style={[s.textSub, props.centered ? s.centered : null]} numberOfLines={2}>
        {props.record.subTitleCalculated}
      </Text>
    );
  };

  return (
    <View style={[s.titleBlock, props.centered ? s.centered : null]}>
      <Text style={s.title} numberOfLines={1} ellipsizeMode="tail">
        {agreementIdComplex}
        {props.record.title}
      </Text>
      {renderSubTitle()}
    </View>
  );
});

const s = StyleSheet.create({
  title: {
    color: theme.textColor,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMedium,
    lineHeight: theme.aligned(20),
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
    paddingBottom: theme.aligned(6),
  },
  textSub: {
    paddingTop: theme.aligned(4),
    color: theme.textColorSecondary,
    fontSize: theme.fontSize14,
    fontFamily: theme.fontFamilyMain,
    lineHeight: theme.aligned(20),
    letterSpacing: theme.letterSpacing,
    fontWeight: '400',
  },
  titleBlock: {
    flexShrink: 1,
    paddingRight: theme.space16,
  },
  linkText: {
    color: theme.bgColorPurple100,
    fontSize: theme.fontSize14,
    lineHeight: theme.aligned(18),
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '400',
    flexShrink: 1,
  },
  subTitleRow: {
    flexDirection: 'row',
  },
  asanaIcon: {
    lineHeight: theme.aligned(20),
    marginRight: theme.space8,
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
