import {makeObservable, observable} from 'mobx';

import {IModalConfig, IModalEntry, ModalRenderer, ModalType} from '../types';
import theme from '../theme';

export enum EntryState {
  hidden,
  visible,
}

export class ModalEntry implements IModalEntry {
  readonly id: string;
  readonly backgroundColor: string = theme.bgColor;
  readonly render: ModalRenderer;
  readonly type: ModalType = 'bottom';
  readonly canHide: boolean = true;
  readonly onHide?: () => void = undefined;
  readonly multipleMode?: boolean = false;

  currentState: EntryState = EntryState.hidden;

  constructor(id: string, render: ModalRenderer, config?: IModalConfig) {
    makeObservable(this, {
      currentState: observable,
    });
    this.id = id;
    this.render = render;
    if (config) {
      if (config.type) this.type = config.type;
      if (config.backgroundColor) this.backgroundColor = config.backgroundColor;
      if (config.onHide) this.onHide = config.onHide;
      if (config.persistent) this.canHide = false;
      if (config.multipleMode) this.multipleMode = true;
    }
    this.show();
  }

  show() {
    if (this.currentState === EntryState.visible) return;
    this.currentState = EntryState.visible;
  }

  hide(cb: (id: string) => void) {
    if (this.currentState === EntryState.hidden) {
      cb(this.id);
      return;
    }
    this.currentState = EntryState.hidden;
    cb(this.id);
    if (this.onHide) this.onHide();
  }
}
