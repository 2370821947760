import {format, intervalToDuration, isSameMonth, isSameYear} from 'date-fns';
import {enUS, ru} from 'date-fns/locale';

import {locale} from '../locales/i18n';
import {capitalizeFirstLetter} from './stringFormating';
/**
 * TODO update locales every time we add new language manually
 * due to special locale object in date-fns, it's not a string!!
 */
const LOCALES = {
  en: enUS,
  ru: ru,
};

export function dateToString(date: Date | number | string): string {
  return format(new Date(date), 'iiii, d MMMM', {
    // @ts-ignore
    locale: LOCALES[locale],
  });
}

export function dateToShortFormat(date: Date | number | string): string {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function dateToShortFormatWithTime(date: Date | number | string): string {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
}

export function dateToStringWithYear(date: Date | number | string): string {
  return capitalizeFirstLetter(
    format(new Date(date), 'iiii, d MMMM yyyy', {
      // @ts-ignore
      locale: LOCALES[locale],
    }),
  );
}

export function formatAirTableParsedDate(date: Date | number | string) {
  return `DATETIME_PARSE("${dateToShortFormat(date)}", "YYYY-MM-DD")`;
}

export function formatAirTableParsedDateWithTime(date: Date | number | string) {
  return `DATETIME_PARSE("${dateToShortFormatWithTime(date)}", "YYYY-MM-DD hh:mm:ss")`;
}

export function weekDateFormat(start: Date, end: Date): string {
  let res = formatDateOnly(start);
  const sameMonth = isSameMonth(start, end);
  const sameYear = isSameYear(start, end);
  if (!sameMonth) res = `${res} ${formatMonthOnly(start)}`;
  if (!sameYear) res = `${res} ${formatYearShort(start)}`;
  res = `${res}  — ${formatDateOnly(end)} ${formatMonthOnly(end)}`;
  if (!sameYear) res = `${res} ${formatYearShort(end)}`;
  return res;
}

export function formatDuration(duration: number, skipDelimeter?: boolean) {
  //duration  in seconds
  const delimeter = !skipDelimeter ? ':' : '';
  if (!duration) return `0${delimeter}00`;
  const res = intervalToDuration({start: 0, end: duration * 1000});
  const hours = (res?.days || 0) * 24 + (res.hours || 0);
  return `${String(hours).padStart(2, '0')}${delimeter}${String(res.minutes).padStart(2, '0')}`;
}

export function formatDurationWithSeconds(duration: number) {
  //duration  in seconds
  if (!duration) return '0:00:00';
  const res = intervalToDuration({start: 0, end: duration * 1000});
  const hours = (res?.days || 0) * 24 + (res.hours || 0);
  return `${hours}:${String(res.minutes).padStart(2, '0')}:${String(res.seconds).padStart(2, '0')}`;
}

export function formatYearShort(date: Date) {
  return format(date, 'yy', {
    // @ts-ignore
    locale: LOCALES[locale],
  });
}

export function formatMonthOnly(date: Date) {
  return format(date, 'MMM', {
    // @ts-ignore
    locale: LOCALES[locale],
  }).replace('.', '');
}

export function formatDateOnly(date: Date) {
  return format(date, 'd', {
    // @ts-ignore
    locale: LOCALES[locale],
  });
}

export function formatTimeToDurationInSeconds(time: string) {
  const nodes = time.split(':');
  const hours = parseInt(nodes[0]);
  const minutes = parseInt(nodes[1]);
  return hours * 60 * 60 + minutes * 60;
}
