import {makeHandler} from './makeHandler';
import objectToQueryParamString from '../utils/objectToQueryParamString';
import {IResponse, IResponseItem, RequestApiType} from './request';

export const getAgreements = makeHandler(
  'getAgreements',
  (req: {offset?: string; limit?: number}) => ({
    method: 'GET',
    path: `/AgreementsGrid?${getRequestFields(req)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: AgreementsResponse) => res.data,
);

export const getAgreement = makeHandler(
  'getAgreement',
  (id: string) => ({
    method: 'GET',
    path: `/AgreementsGrid/${id}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: {data: AgreementsResponseItem}) => res.data,
);

export const getAgreementByAsanaGid = makeHandler(
  'getAgreementByAsanaGid',
  (projectIds: string[]) => ({
    method: 'GET',
    path: `/AgreementsGrid?${getByAsanaGidRequestFields(projectIds)}`,
    apiType: RequestApiType.AirTable,
  }),
  (res: AgreementsResponse) => res.data.records,
);

export type AgreementsResponse = IResponse<IAgreement>;
export type AgreementsResponseItem = IResponseItem<IAgreement>;

export interface IAgreement {
  agreement: string;
  id_complex: string;
  project_type: string;
  emails: string;
  asana_gid_agreement: string;
}

export interface IAgreementWithId extends IAgreement {
  id: string;
}

enum availableStatuses {
  InProgress = 'In Progress',
  Done = 'Done/Out',
}

const fields = ['id_complex', 'project_type', 'agreement', 'emails', 'asana_gid_agreement'];

function getRequestFields(req: {offset?: string; limit?: number}) {
  const params: Record<string, any> = {
    'fields[]': fields,
    filterByFormula: `{status} = "${availableStatuses.InProgress}"`,
  };
  if (req.offset) params['offset'] = req.offset;
  if (req.limit) params['pageSize'] = req.limit;
  return objectToQueryParamString(params);
}

function getByAsanaGidRequestFields(projectIds: string[]) {
  let filterByFormula: string = `{asana_gid_agreement} = "${projectIds[0]}"`;
  if (projectIds.length > 1) {
    for (let i = 1; i < projectIds.length; i++) {
      filterByFormula = `${filterByFormula}, {asana_gid_agreement} = "${projectIds[i]}"`;
    }
    filterByFormula = `OR(${filterByFormula})`;
  }
  return objectToQueryParamString({
    'fields[]': fields,
    filterByFormula,
  });
}
