import React from 'react';
import {observer} from 'mobx-react-lite';

import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native';

import {ModalController} from '../models/modalsController';
import theme from '../theme';
import {ModalEntryView} from './ModalEntryView';
import {isNative} from '../constants/general';

type Props = {
  ctrl: ModalController;
};

export const ModalHost: React.FC<Props> = observer((props) => {
  if (!props.ctrl.isVisible) return null;

  const onClick = () => {
    if (props.ctrl.canHideLastEntry) props.ctrl.hide();
  };

  const renderOverlay = () => {
    const modalType = props.ctrl.lastEntry?.type;
    const style = [
      styles.absolutePosition,
      modalType !== 'bottom' ? styles.opacityRegular : null,
      modalType === 'dialog' ? styles.opacityDialog : null,
    ];
    return <View style={style} />;
  };

  return (
    <View style={styles.absolutePosition}>
      <TouchableWithoutFeedback onPress={onClick}>{renderOverlay()}</TouchableWithoutFeedback>
      {props.ctrl.entries.map((entry) => (
        <ModalEntryView key={entry.id} ctrl={props.ctrl} entry={entry} />
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  absolutePosition: {
    ...StyleSheet.absoluteFillObject,
    top: !isNative ? theme.aligned(80) : 0,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  opacityRegular: {
    backgroundColor: theme.bgColorDark100,
    opacity: 0.6,
  },
  opacityDialog: {
    backgroundColor: theme.bgColorDark100,
    opacity: 0.35,
  },
});
