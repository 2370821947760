import {observer} from 'mobx-react-lite';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

import images from '../images';
import {appProvider} from '../appProvider';
import theme from '../theme';
import {strings} from '../locales/i18n';
import {Button} from '../elements/Button';

export const SessionExpiredBlock: React.FC = observer(() => {
  const onLogin = () => appProvider.application.modal.hide();
  return (
    <View style={styles.content}>
      <View style={styles.imageBlock}>
        <Image source={images.session_expired} />
      </View>
      <View style={styles.bottomBlock}>
        <Text style={styles.title}>{strings('info.session.expired.title')}</Text>
        <Text style={styles.text}>{strings('info.session.expired')}</Text>
        <View style={styles.buttonBlock}>
          <Button title={strings('action.login')} onPress={onLogin} />
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  content: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.bgColorDark100,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  imageBlock: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bottomBlock: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: theme.space16,
  },
  title: {
    paddingTop: theme.aligned(56),
    paddingBottom: theme.aligned(16),
    color: theme.textColor,
    fontSize: theme.fontSize32,
    paddingHorizontal: theme.space16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '600',
  },
  text: {
    paddingHorizontal: theme.space32,
    textAlign: 'center',
    color: theme.textColor,
    fontSize: theme.fontSize16,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    lineHeight: theme.space24,
  },
  buttonBlock: {
    paddingTop: theme.aligned(40),
    width: theme.windowWidth - theme.space16 * 2,
  },
});
