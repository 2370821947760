export function isString(thing: unknown): thing is string {
  return typeof thing === 'string';
}

export function isNumber(thing: unknown): thing is number {
  return typeof thing === 'number';
}

export function isObject(thing: unknown): thing is object {
  return thing !== null && typeof thing === 'object';
}

export function hasProp<P extends string>(o: object, prop: P): o is {[x in P]: unknown} {
  return o.hasOwnProperty(prop);
}
