import {makeAutoObservable} from 'mobx';
import {differenceInHours} from 'date-fns';

import {decodeDate} from '../utils/decodeDate';
import * as api from '../api';

const SESSION_VALID_CODE_LIMIT = 1; // in hours

export class Session {
  id: string = '';
  token: string = '';
  email: string = '';
  userId: string = '';
  createdTime: number = 0;
  lastModified: number = 0;
  verificationCode: string = '';
  authorized: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get defined() {
    return Boolean(this.id) && Boolean(this.createdTime);
  }

  get valid() {
    return this.defined && this.email && this.token;
  }

  get isAuthorized() {
    return this.authorized && this.valid;
  }

  get awaitPinCodeCheck() {
    const timeDiff = differenceInHours(Date.now(), this.createdTime);
    return this.id && this.verificationCode && !this.token && timeDiff < SESSION_VALID_CODE_LIMIT;
  }

  setToken(val: string) {
    this.token = val;
  }

  setFromApi(s?: api.ISession) {
    if (!s) {
      this.clear();
      return;
    }
    this.email = s.email;
    this.lastModified = decodeDate(s.last_modified);
    this.createdTime = decodeDate(s.created_time);
    this.verificationCode = s.verification_code || '';
    if (this.token === s.token) this.authorized = true;
    this.token = s.token;
  }

  setSessionId(val: string) {
    this.id = val;
  }

  setAuthorized() {
    this.authorized = true;
  }

  setUserId(val?: string) {
    this.userId = val || '';
  }

  load(s: any) {
    if (!s) return;
    this.id = s.id || '';
    this.email = s.email || '';
    this.token = s.token || '';
    this.userId = s.user_id || '';
    this.createdTime = decodeDate(s.created_time);
    this.lastModified = decodeDate(s.last_modified);
    this.verificationCode = s.verificationCode || '';
  }

  clear() {
    this.id = '';
    this.token = '';
    this.email = '';
    this.createdTime = 0;
    this.lastModified = 0;
    this.authorized = false;
    this.verificationCode = '';
    this.userId = '';
  }

  serialize(): any {
    return {
      id: this.id,
      token: this.token,
      email: this.email,
      user_id: this.userId,
    };
  }
}
