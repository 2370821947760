import React from 'react';
import {ScrollView, StyleSheet, Text, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import InputScrollView from 'react-native-input-scroll-view';

import {Screen} from '../elements/Screen';
import theme from '../theme';
import {strings} from '../locales/i18n';
import {NavigationScreenProps} from '../Routes';
import {AppScreens} from '../constants/screens';
import {Button} from '../elements/Button';
import {appProvider} from '../appProvider';
import {TimeRecordFormView} from '../components/TimeRecordFormView';
import {IModalConfirmConfig} from '../types';
import {TimeRecordDatePiker} from '../elements/TimeRecordDatePiker';
import {hasEditRecordsUITroubles} from '../utils/hasEditRecordsUITroubles';
import {HeaderBlock} from '../elements/HeaderBlock';
import {isNative} from '../constants/general';
import {LayoutRectangle} from 'react-native/Libraries/Types/CoreEventTypes';

type Props = NavigationScreenProps<AppScreens.AddOrEditTimeRecord>;

export const AddOrEditTimeRecord: React.FC<Props> = observer((props) => {
  const {form} = props.route.params;
  if (!form) return null;
  const app = appProvider.application;

  const onCancel = () => {
    if (!form.hasChanges) {
      app.navigateBack();
      if (form.afterAddAction) form.afterAddAction();
      return;
    }
    const config: IModalConfirmConfig = {
      onConfirm: onCancelConfirm,
      title: form.isEdit ? strings('info.cancel.editing') : strings('info.cancel.addition'),
      text: strings('info.data.not.saved'),
      buttons: [
        {action: onCancelConfirm, text: strings('action.cancel.positive'), isWarning: true},
        {
          action: () => {
            app.modal.hide();
            if (form.afterAddAction) form.afterAddAction();
          },
          text: strings('action.continue.negative'),
        },
      ],
    };
    app.showConfirmModal(config);
  };

  const onCancelConfirm = () => {
    app.modal.hide();
    app.navigateBack();
    if (form.afterAddAction) form.afterAddAction();
    setTimeout(() => app.showSnackMessage(strings('info.no.data.saved')), 100);
  };

  const onSave = () => {
    if (form.loading) return;
    form.markAsDitry();
    if (!form.isValid) return;
    const call = form.isEdit
      ? app.updateTimeRecord(form.recordId, form.record4export)
      : app.addTimeRecord(form.record4export);
    form.setLoading(true);
    call
      .then((recordId?: string) => {
        app.model.tabCtrl.setHistoryTab();
        if (app.model.session.authorized) {
          app.navigateToMainScreen();
          app.showSnackMessage(form.isEdit ? strings('info.record.edited') : strings('info.record.added'));
        }
        if (form.afterAddAction) form.afterAddAction(recordId);
      })
      .finally(() => form.setLoading(false));
  };

  const renderButtonsBlock = () => {
    return (
      <View style={styles[theme.stylesType].buttonsBlock}>
        <View style={styles[theme.stylesType].buttonBlock}>
          <Button
            variant="primary"
            title={strings('common.save')}
            onPress={onSave}
            disabled={form.isEdit && !form.hasChanges}
            loading={form.loading || app.model.timeRecordsList.loading}
          />
        </View>
        <View style={styles[theme.stylesType].spacer} />
        <View style={styles[theme.stylesType].buttonBlock}>
          <Button variant="secondary" title={strings('common.cancel')} onPress={onCancel} />
        </View>
      </View>
    );
  };

  const renderContent = () => {
    if (!isNative || hasEditRecordsUITroubles(appProvider.application.model.session.email)) {
      return (
        <ScrollView contentContainerStyle={styles[theme.stylesType].scroll}>
          <TimeRecordFormView form={form} />
          {renderButtonsBlock()}
        </ScrollView>
      );
    }

    return (
      <InputScrollView
        scrollEnabled
        contentContainerStyle={styles[theme.stylesType].scroll}
        keyboardOffset={theme.aligned(100)}>
        <TimeRecordFormView form={form} />
        {renderButtonsBlock()}
      </InputScrollView>
    );
  };

  const contentBlock = <View style={styles[theme.stylesType].content}>{renderContent()}</View>;

  const onLayout = (layout: LayoutRectangle) => {
    app.model.appCtrl?.setScreenWidth(layout.width);
  };

  return (
    <>
      <Screen safearea style={s.screen} onLayout={onLayout}>
        {!isNative ? (
          <>
            <HeaderBlock />
            {contentBlock}
          </>
        ) : (
          <>
            <View style={s.titleRow}>
              <Text style={s.title}>
                {form.record ? strings('info.edit.time.entry') : strings('info.new.time.entry')}
              </Text>
            </View>
            {contentBlock}
          </>
        )}
      </Screen>
      <TimeRecordDatePiker form={form} />
    </>
  );
});

interface IScreenStyles {
  content: ViewStyle;
  scroll: ViewStyle;
  buttonsBlock: ViewStyle;
  buttonBlock: ViewStyle;
  spacer: ViewStyle;
}

interface IStyles {
  [x: string]: IScreenStyles;
}

const styles: IStyles = {
  web: {
    content: {
      flex: 1,
      width: theme.webScreensWidth,
      alignContent: 'center',
      margin: 'auto',
    },
    scroll: {},
    buttonsBlock: {
      flexDirection: 'row',
      width: theme.webScreensWidth,
      paddingBottom: theme.space24,
    },
    buttonBlock: {
      flex: 0.5,
    },
    spacer: {
      width: theme.space12,
    },
  },
  native: {
    content: {
      backgroundColor: theme.bgColorDark100,
      flex: 1,
    },
    scroll: {
      paddingHorizontal: theme.space16,
    },
    buttonsBlock: {
      paddingVertical: theme.space32,
    },
    buttonBlock: {},
    spacer: {
      height: theme.space16,
    },
  },
};

const s = StyleSheet.create({
  screen: {
    backgroundColor: isNative ? theme.bgColorDark200 : theme.bgColorWeb,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.space16,
    borderBottomColor: theme.bgColorDark400,
    borderBottomWidth: theme.pt,
  },
  title: {
    alignSelf: 'center',
    color: theme.textColor,
    fontSize: theme.fontSize18,
    fontFamily: theme.fontFamilyMain,
    letterSpacing: theme.letterSpacing,
    fontWeight: '500',
    textAlign: 'center',
    paddingHorizontal: theme.space16,
  },
});
