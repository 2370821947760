import {makeAutoObservable} from 'mobx';
import {differenceInSeconds} from 'date-fns';

import {IncrementTimerController} from './timerController';
import * as api from '../api';
import {decodeDate} from '../utils/decodeDate';
import {formatDurationWithSeconds} from '../utils/formatters';
import {ITimerController} from '../types';
import {Root} from './root';

export class TrackTimer {
  private model: Root;
  id: string = '';
  loading: boolean = false;
  startDate: number = 0;
  isActive: boolean = false;
  timerCtrl: ITimerController = new IncrementTimerController(0);
  timeSpent: number = 0;
  recordId: string = '';

  constructor(root: Root) {
    this.model = root;
    makeAutoObservable(this);
  }

  get timeSpentFormatted() {
    if (this.isActive) return formatDurationWithSeconds(this.timerCtrl.currentSeconds);
    return formatDurationWithSeconds(this.timeSpent);
  }

  get empty() {
    return !this.startDate && !this.isActive;
  }

  get timeRecord() {
    return this.model.timeRecordsList.byId.get(this.recordId);
  }

  get needRequestTimeRecord() {
    return Boolean(this.model.timer.recordId && !this.model.timer.timeRecord);
  }

  setFromApi(id: string, t: api.ITimer) {
    this.id = id;
    this.startDate = decodeDate(t.start_date);
    this.isActive = !!t.is_active;
    this.timeSpent = t.time_spent;
    this.recordId = Array.isArray(t.reports_id) ? t.reports_id[0] : '';
    this.processTimeSpent(t);
    if (t.is_active) this.start();
  }

  processTimeSpent(t: api.ITimer) {
    const timeSpent = t.time_spent || 0;
    this.timerCtrl.setInitialSeconds(t.time_spent || 0);
    if (t.is_active && t.start_date) {
      const initialSeconds = differenceInSeconds(Date.now(), decodeDate(t.start_date)) + timeSpent;
      this.timerCtrl.setInitialSeconds(initialSeconds);
    } else {
      this.timerCtrl.setInitialSeconds(timeSpent);
    }
  }

  start() {
    this.isActive = true;
    this.timerCtrl.start();
  }

  stop() {
    this.isActive = false;
    this.timerCtrl.stop();
  }

  clear() {
    this.recordId = '';
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}
